import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { useLocation } from "react-router-dom"; // Assuming you're using react-router-dom for routing

const DashboardSidebarBusiness = ({ isMobile, ...props }) => {
    const [activeLink, setActiveLink] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();

    const handleLinkClick = (key) => {
        setActiveLink((prevActiveLink) => (prevActiveLink === key ? null : key));
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;
    const isTabletScreen = !isDesktopScreen && window.matchMedia('(min-width: 576px)').matches;

    useEffect(() => {
        if (isDesktopScreen) {
            setIsSidebarOpen(true);
        }
    }, [isDesktopScreen]);

    const isActiveLink = (path) => {
        if (location.search == '' && path == '/business-dashboard') return true
        else if (location.search !== '' && path !== '/business-dashboard') return location.pathname === path || location.search.includes(path.split('=')[1]);
    };

    const userData = JSON.parse(localStorage.getItem("userdata"));
    const companyDetailData = JSON.parse(userData.companyData)
    const companyData = JSON.parse(localStorage.getItem("companyData"));

    return (
        <div>
            {!isDesktopScreen && (
                <button
                    onClick={toggleSidebar}
                    style={{
                        position: 'absolute',
                        top: 120,
                        left: 20,
                        zIndex: 3,
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '8px',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    <FaBars size={24} />
                </button>
            )}
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'start',
                flexDirection: 'column',
                width: '250px',
                position: isDesktopScreen ? 'relative' : 'fixed',
                zIndex: 2,
                height: '100%',
                backgroundColor: '#fff',
                paddingLeft: '60px',
                paddingTop: '100px',
                transition: 'transform 0.3s ease-in-out',
                transform: isDesktopScreen || isTabletScreen || isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
                boxShadow: '2px 0 5px rgba(0,0,0,0.1)'
            }}>
                <a className={`business-dashboard-link ${isActiveLink('/business-dashboard') ? 'active' : ''}`} href="/business-dashboard">Home</a>
                <a className={`business-dashboard-link ${isActiveLink('/business-dashboard?page=bookings') ? 'active' : ''}`} href="/business-dashboard?page=bookings">Bookings</a>
                <a className={`business-dashboard-link ${isActiveLink('/business-dashboard?page=roaming') ? 'active' : ''}`} href="/business-dashboard?page=roaming">Roaming</a>
                <div>
                    <a
                        onClick={() => handleLinkClick('admin')}
                        className="business-dashboard-link" href="javascript:void(0)">Admin +</a>
                    <div className={`business-dashboard-sub-links-group ${(activeLink === 'admin') || (
                        isActiveLink('/business-dashboard?page=company-details') ||
                        isActiveLink('/business-dashboard?page=profile') ||
                        isActiveLink('/business-dashboard?page=payments') ||
                        isActiveLink('/business-dashboard?page=team')
                    ) ? '' : 'hidden'}`} >
                        <a className={`business-dashboard-sub-link ${isActiveLink('/business-dashboard?page=company-details') ? 'active' : ''}`} href="/business-dashboard?page=company-details">
                            <span style={{ marginRight: '0.5rem' }}>Company</span>
                            {!companyData.stripeCustomerId && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="red" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M9 5v6h2V5zm0 8v2h2v-2z" /></svg>
                            )}
                        </a>
                        <a className={`business-dashboard-sub-link ${isActiveLink('/business-dashboard?page=profile') ? 'active' : ''}`} href="/business-dashboard?page=profile">Profile</a>
                        <a className={`business-dashboard-sub-link ${isActiveLink('/business-dashboard?page=payments') ? 'active' : ''}`} href="/business-dashboard?page=payments">Payments</a>
                        <a className={`business-dashboard-sub-link ${isActiveLink('/business-dashboard?page=team') ? 'active' : ''}`} href="/business-dashboard?page=team">Team</a>
                    </div>
                </div>
                <a className={`business-dashboard-link ${isActiveLink('/business-dashboard?page=support') ? 'active' : ''}`} href="/business-dashboard?page=support">Support</a>
                <a className={`business-dashboard-link ${isActiveLink('/faq') ? 'active' : ''}`} href="/faq">FAQ</a>
            </div>
        </div>
    );
};

export default DashboardSidebarBusiness;
