import React from "react";
import "antd/dist/antd.css";
import "../Auth.styles.css";
import { Form, Input, Button, Checkbox } from "antd";
import { MDBAlert } from "mdbreact";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from "react-google-login";
import useLoginFormLogic from "./LoginForm.logic";

const LoginFormComponent = (props) => {
    const {
        handleSubmit,
        errors,
        getFieldDecorator,
        responseGoogle,
        responseFacebook,
        validateCompanyEmail,
        isIndosatBusinessPage,
        onClickOpenForgotPasswordModal,
        onClickOpenSignUpModal,
    } = useLoginFormLogic(props);

    return (
        <div style={{
            color: 'white',
            backgroundColor: '#ffffff30',
            backdropFilter: "blur(5px)",
            padding: 10,
        }}>
            <p style={{ maxWidth: 350, textAlign: 'center' }}>{`Dear customer please login to get members only special room rates`} </p>

            <Form
                onSubmit={handleSubmit}
                className="login-form"
                hideRequiredMark={true}
                colon={false}
            >
                {errors && (
                    <MDBAlert color="danger">{errors}</MDBAlert>
                )}

                {!isIndosatBusinessPage && (
                    <>
                        <FacebookLogin
                            appId="208307229975285"
                            fields="name,email,picture"
                            callback={responseFacebook}
                            render={(renderProps) => (
                                <Button
                                    onClick={renderProps.onClick}
                                    style={{
                                        backgroundColor: "#405a93",
                                        color: "white",
                                        width: "100%",
                                        padding: "1rem 0",
                                        height: "auto",
                                        borderRadius: "3px",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    Continue with Facebook
                                </Button>
                            )}
                        />

                        <GoogleLogin
                            clientId="255531455220-0gu1fj71v1jr783csupghm5gcjqq4uc6.apps.googleusercontent.com"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={"single_host_origin"}
                            render={(renderProps) => (
                                <Button
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    style={{
                                        backgroundColor: "#cf5b4d",
                                        color: "white",
                                        width: "100%",
                                        padding: "1rem 0",
                                        height: "auto",
                                        borderRadius: "1px",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    Continue with Google
                                </Button>
                            )}
                        />
                    </>
                )}

                <Form.Item label={<span style={{ color: 'white' }}>Email</span>}>
                    {getFieldDecorator("email", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                            {
                                type: "email",
                                message: "Please input a valid email",
                            },
                            ...(isIndosatBusinessPage
                                ? [{ validator: validateCompanyEmail }]
                                : []),
                        ],
                    })(<Input />)}
                </Form.Item>

                <Form.Item label={<span style={{ color: 'white' }}>Password</span>}>
                    {getFieldDecorator("password", {
                        rules: [{ required: true, message: "Please input your Password!" }],
                    })(<Input type="password" />)}
                </Form.Item>

                <Form.Item>
                    {getFieldDecorator("remember", {
                        valuePropName: "checked",
                        initialValue: true,
                    })(<Checkbox style={{ color: 'white' }}>Remember me</Checkbox>)}

                    <Button
                        style={{ float: "right", color: 'white', paddingTop: 10, fontSize: 12 }}
                        type="link"
                        onClick={onClickOpenForgotPasswordModal}
                    >
                        <span style={{ textDecoration: 'underline' }}>Forgot password</span>
                    </Button>

                    <div style={{ width: '100%' }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginRight: "1rem", width: "100%" }}
                            onClick={handleSubmit}
                        >
                            Log in
                        </Button>
                    </div>

                    <span style={{ color: 'white', fontSize: 14 }}>Don't have an account?</span>
                    <Button
                        style={{ paddingLeft: '5px', color: 'white' }}
                        type="link"
                        onClick={onClickOpenSignUpModal}
                    // onClick={() => setModalType("Register")}
                    >
                        <b>Create one</b>
                    </Button>

                </Form.Item>
            </Form>
        </div>

    );
};

const LoginForm = Form.create({ name: "login_form" })(LoginFormComponent);

export default LoginForm;
