import React from 'react'
import ModalV2 from 'components/Global/ModalV2/ModalV2'
import { styles } from './ForgotPasswordModalV2.styles'
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm'
import { forgotPasswordModalVisibleState } from 'recoil/atoms';

const ForgotPasswordModalV2 = () => {
  return (
    <ModalV2
      recoilState={forgotPasswordModalVisibleState}
      children={
        <ForgotPasswordForm />
      }
      bodyStyle={styles.bodyStyle}
      style={styles.style}
      className="loginModal"
    />
  )
}

export default ForgotPasswordModalV2