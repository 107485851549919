import React from 'react';
import moment from "moment";
import gtag from "ga-gtag";
import {getTimezoneByCountry} from "country-timezone-list";

export const getIsTestAcc = () => {
  return false
  // return true
  // if( localStorage.getItem("is-test-acc") == 'true')
  //     return true;
  // if( localStorage.getItem("is-test-acc") === 1 )
  //     return true;

  // return false;
}

export const formatPolicyType = (type) => {
  return type.replace(/([A-Z])/g, ' $1').trim()
}

export const formatPolicyText = (type) => {
  return type.replace(/\./g, ".\n")
  // return <div dangerouslySetInnerHTML={{ __html: type }} />
}

export const formatDate = (date) => {
  return new Date(date).toLocaleString('en-US', {
    weekday: 'short', // long, short, narrow
    day: 'numeric', // numeric, 2-digit
    year: 'numeric', // numeric, 2-digit
    month: 'long', // numeric, 2-digit, long, short, narrow
  })
}

export const processCancellationPolicy = (_policy, forceGenerateText=false) => {

  const ruleType = (rule) => {
    let value = '';
    switch(rule.valueType){
      case "Amount" :
      default :
        value = localStorage.getItem("currency") + " " + rule.value;
        break;
      case "Percent" :
      case "Percentage" :
        value = rule.value + "%";
        break

    }

    return value;
  }

  const response = {
    text : '',
    table: '',
  }

  const policy = _policy;
  policy.forEach((ply) => {
    let fullstring = `Each room in this reservation is subject to the hotel's cancellation policy which is: `;
    let shortText = '';
    let lastEndDate = '';
    response.text = ply.text;
    response.shortText = ply.text;

    let genText = false;
    if( forceGenerateText )
      genText = true;
    if( !ply.text )
      genText = true;


    if( genText ){
      const rules = ply.rules;
      rules.map((rule) => {
        let text = '';
        const date = moment(rule.end).format('MMMM Do, YYYY h:mma');
        if( rule.value == 0 ){
          text = `Cancellations before ${date} are fully refundable. `;
        }else{
          text = `Bookings cancelled after ${date} are subject to a fee of ${ruleType(rule)}. `
        }

        fullstring += text;
        shortText += text;
        lastEndDate = date;
      })
      fullstring += `There is no refund for no-shows, early checkouts, or cancellations after ${lastEndDate}.`

      response.text = fullstring;
      response.shortText = shortText;

    }

    const refundTooltip = "<Table class='table table-bordered'><tr><th>Start</th><th>End</th><th>Penalty</th></tr>";
    const map = ply.rules.map(rule => {
      return `<tr>
                    <td>${moment(rule.start).format('MMMM Do, YYYY h:mma')}</td>
                    <td>${moment(rule.end).format('MMMM Do, YYYY h:mma')}</td>
                    <td>${ruleType(rule)}</td>
                  </tr>`;

    })

    response.table = refundTooltip + map + "</Table>";
  });

  return response;
}

export const _gtag = (value, type='event', config=null) => {

  if( process.env.REACT_APP_ENV === 'production' ) {
    if (!config) {
      config = {
        send_to: [process.env.REACT_APP_GOOGLE_ANALYTIC, process.env.REACT_APP_GOOGLE_ANALYTIC2],
        event_label: value,
      }
    }

    gtag(type, value, config);
  }
}

export const processAutoSugestionResponse = async (res) => {

  const loca = await res.map((location) => {


    let displayName = location.name;
    if( location.state?.length > 0 ) {
      displayName = `${displayName}, ${location.state}`
    } else {
      const country = getTimezoneByCountry(location.country);

      displayName = `${displayName}, ${country[0].countryName}`
    }

    return {...location, fullName : displayName}
  })

  return loca;
}
