import React from "react";
import "antd/dist/antd.css";
import { Form, Input, Button } from "antd";
import { MDBAlert } from "mdbreact";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import CountrySelector from "pages/PersonalDetails/CountrySelector";
import { styles } from '../Auth.styles'
import useSignUpFormLogic from "./SignUpForm.logic";
import LoginBg from 'images/login_bg_1.png';

const SignUpFormComponent = (props) => {
    const {
        autoSetCountryName,
        isDesktop,
        confirmDirty,
        error,
        registered,
        handleSubmit,
        handleConfirmBlur,
        responseGoogle,
        responseFacebook,
        compareToFirstPassword,
        validateToNextPassword,
        isIndosatBusinessPage,
        isIndosatInvitationBusinessPage,
        validateCompanyEmail
    } = useSignUpFormLogic(props);

    const { getFieldDecorator } = props.form;
    const { hideSocialButtons, formStyles } = props;

    return (
        <div style={{
            color: 'white',
            backgroundColor: '#ffffff30',
            backdropFilter: "blur(5px)",
            padding: 10,
        }}>
            <p style={{ maxWidth: 350, textAlign: 'center' }}>{`Dear customer please get registered to get members only special room rates`} </p>

            <Form
                className="login-form"
                colon={false}
                onSubmit={handleSubmit}
                hideRequiredMark={true}
                layout="horizontal"
                style={{ ...formStyles }}
            >
                {registered && (
                    <MDBAlert color="success">Registration successful</MDBAlert>
                )}
                {error !== null && (
                    <MDBAlert color="danger">{error}</MDBAlert>
                )}

                {(!isIndosatBusinessPage && !isIndosatInvitationBusinessPage) && (
                    <>
                        <FacebookLogin
                            appId="208307229975285"
                            fields="name,email,picture"
                            callback={responseFacebook}
                            render={(renderProps) => (
                                <Button
                                    onClick={renderProps.onClick}
                                    style={styles.facebookButton}
                                >
                                    Continue with Facebook
                                </Button>
                            )}
                        />

                        <GoogleLogin
                            clientId="255531455220-0gu1fj71v1jr783csupghm5gcjqq4uc6.apps.googleusercontent.com"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={"single_host_origin"}
                            buttonText="Login with Google"
                            render={(renderProps) => (
                                <Button
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    style={styles.googleButton}
                                >
                                    Continue with Google
                                </Button>
                            )}
                        />
                    </>
                )}

                <Form.Item style={styles.formItem} label={<span style={{ color: 'white' }}>{(isIndosatBusinessPage) && 'PIC'} First Name</span>}>
                    {getFieldDecorator("first_name", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your first name!",
                                whitespace: true,
                            },
                        ],
                    })(<Input />)}
                </Form.Item>

                <Form.Item style={styles.formItem} label={<span style={{ color: 'white' }}>{(isIndosatBusinessPage) && 'PIC'} Last Name</span>}>
                    {getFieldDecorator("last_name", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your last name!",
                                whitespace: true,
                            },
                        ],
                    })(<Input />)}
                </Form.Item>

                <Form.Item style={styles.formItem} label={<span style={{ color: 'white' }}>Email</span>}>
                    {getFieldDecorator("email", {
                        rules: [
                            {
                                type: "email",
                                message: "The input is not valid E-mail!",
                            },
                            {
                                required: true,
                                message: "Please input your E-mail!",
                            },
                            ...(isIndosatBusinessPage
                                ? [{ validator: validateCompanyEmail }]
                                : [])
                        ],
                    })(<Input disabled={props.isAcceptInvitationForm && props.invitedEmail} />)}
                </Form.Item>

                <Form.Item style={styles.formItem} label={<span style={{ color: 'white' }}>Password</span>} hasFeedback>
                    {getFieldDecorator("password", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your password!",
                            },
                            {
                                validator: validateToNextPassword,
                            },
                        ],
                    })(<Input.Password />)}
                </Form.Item>

                <Form.Item style={styles.formItem} label={<span style={{ color: 'white' }}>Confirm Password</span>} hasFeedback>
                    {getFieldDecorator("confirm", {
                        rules: [
                            {
                                required: true,
                                message: "Please confirm your password!",
                            },
                            {
                                validator: compareToFirstPassword,
                            },
                        ],
                    })(<Input.Password onBlur={handleConfirmBlur} />)}
                </Form.Item>

                <Form.Item style={styles.formItem} label={<span style={{ color: 'white' }}>Phone Number</span>}>
                    {getFieldDecorator("contactNumber", {
                        rules: [
                            { required: true, message: "Please input your phone number!" },
                        ],
                    })(<PhoneInput
                        onChange={(phoneData, country) => autoSetCountryName(phoneData, country)}
                        placeholder="" inputClass="signUpPhoneInput" />)}
                </Form.Item>

                {(isIndosatBusinessPage) && (
                    <Form.Item style={styles.formItem} label={<span style={{ color: 'white' }}>Country</span>}>
                        {getFieldDecorator("country", {
                            rules: [
                                { required: true, message: "Please input your country!" },
                            ],
                            // initialValue: 'ID'
                        })(<CountrySelector style={{ border: "none", width: "100%" }} />)}
                    </Form.Item>
                )}

                <Button type="primary" htmlType="submit" style={{ width: '100%', marginTop: '20px' }}>
                    Register
                </Button>
            </Form>
        </div>

    );
};

const SignUpForm = Form.create({ name: "sign_up_form_v2" })(SignUpFormComponent);

export default SignUpForm;
