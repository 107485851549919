import { Button } from 'antd'
import LoginModalV2 from 'components/Auth/LoginModalV2/LoginModalV2'
import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import useResetNewPasswordLogic from './ResetNewPassword.logic'
import useResetNewPasswordStyles from './ResetNewPassword.styles'

const ResetNewPasswordSuccess = () => {
    const { styles } = useResetNewPasswordStyles()

    const {
        onClickLogin
    } = useResetNewPasswordLogic()

    return (
        <div style={styles.form}>
            <FaCheckCircle size={75} style={styles.successIcon} />
            <h1>Password has successfully been updated!</h1>

            <p>If you think you have not updated the password yet, please check your email inbox and make sure to click the most recent link received by your email.</p>

            <div style={styles.actionGroup}>

                <Button
                    type='ghost'
                    href="/"
                >
                    Back to home
                </Button>
                <Button
                    type='primary'
                    onClick={onClickLogin}
                >
                    Login to your account
                </Button>
            </div>


            <LoginModalV2 />
        </div>
    )
}

export default ResetNewPasswordSuccess