import { Button, Checkbox, Input, Modal, Radio } from "antd";
import React, { useState } from "react";

import ReactDataTables from "components/Global/ReactDataTables/ReactDataTables"

const data = [
    {
        id: "1",
        employee_name: "Tushar",
        duration: "7 days (10 May-17 May)",
        destination: "Bangkok",
        mobile_operator: "Indosat",
        roaming_status: "Eligible",
    },
    {
        id: "2",
        employee_name: "Bala",
        duration: "7 days (10 May-17 May)",
        destination: "Singapore",
        mobile_operator: "Indosat",
        roaming_status: "Activated",
    },
];

const columns = [
    { data: "employee_name", title: "Employee Name" },
    { data: "duration", title: "Duration" },
    { data: "destination", title: "Destination" },
    { data: "mobile_operator", title: "Mobile Operator" },
    // { data: "roaming_status", title: "Roaming Status" },
    {
        data: "roaming_status", // data is set to null because this column doesn't refer to a data property
        title: "Roaming Status",
        render: function (data, type, row) {
            let statusColor = ''
            switch (data) {
                case 'Activated':
                    statusColor = 'green'
                    break
                case 'Eligible':
                    statusColor = 'orange'
                    break
                default:
                    statusColor = 'gray'
                    break
            }

            return `
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="0.5em" height="0.5em" viewBox="0 0 24 24"><path fill="${statusColor}" d="M7 3.34a10 10 0 1 1-4.995 8.984L2 12l.005-.324A10 10 0 0 1 7 3.34"/></svg>
                ${data}
              </div>
            `;


        },
    },
];

const DashboardContentRoaming = ({ isMobile, ...props }) => {
    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;
    const [inviteTeamModalVisible, setInviteTeamModalVisible] = useState(false)

    return (
        <div className="teamSection">
            <div style={{
                backgroundColor: 'white',
                width: 'fit-content',
                minWidth: isDesktopScreen ? '750px' : 'auto',
                padding: '40px',
            }}>
                <div className="titleRow">
                    <h2>Roaming Status</h2>
                    {/* <Button type="primary" onClick={() => setInviteTeamModalVisible(true)}>
                        Invite team
                    </Button> */}
                </div>

                <hr />

                <Modal
                    title={null}
                    footer={null}
                    visible={inviteTeamModalVisible}
                    onCancel={() => setInviteTeamModalVisible(false)}
                    className="inviteTeamModal"
                    width={"auto"}

                    style={{
                        width: ''
                    }}
                >
                    <div className="modalWrapper">
                        <h2>Invite your team</h2>
                        <Input
                            style={{ border: "none", width: "100%" }}
                            placeholder="Enter email address"
                        />

                        <hr />

                        <h2>Users permissions</h2>
                        <ul>
                            <li>
                                <div className="checkboxGroup">
                                    <h4>Able to view bookings only</h4>
                                    <Checkbox />
                                </div>
                            </li>
                            <li>
                                <div className="checkboxGroup">
                                    <h4>Able to book for onself</h4>
                                    <Checkbox />
                                </div>
                            </li>
                            <li>
                                <div className="checkboxGroup">
                                    <h4>Able to booking for others</h4>
                                    <Checkbox />
                                </div>
                            </li>
                            <li>
                                <div className="checkboxGroup">
                                    <h4>Using company card</h4>
                                    <Checkbox />
                                </div>
                            </li>
                        </ul>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                            <Button type="primary" onClick={() => setInviteTeamModalVisible(true)} style={{
                                marginLeft: 'auto',
                            }}>
                                Invite
                            </Button>
                        </div>

                    </div>

                </Modal>

                <Radio.Group defaultValue="current" buttonStyle="solid" style={{
                    marginTop: '16px',
                    marginBottom: '16px',
                }}>
                    <Radio.Button value="current">Current</Radio.Button>
                    <Radio.Button value="past">Past</Radio.Button>
                    <Radio.Button value="upcoming">Upcoming</Radio.Button>
                </Radio.Group>


                <ReactDataTables data={data} columns={columns} />

            </div>

            <h2 style={{
                color: 'white',
                marginTop: '40px',
                textAlign: 'center',
            }}>Please note: Free roaming is available only for indosat mobile subscribers</h2>
        </div >
    )
}

export default DashboardContentRoaming;
