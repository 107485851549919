import { Button, Checkbox, Input, Modal, Radio, message } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HOTEL_DETAIL } from "constants/RouteNames";
import SavedBookingDetailModal from "./SavedBookingDetailModal";

import ReactDataTables from "components/Global/ReactDataTables/ReactDataTables";

const getColumnsByFilter = (filter) => {
    switch (filter) {
        case 'saved':
            return [
                { data: "guest_name", title: "Guest Name" },
                { data: "duration", title: "Duration" },
                { data: "hotel_name", title: "Hotel Name" },
                { data: "destination", title: "Destination" },
                { data: "notes", title: "Notes" },
                { data: "status", title: "Status" },
                {
                    data: "all",
                    title: "Detail",
                    render: function (data, type, row) {
                        return `
                <button class="action-button" data-id="${row.id}">
                  Click Here
                </button>
              `;
                    },
                },
                {
                    data: "all",
                    title: "Book now",
                    render: function (data, type, row) {
                        return `
                <button class="edit-button" data-id="${row.id}">
                  Click Here
                </button>
              `;
                    },
                },
                {
                    data: "all",
                    title: "Remove",
                    render: function (data, type, row) {
                        return `
                <button class="delete-button" data-id="${row.id}">
                  Click Here
                </button>
              `;
                    },
                },
            ];

        case 'past':
        case 'cancelled':
        case 'unpaid':
            return [
                { data: "guest_name", title: "Booked by" },
                { data: "guest_details_per_room", title: "Guest Name" },
                { data: "destination", title: "Destination" },
                { data: "duration", title: "Duration" },
                { data: "hotel_name", title: "Hotel Name" },
                {
                    data: "all",
                    title: "Delete",
                    render: function (data, type, row) {
                        return `
                <button class="edit-button" style="display: none;" data-id="${row.id}">
                  Click Here
                </button>
                <button class="action-button" style="display: none;" data-id="${row.id}">
                  Click Here
                </button>
                <button class="delete-button" data-id="${row.id}">
                  Click Here
                </button>
              `;
                    },
                },
            ];
        case 'upcoming':
            return [
                { data: "guest_name", title: "Booked by" },
                { data: "guest_details_per_room", title: "Guest Name" },
                { data: "destination", title: "Destination" },
                { data: "duration", title: "Duration" },
                { data: "hotel_name", title: "Hotel Name" },
                {
                    data: "all",
                    title: "Cancel",
                    render: function (data, type, row) {
                        return `
                <button class="edit-button" style="display: none;" data-id="${row.id}">
                  Click Here
                </button>
                <button class="action-button" style="display: none;" data-id="${row.id}">
                  Click Here
                </button>
                <button class="delete-button" data-id="${row.id}">
                  Click Here
                </button>
              `;
                    },
                },
            ];
        default:
            return [
                { data: "guest_name", title: "Booked by" },
                { data: "guest_details_per_room", title: "Guest Name" },
                { data: "destination", title: "Destination" },
                { data: "duration", title: "Duration" },
                { data: "hotel_name", title: "Hotel Name" },
            ];
    }
};

const DashboardContentBookings = ({ isMobile, ...props }) => {
    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;
    const [isOpenSavedBookingDetailModal, setIsOpenSavedBookingDetailModal] = useState(false);
    const [currentSavedBookingDetailData, setCurrentSavedBookingDetailData] = useState(null);

    const [inviteTeamModalVisible, setInviteTeamModalVisible] = useState(false);

    const history = useHistory();
    const [data, setData] = useState([]);
    const [savedBookingsData, setSavedBookingsData] = useState([]);

    const [selectedFilter, setSelectedFilter] = useState('current');
    const [columnsToShow, setColumnsToShow] = useState(getColumnsByFilter(selectedFilter));
    const [key, setKey] = useState(0); // Unique key for DataTable reinitialization

    useEffect(() => {
        setColumnsToShow(getColumnsByFilter(selectedFilter))
    }, [selectedFilter])

    const getData = async () => {
        const email = localStorage.getItem("useremail");

        let config = {
            method: "post",
            url: "/users/transactions",
            data: {
                email: email,
            },
            params: {
                status: selectedFilter
            }
        };

        const transactionList = [];
        const res = await Axios(config);
        if (res.data.success) {
            res.data.object.map((trx) => {
                const checkinDate = new Date(trx.checkin_date);
                const checkoutDate = new Date(trx.checkout_date);
                const differenceInMilliseconds = checkoutDate.getTime() - checkinDate.getTime();
                const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
                const totalDays = Math.floor(differenceInMilliseconds / oneDayInMilliseconds);

                const options = { day: '2-digit', month: 'long', year: 'numeric' };
                const formattedCheckinDate = new Intl.DateTimeFormat('en-GB', options).format(checkinDate);
                const formattedCheckoutDate = new Intl.DateTimeFormat('en-GB', options).format(checkoutDate);

                const bookingData = JSON.parse(trx.booking_data);
                const guestInfo = bookingData.roomsAllocations[0].guests[0];

                transactionList.push({
                    id: trx,
                    guest_name: `${guestInfo.firstName} ${guestInfo.lastName}`,
                    guest_details_per_room: trx.guest_details_per_room,
                    destination: trx.destination,
                    duration: `${totalDays} days (${formattedCheckinDate} - ${formattedCheckoutDate})`,
                    hotel_name: trx.hotel_name,
                });
            });

            setData(transactionList);
        }
    };

    const redirectToHotelDetailsPage = (booking) => {
        const guestDetails = JSON.parse(booking.guest_details);

        const state = {
            correlationId: booking.correlationId,
            place: booking.place,
            placeId: booking.place_id,
            startDate: booking.checkin_date,
            endDate: booking.checkout_date,
            noofAdults: guestDetails.noofAdults,
            noofChild: guestDetails.noofChild,
            noofroom: booking.room_count,
            lattitude: booking.latitude,
            longittude: booking.longitude,
            panes: guestDetails.panes,
        };

        const timeStamp = new Date().getTime();
        const key = `VDHOL-${booking.hotel_id}-${timeStamp}`;
        localStorage.setItem(key, JSON.stringify(state));

        let url = HOTEL_DETAIL.replace(":slug", booking.hotel_id) + `?d=${timeStamp}&p_id=${booking.place_id}`;
        if (booking.id) url += `&saved_booking_id=${booking.id}`

        window.open(url, "_blank");
    };

    const openSavedBookingDetailModal = async (savedBooking) => {
        if (savedBooking?.id) {
            const config = {
                method: "get",
                url: `/saved-booking-detail/${savedBooking?.id}/get-detail`,
            };

            await Axios(config)
                .then((response) => {
                    const guestDetails = JSON.parse(response.data.guest_details);
                    setCurrentSavedBookingDetailData({
                        ...response.data, guestDetails
                    });
                    setIsOpenSavedBookingDetailModal(true);
                })
                .catch((err) => {
                    message.error('Failed to fetch booking details');
                });
        }
    };

    const getSavedBookingsData = async () => {
        const companyId = JSON.parse(localStorage.getItem("companyData")).id;
        if (!companyId) {
            console.error("Company ID not found in localStorage");
            return;
        }

        let config = {
            method: "get",
            url: `/saved-booking-detail/${companyId}`,
        };

        try {
            const savedBookingsList = [];
            const res = await Axios(config);

            if (res.status === 200) {
                res.data.forEach((booking) => {
                    const guestDetails = JSON.parse(booking.guest_details);
                    savedBookingsList.push({
                        guest_name: `${booking.User.firstName} ${booking.User.lastName}`,
                        duration: booking.duration,
                        hotel_name: booking.hotel_name,
                        destination: booking.destination,
                        status: booking.status,
                        notes: booking.notes,
                        id: booking,
                        detail_link: `hotel/${booking.hotel_id}/detail?d=${booking.search_timestamp}&p_id=${booking.place_id}`,
                    });
                });

                setSavedBookingsData(savedBookingsList);
            } else {
                console.error("Failed to fetch saved bookings");
            }
        } catch (error) {
            console.error("Error fetching saved bookings data:", error);
        }
    };

    const handleFilterChange = (e) => {
        const filter = e.target.value;
        setSelectedFilter(filter);
        // if (filter === 'saved') {
        //     setColumnsToShow(savedBookingsColumns);
        // } else {
        //     setColumnsToShow(columns);
        // }
        setColumnsToShow(getColumnsByFilter(filter));
        setKey(prevKey => prevKey + 1); // Change key to force re-render
    };

    const onClickHandleDeleteSaved = async (id) => {
        console.log(id)
        const {
            companyId,
            userId,
            hotel_id,
            place_id,
        } = id

        // Show confirmation alert
        const confirmDelete = window.confirm('Are you sure you want to remove this booking?');

        if (!confirmDelete) {
            return; // Exit function if user cancels the deletion
        }

        const config = {
            method: 'post',
            url: 'saved-booking-detail/remove',
            data: {
                companyId,
                userId,
                hotel_id,
                place_id,
                source: 'B2B_INDOSAT_PIC'
            },
        };

        await Axios(config)
            .then((response) => {
                message.success('Booking has been successfully removed.');
                getSavedBookingsData()
            })
            .catch((err) => {
                console.error('Error removing booking:', err);
                message.error('Failed to remove booking.');
            })
            .finally(() => {
                // Perform any additional cleanup actions here if needed
            });
    }

    const onClickHandleArchive = async (trx) => {
        const {
            id
        } = trx

        // Show confirmation alert
        const confirmDelete = window.confirm('Are you sure you want to remove this booking?');

        if (!confirmDelete) {
            return; // Exit function if user cancels the deletion
        }

        const config = {
            method: 'post',
            url: 'payment/update-status',
            data: {
                id,
                status: "ARCHIVED"
            },
        };

        await Axios(config)
            .then((response) => {
                message.success('Booking has been successfully removed.');
                getData()
            })
            .catch((err) => {
                console.error('Error removing booking:', err);
                message.error('Failed to remove booking.');
            })
            .finally(() => {
                // Perform any additional cleanup actions here if needed
            });
    }

    useEffect(() => {
        if (selectedFilter === 'saved') {
            getSavedBookingsData();
        } else {
            getData();
        }
    }, [selectedFilter]);

    return (
        <div className="teamSection">
            <div style={{
                backgroundColor: 'white',
                width: 'fit-content',
                minWidth: isDesktopScreen ? '750px' : 'auto',
                padding: '40px',
                maxWidth: isDesktopScreen ? '80%' : 'auto',
                maxHeight: isDesktopScreen ? '80vh' : '60vh',
                // maxHeight: isDesktopScreen ? '80%' : 'auto',
                overflowY: 'scroll'
            }}>
                <div className="titleRow">
                    <h2>Manage Bookings</h2>
                </div>

                <hr />

                <SavedBookingDetailModal
                    isOpen={isOpenSavedBookingDetailModal}
                    setIsOpen={setIsOpenSavedBookingDetailModal}
                    data={currentSavedBookingDetailData}
                />

                <Modal
                    title={null}
                    footer={null}
                    visible={inviteTeamModalVisible}
                    onCancel={() => setInviteTeamModalVisible(false)}
                    className="inviteTeamModal"
                    width={"auto"}
                >
                    <div className="modalWrapper">
                        <h2>Invite your team</h2>
                        <Input
                            style={{ border: "none", width: "100%" }}
                            placeholder="Enter email address"
                        />

                        <hr />

                        <h2>Users permissions</h2>
                        <ul>
                            <li>
                                <div className="checkboxGroup">
                                    <h4>Able to view bookings only</h4>
                                    <Checkbox />
                                </div>
                            </li>
                            <li>
                                <div className="checkboxGroup">
                                    <h4>Able to booking for others</h4>
                                    <Checkbox />
                                </div>
                            </li>
                            <li>
                                <div className="checkboxGroup">
                                    <h4>Using company card</h4>
                                    <Checkbox />
                                </div>
                            </li>
                        </ul>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                            <Button type="primary" onClick={() => setInviteTeamModalVisible(true)} style={{
                                marginLeft: 'auto',
                            }}>
                                Invite
                            </Button>
                        </div>

                    </div>

                </Modal>

                <Radio.Group defaultValue="current" buttonStyle="solid" style={{
                    marginTop: '16px',
                    marginBottom: '16px',
                    position: 'relative',
                    zIndex: 1,
                }}
                    onChange={handleFilterChange}
                >
                    <Radio.Button value="current">Current</Radio.Button>
                    <Radio.Button value="upcoming">Upcoming</Radio.Button>
                    <Radio.Button value="saved">Saved</Radio.Button>
                    <Radio.Button value="past">Past</Radio.Button>
                    <Radio.Button value="cancelled">Cancelled</Radio.Button>
                    <Radio.Button value="unpaid">Unpaid</Radio.Button>
                </Radio.Group>

                <ReactDataTables
                    key={key} // Use the key to force re-render
                    data={selectedFilter === 'saved' ? savedBookingsData : data}
                    columns={columnsToShow}
                    onEdit={redirectToHotelDetailsPage}
                    onAction={selectedFilter === 'saved' ? openSavedBookingDetailModal : redirectToHotelDetailsPage}
                    onDelete={selectedFilter === 'saved' ? onClickHandleDeleteSaved : onClickHandleArchive}
                />
            </div>
        </div>
    );
};

export default DashboardContentBookings;
