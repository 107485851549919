import { Button, Modal } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HOTEL_DETAIL } from "constants/RouteNames";

const SavedBookingDetailModal = ({
    isOpen,
    setIsOpen,
    data,
    appendRedirectButton,
    removeBooking
}) => {
    const history = useHistory()

    if (data) {
        const guestDetails = JSON.parse(data?.guest_details)

        const appendRedirectButtonAction = (booking) => {
            const state = {
                correlationId: data.correlationId,
                place: data.place,
                placeId: data.place_id,
                startDate: data.checkin_date,
                endDate: data.checkout_date,
                noofAdults: guestDetails.noofAdults,
                noofChild: guestDetails.noofChild,
                noofroom: data.room_count,
                lattitude: data.latitude,
                longittude: data.longitude,
                panes: guestDetails.panes,
            };

            const timeStamp = new Date().getTime();
            const key = `VDHOL-${data.hotel_id}-${timeStamp}`;
            localStorage.setItem(key, JSON.stringify(state));

            window.open(HOTEL_DETAIL.replace(":slug", data.hotel_id) + `?d=${timeStamp}&p_id=${data.place_id}&saved_booking_id=${booking.bookingId}`, "_blank");
        }

        return (
            <Modal
                visible={isOpen}
                forceRender={true}
                ariaHideApp={false}
                footer={null} // Hides the default footer with OK and Cancel buttons
                onCancel={() => setIsOpen(false)} // This handles the close button
            >
                {
                    data && guestDetails && (
                        <div style={{ width: '100%', paddingBottom: '24px' }}>
                            <h2>User Info</h2>
                            <hr />
                            <p><strong>User name:</strong> {`${data?.User?.firstName} ${data?.User?.lastName}`}</p>
                            <p><strong>User email:</strong> {data?.User?.email}</p>

                            <h2>Booking Detail</h2>
                            <hr />
                            <p><strong>Destination:</strong> {data?.destination}</p>
                            <p><strong>Hotel Name:</strong> {data?.hotel_name}</p>
                            <p><strong>Check-in Date:</strong> {new Date(data?.checkin_date).toLocaleDateString()}</p>
                            <p><strong>Check-out Date:</strong> {new Date(data?.checkout_date).toLocaleDateString()}</p>
                            <p><strong>Room Count:</strong> {data?.room_count}</p>
                            <p><strong>Notes:</strong> {data?.notes}</p>
                            <p><strong>Booking saved at:</strong> {new Date(data?.savedAt).toLocaleDateString()}</p>
                            <p><strong>Status:</strong> {data?.status}</p>

                            <h2>Guest Details</h2>
                            <hr />
                            <p><strong>Number of Adults:</strong> {guestDetails?.noofAdults}</p>
                            <p><strong>Number of Children:</strong> {guestDetails?.noofChild}</p>


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                width: 'fit-content',
                                columnGap: '8px'
                            }}>
                                <Button type="link" onClick={() => removeBooking({
                                    companyId: data?.companyId,
                                    userId: data?.User?.id,
                                    hotel_id: data?.hotel_id,
                                    place_id: data?.place_id,
                                })}>
                                    Remove
                                </Button>

                                {appendRedirectButton && (
                                    <Button style={{ float: 'right' }} type="primary" onClick={() => appendRedirectButtonAction(data)}>View Details</Button>
                                )}
                            </div>

                        </div>
                    )
                }
            </Modal>
        );
    } else return <Modal
        visible={isOpen}
        forceRender={true}
        ariaHideApp={false}
        footer={null} // Hides the default footer with OK and Cancel buttons
        onCancel={() => setIsOpen(false)} // This handles the close button
    >
        <h2>Data is unavailable!</h2>
    </Modal>
};

export default SavedBookingDetailModal;
