import React from 'react'
import ModalV2 from 'components/Global/ModalV2/ModalV2'
import LoginForm from 'components/Auth/LoginForm/LoginForm'
import { styles } from './LoginModalV2.styles'
import { loginModalVisibleState } from "recoil/atoms"
import ForgotPasswordModalV2 from "components/Auth/ForgotPasswordModalV2/ForgotPasswordModalV2";
import SignUpModalV2 from "components/Auth/SignUpModalV2/SignUpModalV2";

const LoginModalV2 = () => {
  return (
    <>
      <ForgotPasswordModalV2 />
      <SignUpModalV2 />
      
      <ModalV2
        recoilState={loginModalVisibleState}
        children={
          <LoginForm />
        }
        bodyStyle={styles.bodyStyle}
        style={styles.style}
        className="loginModal"
      />
    </>

  )
}

export default LoginModalV2