import { Button, Form, Input, message } from "antd";
import React, { useCallback, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import CountrySelector from "pages/PersonalDetails/CountrySelector";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Axios from "axios";
import { RegionSelector } from "pages/PersonalDetails/RegionSelector";

const CompanyDetailsForm = (props) => {
    const isDesktopScreen = useMediaQuery({
        minWidth: 991
    })
    const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields, getFieldsValue } = props.form;

    const handleSubmit = () => {
        const company = JSON.parse(localStorage.getItem("companyData"))

        validateFields((err, values) => {
            if (!err) {
                Axios.post(`/company/${company.id}`, {
                    ...values,
                })
                    .then((res) => {
                        localStorage.setItem("companyData", JSON.stringify(res.data.company));
                        if (res.status == 200) {
                            message.success("Company details is updated");
                            setTimeout(() => {
                                window.location.reload()
                            }, 500);
                            // let data = res.data.userdata;
                            // localStorage.setItem("userdata", JSON.stringify(data));
                        } else {
                            message.error("Fail to update company details");
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        message.error("An error occurred while updating the company details");
                    });
            }
        });


        // console.log('ok')
        // console.log(getFieldsValue)
        // return

        // Axios.put("/users/editUser", {
        //     prevemail: this.state.userdata.email,
        //     email: this.state.email,
        //     firstName: this.state.first_name,
        //     lastName: this.state.last_name,
        //     contactNumber: this.state.contactNumber,
        // })
        //     .then((res) => {
        //         if (res.data.status == "success") {
        //             message.success("Profile is updated");
        //             let data = res.data.userdata;
        //             this.setState({
        //                 userdata: data,
        //                 first_name: data.first_name,
        //                 last_name: data.last_name,
        //                 email: data.email,
        //                 contactNumber: data.contactNumber,
        //             });
        //             localStorage.setItem("useremail", data.email);
        //             localStorage.setItem("userdata", JSON.stringify({
        //                 first_name: data.firstName,
        //                 last_name: data.lastName,
        //                 contactNumber: data.contactNumber,
        //             }));
        //         } else {
        //             let data = this.state.userdata;
        //             message.error("Fail to update profile");
        //             this.setState({
        //                 first_name: data.first_name,
        //                 last_name: data.last_name,
        //                 email: data.email,
        //                 contactNumber: data.contactNumber,
        //             });
        //         }
        //     })
        //     .catch((e) => console.log(e));
    }

    const userData = JSON.parse(localStorage.getItem("userdata"));
    const companyDetailData = JSON.parse(userData.companyData)
    const companyData = JSON.parse(localStorage.getItem("companyData"));

    return (
        <Form style={{
            backgroundColor: 'white',
            width: 'fit-content',
            minWidth: isDesktopScreen ? '850px' : 'auto',
            padding: '5px 10px',
            maxHeight: isDesktopScreen ? '80vh' : '60vh',
            overflowY: 'scroll'
        }}

        >
            {!companyData.stripeCustomerId && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    color: 'red',
                    columnGap: '0.5rem'
                }}>
                    <p style={{ marginTop: '1rem' }}>Please complete the following form</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="red" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M9 5v6h2V5zm0 8v2h2v-2z" /></svg>
                </div>
            )}

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                rowGap: '10px',
                columnGap: '20px',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    rowGap: '8.5px',
                    marginTop: '10px',
                    marginBottom: '30px',
                    width: isDesktopScreen ? '50%' : '100%'
                }}>

                    <h2>Company Details</h2>

                    <div className="inputContainer">
                        <div className="inputDescription">Company Name</div>
                        <Form.Item style={{ margin: 0 }}>
                            {getFieldDecorator("companyName", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your company name",
                                    },
                                    {
                                        type: "string",
                                        message: "Please input a valid company name",
                                    },
                                ],
                                initialValue: `${JSON.parse(localStorage.getItem("companyData")).companyName ?? ''
                                    }`,
                            })(
                                <Input
                                    style={{ border: "none", width: "100%" }}
                                // placeholder="Company name"
                                />
                            )}
                        </Form.Item>
                    </div>

                    <div className="inputContainer">
                        <div className="inputDescription">Business Name</div>
                        <Form.Item style={{ margin: 0 }}>
                            {getFieldDecorator("businessName", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your business name",
                                    },
                                    {
                                        type: "string",
                                        message: "Please input a valid business name",
                                    },
                                ],
                                initialValue: `${JSON.parse(localStorage.getItem("companyData")).businessName ?? ''
                                    }`,
                            })(
                                <Input
                                    style={{ border: "none", width: "100%" }}
                                // placeholder="Business name"
                                />
                            )}
                        </Form.Item>
                    </div>

                    <div className="inputContainer">
                        <div className="inputDescription">Business Email</div>
                        <Form.Item style={{ margin: 0 }}>
                            {getFieldDecorator("businessEmail", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your business email",
                                    },
                                    {
                                        type: "string",
                                        message: "Please input a valid business email",
                                    },
                                ],
                                initialValue: `${JSON.parse(localStorage.getItem("companyData")).businessEmail ?? ''
                                    }`,
                            })(
                                <Input
                                    style={{ border: "none", width: "100%" }}
                                // placeholder="Business email"
                                />
                            )}
                        </Form.Item>
                    </div>

                    <div
                        className="inputContainer"
                    >
                        <div className="inputDescription">Phone Number</div>
                        <Form.Item className="companyDetailsFormItem" style={{ margin: 0, width: "100%" }}>
                            {getFieldDecorator("contactNumber", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Invalid Phone Number",
                                    },
                                ],
                                initialValue: JSON.parse(localStorage.getItem("companyData"))
                                    .contactNumber,
                            })(
                                <PhoneInput
                                    // onChange={(phoneData, country) => autoSetCountryName(phoneData, country)}
                                    inputClass="profiletelephoneinput"
                                    containerClass="profiletelephoneinput"
                                    dropdownStyle={{ border: "none" }}
                                    placeholder="Phone"
                                />
                            )}
                        </Form.Item>
                    </div>

                    <div className="inputContainer">
                        <div className="inputDescription">Number of Employees</div>
                        <Form.Item style={{ margin: 0 }}>
                            {getFieldDecorator("numberOfEmployees", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your number of employees",
                                    },
                                    {
                                        type: "string",
                                        message: "Please input a valid number of employees",
                                    },
                                ],
                                initialValue: `${JSON.parse(localStorage.getItem("companyData")).numberOfEmployees ?? ''}`,
                            })(
                                <Input
                                    style={{ border: "none", width: "100%" }}
                                    type="number"
                                // placeholder="Number of employees"
                                />
                            )}
                        </Form.Item>
                    </div>

                    <div className="inputContainer">
                        <div className="inputDescription">Industry Type</div>
                        <Form.Item style={{ margin: 0 }}>
                            {getFieldDecorator("industryType", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input your industry type",
                                    },
                                    {
                                        type: "string",
                                        message: "Please input a valid industry type",
                                    },
                                ],
                                initialValue: `${JSON.parse(localStorage.getItem("companyData")).industryType ?? ''
                                    }`,
                            })(
                                <Input
                                    style={{ border: "none", width: "100%" }}
                                // placeholder="Industry Type"
                                />
                            )}
                        </Form.Item>
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    rowGap: '3px',
                    marginTop: '10px',
                    width: isDesktopScreen ? '50%' : '100%',
                }}>
                    {/* <h2>Company Logo</h2>
                    <img src="https://i0.wp.com/getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg" className="businessLogoThumbnail" alt="" /> */}


                    <h2>Company Address</h2>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        rowGap: '0px',
                        width: '100%'
                    }}>

                        <div className="inputContainer">
                            <div className="inputDescription">Country</div>
                            <Form.Item className="companyDetailsFormItem" style={{ margin: 0 }}>
                                {getFieldDecorator("country", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please enter your country",
                                        },
                                    ],
                                    initialValue: 'ID'
                                    // initialValue: `${JSON.parse(localStorage.getItem("companyData")).country ?? 'ID'}`,
                                })(
                                    <CountrySelector
                                        style={{ border: "none", width: "100%" }}
                                        disabled
                                        onChange={(value) => {
                                            setFieldsValue({
                                                country: value,
                                                state: undefined,
                                            });
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </div>

                        <div className="inputContainer">
                            <div className="inputDescription">State</div>
                            <Form.Item style={{ margin: 0 }}>
                                {getFieldDecorator("state", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please enter your company state",
                                        },
                                    ],
                                    initialValue: `${JSON.parse(localStorage.getItem("companyData")).state ?? ''}`,
                                })(
                                    <RegionSelector
                                        placeholder="Select the company state"
                                        style={{ border: "none", width: "100%" }}
                                        disabled={!!!getFieldValue("country")}
                                        country={getFieldValue("country")}
                                    />
                                )}
                            </Form.Item>
                        </div>

                        <div className="inputContainer">
                            <div className="inputDescription">City / Town</div>
                            <Form.Item style={{ margin: 0 }}>
                                {getFieldDecorator("city", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input your city or town",
                                        },
                                        {
                                            type: "string",
                                            message: "Please input a valid city or town",
                                        },
                                    ],
                                    initialValue: `${JSON.parse(localStorage.getItem("companyData")).city ?? ''}`,
                                })(
                                    <Input
                                        style={{ border: "none", width: "100%" }}
                                    // placeholder="City / Town"
                                    />
                                )}
                            </Form.Item>
                        </div>


                        <div className="inputContainer">
                            <div className="inputDescription">Street address</div>
                            <Form.Item style={{ margin: 0 }}>
                                {getFieldDecorator("streetAddress", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input your street address",
                                        },
                                        {
                                            type: "string",
                                            message: "Please input a valid street address",
                                        },
                                    ],
                                    initialValue: `${JSON.parse(localStorage.getItem("companyData")).streetAddress ?? ''
                                        }`,
                                })(
                                    <Input
                                        style={{ border: "none", width: "100%" }}
                                    // placeholder="Street address"
                                    />
                                )}
                            </Form.Item>
                        </div>

                        <div className="inputContainer">
                            <div className="inputDescription">Postal Code</div>
                            <Form.Item style={{ margin: 0 }}>
                                {getFieldDecorator("postalCode", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input your postal code",
                                        },
                                        {
                                            type: "string",
                                            message: "Please input a valid postal code",
                                        },
                                    ],
                                    initialValue: `${JSON.parse(localStorage.getItem("companyData")).postalCode ?? ''
                                        }`,
                                })(
                                    <Input
                                        style={{ border: "none", width: "100%" }}
                                    // placeholder="Street address"
                                    />
                                )}
                            </Form.Item>
                        </div>


                    </div>
                </div>

            </div>

            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Button type="primary" onClick={handleSubmit}>Save</Button>
            </div>

        </Form>
    )
}

const CompanyDetailsPage = Form.create({ name: "CompanyDetailsPage" })(CompanyDetailsForm);

const DashboardContentCompanyDetails = ({ isMobile, ...props }) => {
    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;

    return (
        <div style={{
            // width: '100vw',
            marginTop: '120px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }} className="PersonalDetails companyDetailsPage">
            <CompanyDetailsPage {...props} />
        </div>
    )
}

export default DashboardContentCompanyDetails;
