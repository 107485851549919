import React, { useState } from "react";
import "pages/LegalDocuments.css";
import Header from "components/Header/Header";
import { useMediaQuery } from 'react-responsive'
import Collapsible from 'react-collapsible';
import useGoogleSheets from 'use-google-sheets';
import FAQ from "components/GeneralInformation/FAQ/FAQ";
import FooterPage from "components/Global/FooterPage/FooterPage";

const chuckArray = (array, chunk = 10) => {
  chunk = chunk === 0 ? 1 : chunk;

  const arrayLength = array.length;;
  const returnArray = [];
  for (let i = 0; i < arrayLength; i += chunk) {
    returnArray.push(array.slice(i, i + chunk));
  }
  return returnArray;
}

// const countryListing1Day = [
//     "Albania","Australia","Austria","Belgium","Bulgaria","China","Croatia","Cyprus","Czech Republic",
//     "Denmark","Estonia","Finland","France","Germany","Greece","Hong Kong","Hungary","Iceland","Ireland",
//     "Israel","Italy","Jordan","Kuwait","Latvia","Liechtenstein","Lithuania","Luxembourg","Macau","Malaysia",
//     "Malta","Myanmar","Netherlands","New Zeland","Norway","Poland","Portugal","Qatar","Romania","Saudi Arabia",
//     "Serbia","Singapore","Slovenia","South Korea","Spain","Sweden","Switzerland","Taiwan","Turkey","UK*","Vietnam"
// ];

// const countryListing3Day = [
//     "Anguilla (UK)","Antigua and Barbuda","Argentina","Aruba","Azerbaijan","Barbados","Bermuda",
//     "Bonaire","Brazil","British Virgin Islands","Brunei","Cambodia","Canada","Cayman Island","Costa Rica",
//     "Dominica","Dominican repubblic","DR Congo","East Timor","El Salvador","Fiji","Ghana","Gibraltar","Grenada",
//     "Guadalupe","Guadeloupe","Guam (US)","Guatemala","Guyana","Honduras","India","Indonesia","Jamaica","Japan","Kazakhstan",
//     "Kosovo","Liberia","Madagascar","Martinique","Mexico","Monaco","Mongolia","Montenegro","Montserrat (UK)",
//     "Nauru","Papua New Guinea","Paraguay","Peru","Philippines","Puerto Rico","Russia","Rwanda","Samoa","Slovakia",
//     "South Africa","St. Barthélemy","St. Kitts / Nevis","St. Lucia","St. Martin","St. Vincent & Grenadines","Thailand",
//     "Timor-Leste","Tonga","Trinidad & Tobago","Turks & Caicos Islands","Ukraine","Uruguay","US","U.S. Virgin Islands","Vanuatu","Zambia"
// ];

const FAQQuestionAnswer = [
  {
    Q: "Can all mobile phones be eSIM enabled or can use eSIM like the normal Sim Card ?",
    A: "eSIM can only be used only on certain phone models  , mostly the newer models , please make sure your phone is an eSIM compatible  phone."
  },
  {
    Q: "Is the eSIM  work the same as a conventional Sim Card ?",
    A: "Yes it works exactly the same as a Sim Card  , the only difference is , it's not a physical card & it doesn’t take up space in your phone ."
  },
  {
    Q: "How will I get my eSIM ?",
    A: "We will send your eSIM together with your room booking details , eSIM will come as a QR code , you need to just scan the QR code and follow the steps to download it ."
  },
  {
    Q: "How to download eSIM onto my phone ?",
    A: "It’s a 3 step process : go to Settings > Cellular > Add Cellular Plan > Scan QR Code ."
  },
  {
    Q: "Can I download and use / test my eSIM in my home country before my departure ?",
    A: "Yes you can download and use it the moment you receive it , but make sure to switch it off or not to use it much as it comes with limited data ."
  },
  {
    Q: "Do I need to install or make changes when I,m in my new destination / country ?",
    A: "It needs to download only once , it will automatically connect with a designated service provider in your new destination / country . If you have turned off the line after testing you just need to turn on the line again & turn on data roaming , that’s it ."
  },
  {
    Q: "Will I get only 1 eSIM or multiple eSIMs for my entire travel period ?",
    A: "We have different data limits based on group of countries , if you travel within a single group you will receive only 1 eSIM , if you travel from one  different group  to another group , we will send you 2 or more  eSIMs for you according to the group  . \n" +
      "( please refer to our group listing )\n"
  },
  {
    Q: "How about my primary line , what should I do with it , when I,m travelling ?",
    A: "Before you start your travel , just switch off data roaming in your primary line so you won't be charged for roaming , you can still receive your normal calls & SMS .\n" +
      "\n" +
      "{ Note :  please check the call & SMS charges with your mobile service provider }\n"
  },
  {
    Q: "Are there any limitations on usage or restricted to certain apps ?",
    A: "No any  restrictions are in place on usage , you can use it like your normal data connection or WiFi connection except it is limited by data plan  ."
  },
  {
    Q: "How will I know how much I have used & how much balance data is available ?",
    A: "We will send you an SMS notification once you have used 50% and 2nd notification at 90% ."
  },
  {
    Q: "Once I used the eSIM , can I remove the eSIM ?",
    A: "Yes you can , just go to your Settings >Cellular> Select the eSIM plan and just click Remove Cellular Plan and the eSIM will be deleted from your phone .\n" +
      "\n" +
      "{ Note : Settings may differ from phone brands , operating systems & device type , but this is the  basic steps }\n"
  },
  {
    Q: "Can I top up my eSIM ?",
    A: "At this moment you are unable to top up your data , you can only use the data provided ."
  },
  {
    Q: "Can I give the eSIM to someone else to use it ?",
    A: "Please don’t do that , eSIM is the same as your Sim card or phone line,  in the event if someone misuse your eSIM you will  be answerable  for it ."
  },
  {
    Q: "Can I use the eSIM on multiple devices ?",
    A: "You can only download your eSIM once & use it on one device at any moment of time . Like any other connection you can use hotspot to connect with other devices ."
  },
  {
    Q: "Once I delete the eSIM plan , can I download the eSIM again ?",
    A: "You can download the eSIM only once ,  if you delete it you can't use it again ."
  },
  {
    Q: "How about the coverage ?",
    A: "In all the destinations / countries we have at least 2 mobile operators , your phone will automatically connect with an operator’s network which has a stronger signal ."
  },
];


const Esim = () => {

  const { data: countryListing, loading: countryListingLoading, error } = useGoogleSheets({
    apiKey: 'AIzaSyDLTC_f9muGGR9zJtFA0iKzwBJXqN5_6Lc',
    sheetId: '1fETldVnSBYeRjt1FMOwU3-idEZ_6GEyJ0hvNCIe5Kmc',
  });

  const isMobile = useMediaQuery({
    maxWidth: 775
  })

  const ParentDiv = ({ children, title }) => {

    const [caret, setCaret] = useState(false);


    return !isMobile ? <>
      {title()}
      {children}
    </> : <Collapsible trigger={
      <span onClick={() => setCaret(!caret)}>
        {title(caret)}
      </span>
    }>
      {children}
    </Collapsible>
  }

  const processSupportedCountry = (countryList, title) => {

    return (
      <ParentDiv title={title}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', borderRadius: 10, border: '1px solid #E9EDEF', padding: '20px 40px', color: 'black' }}>
          {chuckArray(countryList, Math.round(countryList.length / (isMobile ? 2 : 3))).map((item, index) => {
            const individualItems = item.map(country => <li key={country.Name}>{country.Name}</li>)

            return (
              <div key={index} style={{ flex: 1 }}>
                <ul>
                  {individualItems}
                </ul>
              </div>
            )
          })}
        </div>
      </ParentDiv>
    )
  }

  const SupportedCountry = ({ num }) => {

    if (!countryListingLoading) {
      const countryListing1DayData = countryListing[0]?.data ?? [];
      const countryListing3DayData = countryListing[1]?.data ?? [];

      return (
        <>
          <div style={{
            justifyContent: 'center', display: 'flex', border: '2px solid #EB234A',
            borderRadius: 10,
            marginBottom: 20
          }}>
            <p style={{ fontSize: 18, fontWeight: 700, color: 'black', padding: 25, marginBottom: 0 }}>Country Listing</p>
          </div>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <div style={{ flex: 1, marginRight: isMobile ? 0 : 20 }}>
              {processSupportedCountry(countryListing1DayData, (caret) => (
                <div style={{ backgroundColor: 'rgba(233, 237, 239, 0.3)', borderRadius: 10, padding: 30, marginBottom: 20, fontSize: 16, fontWeight: 500, color: 'black', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ flex: 1, textAlign: 'center' }}>
                    1GB <span style={{ color: "#F48247" }}>Daily</span> Countries
                  </div>
                  {isMobile && <i style={{ fontSize: 30, color: "#EB234A" }} className={caret ? `fas fa-angle-down` : `fas fa-angle-left`}></i>}
                </div>
              ))}
            </div>
            <div style={{ flex: 1, marginTop: isMobile ? 15 : 0 }}>
              {processSupportedCountry(countryListing3DayData, (caret) => (
                <div style={{ backgroundColor: 'rgba(233, 237, 239, 0.3)', borderRadius: 10, padding: 30, marginBottom: 20, fontSize: 16, fontWeight: 500, color: 'black', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ flex: 1, textAlign: 'center' }}>
                    1GB <span style={{ color: "#F48247" }}>3 Days</span> Countries
                  </div>
                  {isMobile && <i style={{ fontSize: 30, color: "#EB234A" }} className={caret ? `fas fa-angle-down` : `fas fa-angle-left`}></i>}
                </div>
              ))}
            </div>
          </div>
        </>)
    }

    return <></>

  }
  const SupportedMobile = ({ num }) => {

    if (!countryListingLoading) {
      const countryListing1DayData = countryListing[2]?.data ?? [];
      return (
        <>
          <div style={{
            justifyContent: 'center', display: 'flex', border: '2px solid #EB234A',
            borderRadius: 10,
            marginBottom: 20
          }}>
            <p style={{ fontSize: 18, fontWeight: 700, color: 'black', padding: 25, marginBottom: 0 }}>Supported Mobile Device</p>
          </div>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <div style={{ flex: 1, marginRight: isMobile ? 0 : 20 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', borderRadius: 10, border: '1px solid #E9EDEF', padding: '20px 40px', color: 'black' }}>
                {chuckArray(countryListing1DayData, Math.round(countryListing1DayData.length / (isMobile ? 2 : 5))).map((item, index) => {
                  const individualItems = item.map(mobile => <li key={mobile.Device}>{mobile.Device}</li>)

                  return (
                    <div key={index} style={{ flex: 1 }}>
                      <ul>
                        {individualItems}
                      </ul>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>)
    }

    return <></>

  }

  const FAQElement = ({ num }) => {

    const title = (caret) => {

      // <div style={{backgroundColor:'rgba(233, 237, 239, 0.3)', borderRadius:10, padding:30, marginBottom:20, fontSize:16, fontWeight:500, color:'black',  justifyContent:'center', display:'flex', flexDirection:'row'}}>
      //   <div style={{flex:1, textAlign:'center'}}>
      //     1GB <span style={{color:"#F48247"}}>3 Days</span> Countries
      //   </div>
      //   {isMobile && <i style={{fontSize:30, color:"#EB234A"}} className={caret ? `fas fa-angle-down` : `fas fa-angle-left`}></i> }
      // </div>

      return (
        <div style={{
          justifyContent: 'center', display: 'flex', border: '2px solid #EB234A',
          alignItems: 'center',
          borderRadius: 10,
          marginBottom: 20
        }}>
          <div style={{ textAlign: 'right', fontSize: 18, fontWeight: 700, color: 'black', padding: 25 }}>
            FAQ
          </div>
          {isMobile && <i style={{ fontSize: 30, color: "#EB234A", textAlign: 'right', paddingRight: 25 }} className={caret ? `fas fa-angle-down` : `fas fa-angle-left`}></i>}
          {/*<p style={{fontSize:18, fontWeight:700, color:'black', padding:25, marginBottom:0, flex:1, textAlign: 'center'}}>*/}
          {/*  FAQ*/}
          {/*</p>*/}
        </div>
      )
    }

    return (
      <ParentDiv title={title}>
        <div style={{ marginLeft: '20px' }}>
          <FAQ defaultOpen={[]}>

            {FAQQuestionAnswer.map((item, index) => (
              <FAQ.QAItem>
                <FAQ.Question answerId={`q${index}`}>
                  {(isOpen, onToggle) => {
                    return (<div style={{ borderRadius: 10, border: '1px solid #E9EDEF', padding: 20, color: 'black' }}>
                      <span style={{ fontWeight: '600', fontSize: '16px' }}>
                        <span style={{ color: "#EB234A", fontSize: 25, marginRight: 20 }}>{isOpen ? "-" : "+"}</span>
                        {item.Q}
                      </span>
                    </div>);
                  }}
                </FAQ.Question>
                <FAQ.Answer id={`q${index}`}>
                  <div style={{ marginTop: '5px', backgroundColor: '#dddddd45', marginLeft: '20px', padding: '20px', borderRadius: '5px' }}>
                    {item.A}
                  </div>
                </FAQ.Answer>
              </FAQ.QAItem>
            ))}
          </FAQ>
        </div>
      </ParentDiv>
    )

  }

  const HowToInstallEsim = ({ num }) => {

    const [caret, setCaret] = useState({
      ios: false,
      android: false
    });

    return (
      <>
        <div style={{
          justifyContent: 'center', display: 'flex', border: '2px solid #EB234A',
          borderRadius: 10,
          marginBottom: 20
        }}>
          <p style={{ fontSize: 18, fontWeight: 700, color: 'black', padding: 25, marginBottom: 0 }}>How to Install E-Sim</p>
        </div>
        <Collapsible trigger={
          <div onClick={() => setCaret({ ...caret, ios: !caret.ios })} style={{ backgroundColor: 'rgba(233, 237, 239, 0.3)', borderRadius: 10, padding: 30, marginBottom: 20, fontSize: 16, fontWeight: 500, color: 'black', textAlign: 'center', display: 'flex' }}>
            <span style={{ flex: 1, fontSize: 16 }}>IOS</span>
            <i style={{ fontSize: 30, color: "#EB234A" }} className={caret.ios ? `fas fa-angle-down` : `fas fa-angle-left`}></i>
          </div>
        }
        >
          <div style={{ display: 'flex' }}>
            <img style={{ marginLeft: 'auto', marginRight: 'auto' }} src={isMobile ? "/iOS-m1.png" : "/iOS-1.png"} />
          </div>
          <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
            <img style={{ marginLeft: 'auto', marginRight: 'auto' }} src={isMobile ? "/iOS-m2.png" : "/iOS-2.png"} />
          </div>
        </Collapsible>
        <Collapsible trigger={
          <div onClick={() => setCaret({ ...caret, android: !caret.android })} style={{ backgroundColor: 'rgba(233, 237, 239, 0.3)', borderRadius: 10, padding: 30, marginBottom: 20, fontSize: 16, fontWeight: 500, color: 'black', textAlign: 'center', display: 'flex' }}>
            <span style={{ flex: 1, fontSize: 16 }}>Android</span>
            <i style={{ fontSize: 30, color: "#EB234A" }} className={caret.android ? `fas fa-angle-down` : `fas fa-angle-left`}></i>
          </div>
        }
        >
          <div style={{ display: 'flex' }}>
            <img style={{ marginLeft: 'auto', marginRight: 'auto' }} src={isMobile ? "/AND-m1.png" : "/AND-1.png"} />
          </div>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <img style={{ marginLeft: 'auto', marginRight: 'auto' }} src={isMobile ? "/AND-m2.png" : "/AND-2.png"} />
          </div>
        </Collapsible>
      </>
    )
  }

  const ImportantSteps = () => {
    return (
      <>
        <div style={{
          justifyContent: 'center', display: 'flex', border: '2px solid #EB234A',
          borderRadius: 10,
          marginBottom: 20
        }}>
          <p style={{ fontSize: 18, fontWeight: 700, color: 'black', padding: 25, marginBottom: 0 }}>Important Steps</p>
        </div>
        <div style={{ display: 'flex', flex: 3, color: 'black', flexDirection: isMobile ? 'column' : 'row' }}>
          <div style={{ flex: 1, borderRadius: 10, border: '1px solid #E9EDEF', marginRight: isMobile ? 0 : 20, padding: 20 }}>
            Default Line
            <div style={{ backgroundColor: "#e9edef73", padding: 10, borderRadius: 10, fontWeight: 600, marginTop: 10 }}>
              <span style={{ color: "#EB234A" }}>--></span> Select Primary Line
            </div>
          </div>
          <div style={{ flex: 1, borderRadius: 10, border: '1px solid #E9EDEF', marginRight: isMobile ? 0 : 20, padding: 20, marginTop: isMobile ? 10 : 0 }}>
            Cellular Data
            <div style={{ backgroundColor: "#e9edef73", padding: 10, borderRadius: 10, fontWeight: 600, marginTop: 10 }}>
              <span style={{ color: "#EB234A" }}>--></span> Select Primary Line
            </div>
          </div>
          <div style={{ flex: 1, borderRadius: 10, border: '1px solid #E9EDEF', marginRight: isMobile ? 0 : 20, padding: 20, marginTop: isMobile ? 10 : 0 }}>
            Data Roaming
            <div style={{ backgroundColor: "#e9edef73", padding: 10, borderRadius: 10, fontWeight: 600, marginTop: 10 }}>
              <span style={{ color: "#EB234A" }}>--></span> Off For Primary Line
            </div>

            <div style={{ backgroundColor: "#e9edef73", padding: 10, borderRadius: 10, fontWeight: 600, marginTop: 10 }}>
              <span style={{ color: "#EB234A" }}>--></span> On For New Line
            </div>

          </div>
        </div>
      </>
    )
  }

  const TermsCondition = () => {
    return (
      <>
        <div style={{
          justifyContent: 'center', display: 'flex', border: '2px solid #EB234A',
          borderRadius: 10,
          marginBottom: 20
        }}>
          <p style={{ fontSize: 18, fontWeight: 700, color: 'black', padding: 25, marginBottom: 0 }}>eSIM Terms & Conditions</p>
        </div>
        <div style={{ marginTop: 10, border: '1px solid #E9EDEF', padding: 20, borderRadius: 10 }}>
          <div style={{ color: 'black' }}>
            <p style={{ fontWeight: 500, fontSize: 16 }}>1. 1 eSIM / Per Room</p>
            <p style={{ fontSize: 14 }}>
              We will provide 1 eSIM per room booking. <br /><br />
              1 room booking for 1 adult : 1 eSIM <br /><br />
              1 room booking for 2 adults : 1 eSIM <br /><br />
              2 room bookings for 2 adults : 2 eSIMs <br /><br />
              ( If someone made 2 room bookings for 2 adults in a single booking , we will send 2 eSIMs together with the booking confirmation )
            </p>

            <p style={{ fontWeight: 500, fontSize: 16, marginTop: 30 }}>2. Service availability & quality</p>
            <p style={{ fontSize: 14 }}>
              We are not responsible if the service is not constantly available or the roaming partner’s data speed is not as promised , all the roaming partners we work with worldwide provide 3G - 4G connectivity.
            </p>

            <p style={{ fontWeight: 500, fontSize: 16, marginTop: 30 }}>3. eSIM expiration</p>
            <p style={{ fontSize: 14 }}>
              The eSIM can be activated anytime , it will be terminated according to your check – out date , let's say if you are checking out today , the eSIM will deactivate the following day. eSIM also will deactivate automatically once the data plan is completely used.
            </p>

            <p style={{ fontWeight: 500, fontSize: 16, marginTop: 30 }}>4. Refund</p>
            <p style={{ fontSize: 14 }}>
              In the event if you cancel your room bookings , we will look into the eSIM usage. If you have used more than 50MB of the data we will charge you USD $ 1 / per 100 MB of additional data used from the eSIM.
            </p>

            <p style={{ fontWeight: 500, fontSize: 16, marginTop: 30 }}>5. Device Compatibility</p>
            <p style={{ fontSize: 14 }}>
              Please ensure your device is compatible to use the eSIM , device compatibility also depends on the carrier and country of origin , as some countries have disabled usage of eSIM in certain devices.
            </p>

            <p style={{ fontWeight: 500, fontSize: 16, marginTop: 30 }}>6. Service suspension</p>
            <p style={{ fontSize: 14 }}>
              We may suspend or disconnect the service without any prior notice , if we or any lawful government authorities observe any fraudulent use of the eSIM .
            </p>

            <p style={{ fontWeight: 500, fontSize: 16, marginTop: 30 }}>7. Illegal & misuse</p>
            <p style={{ fontSize: 14 }}>
              As the owner of the eSIM you are liable for any unauthorized or fraudulent activities conducted by using this eSIM , if required we will furnish all your details to the relevant authorities for their further action.
            </p>
            <p style={{ fontSize: 14 }}>
              If you suspect any breach or unauthorized use of your device or the eSIM , as a device owner please contact customer service immediately so that we can look into the issue and take the necessary action.              </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="docsPage" style={{ height: "auto" }}>
      <Header isMobile={isMobile} isDarkFont={true} />
      <div style={{ backgroundImage: "linear-gradient(to right, #F48247, #EB234A)", height: '400px', display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flex: 1, justifyContent: "center" }}>
          <p style={{ fontSize: '60px', alignSelf: 'center', padding: 20, color: 'white', fontFamily: 'Poppins', paddingRight: 80, marginTop: 20 }}>FREE Roaming</p>
          {!isMobile && <img style={{ padding: 20 }} src="/eSIM-Banner.png" />}
        </div>
      </div>
      <div className="docsContainer">
        <div className="docsPara">
          <SupportedCountry />
          <div style={{ marginTop: isMobile ? 30 : 60 }}>
            <FAQElement />
          </div>
          <div style={{ marginTop: isMobile ? 10 : 60 }}>
            <TermsCondition />
          </div>
          <div style={{ marginTop: 60 }}>
            <HowToInstallEsim />
          </div>
          <div style={{ marginTop: 60 }}>
            <ImportantSteps />
          </div>
          <div style={{ marginTop: 60 }}>
            <SupportedMobile />
          </div>
        </div>
      </div>
      <FooterPage />
    </div>
  );
};

export default Esim;
