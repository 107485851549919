import { message } from 'antd';
import Axios from 'axios';
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';

const useResetNewPasswordFormLogic = (props) => {
    const {
        form
    } = props
    const history = useHistory()

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

    const onClickSubmit = async () => {
        setIsLoadingSubmit(true)
        try {
            if (form) {
                const values = await form.validateFields();
                const { newPassword, confirmPassword } = values;

                // Send POST request to update password
                const response = await Axios.post('/auth/password/reset', {
                    password: newPassword,
                    forgetPasswordKey: new URLSearchParams(window.location.search).get('key'),
                    email: new URLSearchParams(window.location.search).get('email'),
                });

                if (response.data.message === 'Password has been successfully updated') {
                    // setIsPasswordHasBeenChanged(true)
                    message.success('Password has been successfully updated.');
                    form.resetFields();  // Reset form fields

                    setTimeout(() => {
                        history.push('/reset-password/success')
                    }, 100)
                } else {
                    message.error(response.data.message || 'Failed to update password.');
                }
            }
        } catch (error) {
            console.error(error);
            message.error('An error occurred while updating the password.');
        } finally {
            setIsLoadingSubmit(false)
        }
    };


    const getButtonActionTitle = () => isLoadingSubmit ? 'Resetting Password' : 'Reset Password'


    const isButtonDisabled = useCallback(() => {
        const newPassword = form.getFieldValue('newPassword');
        const confirmPassword = form.getFieldValue('confirmPassword');

        console.log(newPassword,confirmPassword)
        return !(newPassword && confirmPassword && newPassword === confirmPassword && newPassword.length >= 8);
    }, [form]);

    return {
        isLoadingSubmit,
        onClickSubmit,
        getButtonActionTitle,
        isButtonDisabled
    }
}

export default useResetNewPasswordFormLogic