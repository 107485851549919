import React from 'react'
import LoginBg from 'images/login_bg_1.png';
import { useIsDesktop } from 'hooks';

const useAcceptTeamInvitationStyles = () => {
    const isDesktop = useIsDesktop()
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '4px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        form: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '4px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 32px',
            marginTop: '64px'
        },
        wrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '4px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        formSuccess: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '32px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '64px'
        },
        successTitleGroup: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '16px',
        },
        successIcon: {
            color: 'green',
        },
        memberIcon: {
            color: '#d93b4f',
        },
        errorIcon: {
            color: 'red',
        },
        actionGroup: {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        signUpForm: {
            backgroundImage: `url(${LoginBg})`,
            padding: '30px',
            // width: isDesktop ? '500px !important' : 'auto'
        }
    }

    return {
        styles
    }
}

export default useAcceptTeamInvitationStyles