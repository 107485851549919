import React from "react";
import "pages/LegalDocuments.css";
import Header from "components/Header/Header";
import background from "images/aboutus.jpg";
import { useMediaQuery } from "react-responsive";
import FooterPage from "components/Global/FooterPage/FooterPage";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const isMobile = useMediaQuery({
    maxWidth: 775
  })

  const { t } = useTranslation()

  return (
    <div className="docsPage">
      <Header isMobile={isMobile} isDarkFont={true} />
      <div
        className="aboutUsMainContainer docsbackground"
        style={{ backgroundImage: `url( ${background} )` }}
      >
        <div className="aboutUs">
          <span className="docsHeading" style={{ marginLeft: 0 }}> {t(`about_us_title`)}</span>
          <div className="docsSubHeading" style={{ color: "white" }}>
            {t(`about_us_heading_one`)}
          </div>
          <div className="docsSubHeading" style={{ color: "white" }}>
            {t(`about_us_heading_two`)}
          </div>
        </div>
      </div>
      <FooterPage />
    </div>
  );
};

export default AboutUs;
