import React, { Component } from 'react';

import { TransactionListRow } from './TransactionListRow';
import './Transaction.styles.css';
import Axios from 'axios'

export default class Transaction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: []
        }
    }
    componentDidMount = async () => {
        const email = localStorage.getItem("useremail");
        let { data } = await Axios.get('/users/transactions/' + email);
        this.setState({
            loading: false,
            data: data
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ display: "flex", height: "100vh" }}>
                    <div style={{ margin: "auto" }}>Loading</div>
                </div>
            )
        }

        return (
            <div className="tab-inn">
                <div className="table-responsive table-desi">
                    <table className="table table-hover" id="customers">
                        <thead>
                            <tr>
                                <th>Reference Number</th>
                                <th>Rate ID</th>
                                <th>User ID</th>
                                <th>Hotel Name</th>
                                <th>Total Rate</th>
                                <th>Base Rate</th>
                                <th>Currency</th>
                                <th>Provider ID</th>
                                <th>Status</th>
                                <th>
                                    <tr>Commission</tr>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Description</th>
                                        <th>Type</th>
                                    </tr>
                                </th>
                                <th>
                                    <tr>Taxes</tr>
                                    <tr>
                                        <th>Amount</th>
                                        <th>Description</th>
                                        <th>Type</th>
                                    </tr>
                                </th>
                                <th>Refundable</th>
                                <th>Deposit Required</th>
                                <th>Guarantee Required</th>
                                <th>Online Cancellable</th>
                                <th>Pay At Hotel</th>
                                <th>Generate Receipt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((transaction) => <TransactionListRow key={transaction.refNo} transaction={transaction} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}