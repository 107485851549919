import { Select } from "antd";
import CountryRegionData from "country-region-data/data.json";
import React from "react";

const { Option } = Select;

const CountrySelector = React.forwardRef((props, ref) => {
  return (
    <Select
      showSearch
      placeholder={props.placeholder || "Select a country"}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      style={props.style}
      ref={ref}
    >
      {CountryRegionData.map((countryInfo) => (
        <Option value={countryInfo.countryShortCode} key={countryInfo.countryName}>
          {countryInfo.countryName}
        </Option>
      ))}
    </Select>
  );
});

export default CountrySelector;


// import { Select } from "antd";
// import CountryRegionData from "country-region-data/data.json";
// import React, { Component } from "react";

// const { Option } = Select;

// export class CountrySelector extends Component {
//   render() {
//     return (
//       <Select
//         showSearch
//         placeholder={this.props.placeholder || "Select a country"}
//         optionFilterProp="children"
//         filterOption={(input, option) =>
//           option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
//         }
//         value={this.props.value}
//         onChange={this.props.onChange}
//         style={this.props.style}
//         ref={this.props.ref}
//       >
//         {CountryRegionData.map((countryInfo) => (
//           <Option value={countryInfo.countryShortCode} key={countryInfo.countryName}>
//             {countryInfo.countryName}
//           </Option>
//         ))}
//       </Select>
//     );
//   }
// }
