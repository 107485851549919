import React from "react";
import "./FooterPage.styles.css";
import { FooterDesktop } from "components/Global/FooterDesktop/FooterDesktop";
import { FooterMobile } from "components/Global/FooterMobile/FooterMobile";
import MediaQuery from "react-responsive";
import { useCurrencyContext } from "hooks/useCurrencyContext";

const FooterPage = () => {
  const [selectedCurrency, setCurrency] = useCurrencyContext();
  const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }

  const changeCurrency = (val) => {
    setCurrency(val);
    localStorage.setItem("currency", val);
  };
  return (
    <>
      <MediaQuery maxWidth={775}>
        <FooterMobile showEsim={!hasProvider} currency={selectedCurrency} changeCurrency={changeCurrency} />
      </MediaQuery>
      <MediaQuery minWidth={776}>
        <FooterDesktop showEsim={!hasProvider} currency={selectedCurrency} changeCurrency={changeCurrency} />
      </MediaQuery>
    </>
  );
};

export default FooterPage;
