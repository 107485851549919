export const styles = {
    formItemLabel: {
        color: 'white',
    },
    facebookButton: {
        backgroundColor: "#405a93",
        color: "white",
        width: "100%",
        padding: "1rem 0",
        height: "auto",
        borderRadius: "3px",
        marginBottom: "1rem",
    },
    googleButton: {
        backgroundColor: "#cf5b4d",
        color: "white",
        width: "100%",
        padding: "1rem 0",
        height: "auto",
        borderRadius: "1px",
        marginBottom: "1rem",
    },
    checkbox: {
        color: 'white',
    },
    forgotPasswordButton: {
        float: "right",
        color: 'white',
        paddingTop: 10,
        fontSize: 12,
    },
    forgotPasswordText: {
        textDecoration: 'underline',
    },
    submitButton: {
        marginRight: "1rem",
        width: "100%",
    },
    registerLink: {
        paddingLeft: '5px',
        color: 'white',
    },
    registerText: {
        color: 'white',
        fontSize: 14,
    },
    formItem: {
        margin: 0,
    }
};