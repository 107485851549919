import { Button, Form, Radio } from "antd";
import React from "react";
import ReactDataTables from "components/Global/ReactDataTables/ReactDataTables"
import useDashboardContentTeamPageStyles from "./DashboardContentTeam.styles";

import { tableColumns } from "./DashboardContentTeamTableColumn";
import InviteTeamMemberModal from "../InviteTeamMemberModal/InviteTeamMemberModal";
import useDashboardContentTeamPageLogic from "./DashboardContentTeam.logic";

import ConfirmModal from "components/Global/ConfirmModal/ConfirmModal"

const DashboardContentTeamPage = ({ ...props }) => {
    const { styles } = useDashboardContentTeamPageStyles()

    const logicFunctions = useDashboardContentTeamPageLogic({
        form: props.form
    })

    const {
        form,
        getTeamMember,
        getAllTeamMembersData,
        handleEditClick,
        handleActionClick,
        handleSubmit,
        handleRemoveTeamMember,
        handleResendEmailInvitation,
        handleRoleChange,
        handleFormChange,
        handleSuccess,
        handleFilterChange,
        onClickInviteTeamButton,
        inviteTeamModalVisible,
        currentTeamMemberId,
        currentTeamMemberData,
        roles,
        isButtonDisabled,
        data,
    } = logicFunctions

    return (
        <div className="teamSection">
            <div style={styles.container}>
                <div className="titleRow">
                    <h2>Team</h2>
                    <Button type="primary" onClick={onClickInviteTeamButton}>
                        Invite team
                    </Button>
                </div>

                <hr />


                <InviteTeamMemberModal
                    {...logicFunctions}
                />
                
                <ConfirmModal />

                {/* <Modal
                    title={null}
                    footer={null}
                    visible={inviteTeamModalVisible}
                    onCancel={handleSuccess}
                    className="inviteTeamModal"
                    width={"auto"}
                >
                    <div className="modalWrapper">
                        <h2>{currentTeamMemberId == null ? 'Invite your' : 'Edit your'} team</h2>

                        <Form onChange={handleFormChange}>

                            <div className="inputContainer">
                                <div className="inputDescription">Email address</div>
                                <Form.Item style={styles.formItem}>
                                    {props.form.getFieldDecorator("teamMemberEmailAddress", {
                                        rules: [
                                            {
                                                type: "email",
                                                message: "The input is not valid E-mail!",
                                            },
                                            {
                                                required: true,
                                                message: "Please input your team member's email address",
                                            },
                                        ],
                                        initialValue: ``,
                                    })(
                                        <Input
                                            // disabled={currentTeamMemberData?.status !== 'waiting'}
                                            style={{ border: "none", width: "100%" }}
                                        />
                                    )}
                                </Form.Item>
                            </div>

                            <hr />

                            <h2>Users permissions</h2>

                            <ul>
                                {INDOSAT_B2B_TEAM_MEMBERS_ROLES?.map(role => (
                                    <li key={role.id}>
                                        <div className="checkboxGroup">
                                            <h4>{role.description}</h4>
                                            <input
                                                type="checkbox"
                                                checked={roles?.includes(role.id) || role.id == 1001}
                                                onChange={(e) => handleRoleChange(role.id, e.target.checked)}
                                            />
                                        </div>
                                    </li>
                                ))}
                                {(roles?.includes(1004) && !(roles?.includes(1002) || roles?.includes(1003))) && <p>If option #4 is selected then you would have to select either option #2 or #3 as well</p>}
                            </ul>


                            <div style={{
                                display: currentTeamMemberData?.status == 'cancelled' ? 'none' : 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '100%',
                                columnGap: '16px'
                            }}>

                                <Button onClick={handleRemoveTeamMember} style={{
                                    marginLeft: 'auto',
                                    display: currentTeamMemberId ? 'block' : 'none',
                                }}
                                >
                                    Remove Team Member
                                </Button>

                                {currentTeamMemberData?.approvedAt ? <></> : <Button onClick={handleResendEmailInvitation} style={{
                                    margin: '0 5px',
                                    display: currentTeamMemberId ? 'block' : 'none',
                                }}
                                >
                                    Resend email invitation
                                </Button>
                                }

                                <Button type="primary" onClick={handleSubmit}
                                    disabled={(isButtonDisabled || (roles?.includes(1004) && !(roles?.includes(1002) || roles?.includes(1003))))}
                                >
                                    {currentTeamMemberId == null ? 'Invite' : 'Save'}
                                </Button>
                            </div>

                        </Form>
                    </div>

                </Modal> */}

                <Radio.Group defaultValue="" buttonStyle="solid" style={styles.radioGroup}
                    onChange={handleFilterChange}
                >
                    <Radio.Button value="">All</Radio.Button>
                    <Radio.Button value="approved">Approved</Radio.Button>
                    <Radio.Button value="waiting">Waiting</Radio.Button>
                    <Radio.Button value="cancelled">Cancelled</Radio.Button>
                    <Radio.Button value="disabled">Disabled</Radio.Button>
                </Radio.Group>

                <ReactDataTables data={data} columns={tableColumns} onEdit={handleEditClick} onAction={handleActionClick} />

            </div>
        </div >
    )
}

const DashboardContentTeamPageComponent = Form.create({ name: "DashboardContentTeamPageComponent" })(DashboardContentTeamPage);

const DashboardContentTeam = ({ ...props }) => {
    const { styles } = useDashboardContentTeamPageStyles()

    return (
        <div style={styles.mainContainer} className="PersonalDetails DashboardContentTeamPageComponent">
            <DashboardContentTeamPageComponent {...props} />
        </div>
    )
}


export default DashboardContentTeam;
