import React from 'react'
import ModalV2 from 'components/Global/ModalV2/ModalV2'
import { confirmModalVisibleState } from "recoil/atoms"

const ConfirmModal = () => {
    return (
        <ModalV2
            recoilState={confirmModalVisibleState}
            children={
                <div>
                    <h2>Are you sure you would like to promote this user to be a a PIC?</h2>
                </div>
            }
            // bodyStyle={styles.bodyStyle}
            // style={styles.style}
            // className="loginModal"
        />

    )
}

export default ConfirmModal