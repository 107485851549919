import React, { useState, useEffect } from "react";
import "../../LegalDocuments.css";
import { Icon, Spin } from "antd";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { useMediaQuery } from "react-responsive";
import Header from "components/Header/Header";

const ResetPassword = () => {
    const history = useHistory();

    const isMobile = useMediaQuery({
        maxWidth: 775
    })

    const params = new URLSearchParams(history.location.search)

    const email = params.get('email');

    const key = params.get('key');


    const [isLoading, setIsLoading] = useState(true);
    const [showFail, setShowFail] = useState({ status: false, msg: '' });


    const requestChange = async () => {
        let response = await Axios.get("users/reset-password", {
            params: {
                key,
                email
            }
        });

        setIsLoading(false);

        if (response.data.success === true) {
            setTimeout(() => {
                document.location.href = "/";
            }, 5000);

        } else {
            setShowFail({ status: true, msg: response.data.msg })
        }
    }


    useEffect(() => {

        requestChange();
        // setTimeout(() => setIsLoading(false), 3000)

    }, []);


    return (
        <div className="docsPage" style={{ height: "auto" }}>
            <Header isMobile={isMobile} isDarkFont={true} />
            <div className="DesktopSkeleton" style={{ overflow: "auto" }}>
                <div className="modalOverlay">
                    <div className="overlayContainer">
                        {!showFail.status && <Spin
                            indicator={
                                <Icon
                                    type="loading-3-quarters"
                                    style={{ fontSize: "6rem", color: "#EB234A" }}
                                    spin
                                />
                            }
                        />}
                        <div style={{ margin: "2rem 0", opacity: 1, zIndex: 20 }}>

                            {showFail.status && <div>Password Reset Failed. ({showFail.msg})</div>}

                            {!showFail.status &&
                                (isLoading ? `Password resetting for user : ${email}` : `Password successfully reset. Please check your email.`)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;