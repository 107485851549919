import React from "react";
import Profile from "pages/Auth/Profile/Profile";

const DashboardContentProfile = ({ isMobile, ...props }) => {
    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;

    return (
        <div style={{
            // width: '100vw',
            marginTop: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

            <div style={{
                backgroundColor: 'white',
                width: 'fit-content',
                minWidth: isDesktopScreen ? '750px' : 'auto',
                padding: '40px',
            }}>

                <Profile />
            </div>
        </div>
    )
}

export default DashboardContentProfile;
