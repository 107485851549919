import { Carousel } from "antd";
import React, { useState } from "react";
import Header from "components/Header/Header";
import SearchBar from "components/SearchBar/SearchBar";

import { PopularDestinations } from "./PopularDestinations";
import { NewsLetter } from "components/Global/NewsLetter/NewsLetter";

import des1 from "images/Home/des1.jpg"
import des2 from "images/Home/des2.jpg"
import des3 from "images/Home/des3.jpg"
import des4 from "images/Home/des4.jpg"
import des5 from "images/Home/des5.jpg"
import des6 from "images/Home/des6.jpg"
import des7 from "images/Home/des7.jpg"
import des8 from "images/Home/des8.jpg"
import des9 from "images/Home/des9.jpg"
import des10 from "images/Home/des10.jpg"
import HomeContentIndosat from "components/Provider/Indosat/HomeContentIndosat";
import { IndosatNewsLetter } from "components/Global/IndosatNewsLetter/IndosatNewsLetter";
import HomeContentYoodo from "components/Provider/Yoodo/HomeContent";
import HomeContentBusiness from "components/Provider/Business/HomeContentBusiness";
import { useTranslation } from "react-i18next";

import { useCurrencyContext } from "hooks/useCurrencyContext";

import FooterPage from "components/Global/FooterPage/FooterPage";

export const HomeMobile = ({ hasProvider, provider, history }) => {
  const { t } = useTranslation()
  const [openLogin, setOpenLogin] = useState(false);
  const [providerConfig, setProviderConfig] = useState({
    status: false,
    name: ''
  });
  // const [currency, setCurrency] = useState("MYR");

  const [selectedCurrency, setCurrency] = useCurrencyContext();

  // useEffect(() => {
  //   setCurrency(localStorage.getItem("currency") || 'MYR');
  // }, []);

  const changeCurrency = (val) => {
    localStorage.setItem("currency", val);
    setCurrency(val);
  };

  let pConfig = {
    name: null,
    textColor: '',
    searchBar: () => { },
    content: () => { }
  };

  switch (provider) {
    case 'yoodo':
      pConfig.name = provider;
      pConfig.textColor = "black";

      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
    case 'indosat':
      pConfig.name = provider;
      pConfig.textColor = "black";

      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
    case 'business':
      pConfig.name = provider;
      pConfig.textColor = "black";

      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
    case 'business-dashboard':
      pConfig.name = provider;
      pConfig.textColor = "black";

      localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')),
        config: pConfig
      }))
      break;
    default:
      break;
  }


  const places = [
    {
      place: "Kuala Lumpur, Malaysia",
      placeId: 228226,
      url: des1,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Singapore",
      placeId: 246673,
      url: des2,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Sydney, Australia",
      placeId: 383923,
      url: des3,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "London, United Kingdom",
      placeId: 435089,
      url: des4,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "New York, United States",
      placeId: 4349,
      url: des5,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Qatar",
      placeId: 213428,
      url: des6,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Paris, France",
      placeId: 665191,
      url: des7,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Taipei, Taiwan",
      placeId: 246729,
      url: des8,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Athens, Greece",
      placeId: 480712,
      url: des9,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Istanbul, Turkey",
      placeId: 213435,
      url: des10,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
  ];

  const DefaultHomeBar = () => {
    return (
      <>
        <div className="homeHeading" style={{ width: '100%', textAlign: "center", marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ fontSize: '3rem', fontWeight: 'bolder', color: '#050a30' }}>
            {t('free_roaming_with')}
          </div>
          <div style={{ fontSize: '3rem', fontWeight: 'bolder', color: '#050a30' }}>
            {t('every_booking')}
          </div>
        </div>
        <SearchBar showLogin={() => setOpenLogin(new Date())} history={history} />
        <div className="homeHeading" style={{ width: "100%", textAlign: 'center' }}>
          {t('we_have_900000_properties')}
        </div>
      </>
    )
  }

  const YoodoHomeBar = () => {
    return (
      <>
        <div className="homeHeading" style={{ width: '100%', textAlign: "center", marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ fontSize: '3rem', fontWeight: 'bolder', color: '#050a30' }}>
            Free roaming with
          </div>
          <div style={{ fontSize: '3rem', fontWeight: 'bolder', color: '#050a30' }}>
            every booking
          </div>
        </div>
        <SearchBar showLogin={() => setOpenLogin(new Date())} history={history} />
        <div className="homeHeading" style={{ width: "100%", textAlign: 'center' }}>
          We have over 900,000 properties worldwide
        </div>
      </>
    )
  }
  const IndosatHomeBar = () => {
    return (
      <>
        <div className="homeHeading" style={{ width: '100%', textAlign: "center", marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ fontSize: '4rem', color: 'rgb(217, 59, 79)' }}>
            {t('free_roaming_with')}
          </div>
          <div style={{ fontSize: '4rem', color: 'rgb(217, 59, 79)' }}>
            {t('every_booking')}
          </div>
          <div style={{ marginTop: 20, fontSize: '1.5rem', color: 'black', fontWeight: 'bold' }}>
            {t('book_your_rooms_and_enjoy')}
          </div>
          <div style={{ marginTop: 20, fontSize: '1.5rem', color: 'black', fontWeight: 'bold' }}>
            {t('malaysia_maldives_qatar')}
          </div>
        </div>
        <SearchBar showLogin={() => setOpenLogin(new Date())} history={history} />
        <div className="homeHeading" style={{ width: "100%", textAlign: 'center' }}>
          {t('we_have_900000_properties')}
        </div>
      </>
    )
  }
  const BusinessHomeBar = () => {
    return (
      <>
        <div className="homeHeading" style={{
          width: '100%',
          textAlign: "center",
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
          <div style={{ fontSize: '4rem', color: 'rgb(217, 59, 79)' }}>
            Book Your Rooms & Stay Connected With
          </div>
          <div style={{ fontSize: '4rem', color: 'rgb(217, 59, 79)' }}>
            FREE Roaming
          </div>
        </div>
      </>
    )
  }

  const DefaultHomeContent = () => {
    return (
      <Carousel autoplay={true} dotsClass="carouselContainerDots" dots={true}>
        {places.map((place, index) => (
          <div key={index} className="carouselContainer">
            <PopularDestinations key={index} {...place} showLogin={() => setOpenLogin(!openLogin)} />
          </div>
        ))}
      </Carousel>
    )
  }

  let HomeBar = () => <DefaultHomeBar />;
  let HomeCont = () => <DefaultHomeContent />;
  let HomeNewsLetter = () => <NewsLetter />
  switch (provider) {
    case 'yoodo':
      HomeBar = () => <YoodoHomeBar />
      HomeCont = () => <HomeContentYoodo isMobile setOpenLogin={setOpenLogin} currency={selectedCurrency} history={history} />
      HomeNewsLetter = () => <IndosatNewsLetter />
      break;
    case 'indosat':
      HomeBar = () => <IndosatHomeBar />
      HomeCont = () => <HomeContentIndosat isMobile setOpenLogin={setOpenLogin} currency={selectedCurrency} history={history} />
      HomeNewsLetter = () => <IndosatNewsLetter />
      break;
    case 'business':
      HomeBar = () => <BusinessHomeBar />
      HomeCont = () => <HomeContentBusiness isMobile setOpenLogin={setOpenLogin} currency={selectedCurrency} history={history} />
      HomeNewsLetter = () => <IndosatNewsLetter />
      break;
  }

  return (
    <div className="HomeMobile">
      <Header
        openLoginModal={openLogin}
        isMobile={true}
      />

      <HomeBar />
      <HomeCont />
      <HomeNewsLetter />
      <FooterPage hasProvider={hasProvider} />
    </div>
  );
};
