import Axios from 'axios';
import { getBaseURL } from 'constants/Endpoint';

// Define the base URL
const BASE_URL = getBaseURL();  // Replace with your base URL
// const BASE_URL = 'https://api.visitorsdeals.com';  // Replace with your base URL
// const BASE_URL = process.env.REACT_APP_BACKEND_URL;  // Replace with your base URL

// Helper function for making API requests
const AxiosRequestHelper = async (method, url, data = {}, config = {}) => {
    try {
        // Add the frontend app URL to the payload
        const payload = {
            ...data,
            FRONTEND_APP_URL: "https://dev.visitorsdeals.com",
            // FRONTEND_APP_URL: process.env.REACT_APP_URL,
            ok: "try"
        };

        // Make the request using Axios
        const response = await Axios({
            method,
            url: `${BASE_URL}${url}`,
            // data: payload,
            data: payload,
            ...config, // Optional: you can pass additional config here
        });

        return response;
    } catch (error) {
        // Handle errors (you can customize this as needed)
        if (error.response && error.response.data && error.response.data.message) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error(error.message);
        }
    }
};

export default AxiosRequestHelper;
