import LoginBg from 'images/login_bg_1.png';

export const styles = {
  bodyStyle: {
    backgroundImage: `url(${LoginBg})`,
    backgroundSize: 'cover',
    padding: '30px',
  },
  style: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
}