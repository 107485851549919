
import { INDOSAT_B2B_TEAM_MEMBERS_ROLES } from "helpers/CheckIndosatTeamMemberRolesHelper";
import { Form, Input, Button } from 'antd'
import React from 'react'
import useInviteTeamMemberFormStyles from './InviteTeamMemberForm.styles'

const InviteTeamMemberForm = (props) => {
    const {
        form,
        isButtonDisabled,
        currentTeamMemberId,
        currentTeamMemberData,
        handleFormChange,
        handleSubmit,
        handleRoleChange,
        handleRemoveTeamMember,
        handleResendEmailInvitation,
        roles,
    } = props

    const { styles } = useInviteTeamMemberFormStyles()

    return (
        <div className="modalWrapper">
            <h2>{currentTeamMemberId == null ? 'Invite your' : 'Edit your'} team</h2>

            <Form onChange={handleFormChange}>

                <div className="inputContainer">
                    <div className="inputDescription">Email address</div>
                    <Form.Item style={styles.formItem}>
                        {props.form.getFieldDecorator("teamMemberEmailAddress", {
                            rules: [
                                {
                                    type: "email",
                                    message: "The input is not valid E-mail!",
                                },
                                {
                                    required: true,
                                    message: "Please input your team member's email address",
                                },
                            ],
                            initialValue: ``,
                        })(
                            <Input
                                disabled={currentTeamMemberData?.status == 'cancelled'}
                                style={{ border: "none", width: "100%" }}
                            />
                        )}
                    </Form.Item>
                </div>

                <hr />

                <h2>Users permissions</h2>

                <ul>
                    {INDOSAT_B2B_TEAM_MEMBERS_ROLES?.map(role => (
                        <li key={role.id}>
                            <div className="checkboxGroup">
                                <h4>{role.description}</h4>
                                <input
                                    type="checkbox"
                                    checked={roles?.includes(role.id) || role.id == 1001}
                                    onChange={(e) => handleRoleChange(role.id, e.target.checked)}
                                />
                            </div>
                        </li>
                    ))}
                    {(roles?.includes(1004) && !(roles?.includes(1002) || roles?.includes(1003))) && <p>If option #4 is selected then you would have to select either option #2 or #3 as well</p>}
                </ul>


                {currentTeamMemberData?.status == 'cancelled' ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        columnGap: '16px'
                    }}>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={(isButtonDisabled || (roles?.includes(1004) && !(roles?.includes(1002) || roles?.includes(1003))))}
                            style={{
                                marginLeft: 'auto',
                                display: currentTeamMemberId ? 'block' : 'none',
                            }}
                        >
                            Reinvite Team Member
                        </Button>
                    </div>

                ) : (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        columnGap: '16px'
                    }}>

                        <Button onClick={handleRemoveTeamMember} style={{
                            marginLeft: 'auto',
                            display: currentTeamMemberId ? 'block' : 'none',
                        }}
                        >
                            Cancel Invitation
                        </Button>

                        {currentTeamMemberData?.approvedAt ? <></> : <Button onClick={handleResendEmailInvitation} style={{
                            margin: '0 5px',
                            display: currentTeamMemberId ? 'block' : 'none',
                        }}
                        >
                            Resend email invitation
                        </Button>
                        }

                        <Button type="primary" onClick={handleSubmit}
                            disabled={(isButtonDisabled || (roles?.includes(1004) && !(roles?.includes(1002) || roles?.includes(1003))))}
                        >
                            {currentTeamMemberId == null ? 'Invite' : 'Save'}
                        </Button>
                    </div>
                )
                }

            </Form >
        </div >

    )
}

export default InviteTeamMemberForm