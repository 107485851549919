import React from 'react'

import ModalV2 from 'components/Global/ModalV2/ModalV2'
import { b2bIndosatInviteTeamMemberModalVisibleState } from 'recoil/atoms'
import InviteTeamMemberForm from '../InviteTeamMemberForm/InviteTeamMemberForm'

const InviteTeamMemberModal = (props) => {
    return (
        <ModalV2
            recoilState={b2bIndosatInviteTeamMemberModalVisibleState}
            children={
                <InviteTeamMemberForm
                    {...props}
                />
            }
            className="inviteTeamModal"
        />
    )
}

export default InviteTeamMemberModal