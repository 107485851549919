import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import MediaQuery from "react-responsive";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";
import "./RoomPriceContainer.css";
import { _defaultHeaders, getCurrency, providerURL } from "helpers/AxiosHelper";
import Axios from "axios";
import CheckIndosatTeamMemberRolesHelper from "helpers/CheckIndosatTeamMemberRolesHelper";
import Facilities from "components/Hotel/Facilities/Facilities";
import RoomPrice from "components/Hotel/RoomPrice/RoomPrice";

Array.prototype.hasMin = function (attrib) {
  return (
    (this.length &&
      this.reduce((prev, curr) => (prev[attrib] < curr[attrib] ? prev : curr))) ||
    null
  );
};

const RoomPriceContainer = (props) => {
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [modal, setModal] = useState(false);
  const [galleryStart, setGalleryStart] = useState(0);
  const [roomsDisplayed, setRoomsDisplayed] = useState(
    props.roomList.recomendation.length
  );
  const [recomendationData, setRecomendationData] = useState([]);
  const isAllowedToBookIndosatB2B = CheckIndosatTeamMemberRolesHelper(1002)

  useEffect(() => {
    if (props.roomList.recomendation.length === 0) props.itemFailedToLoad();
    loadAllRecomend();
  }, []);

  const roomFailedToLoad = () => {
    setRoomsDisplayed((prevRoomsDisplayed) => {
      const newRoomsDisplayed = prevRoomsDisplayed - 1;
      if (newRoomsDisplayed <= 0) props.itemFailedToLoad();
      return newRoomsDisplayed;
    });
  };

  const handleLoadMore = () => {
    setLoadMore(!loadMore);
  };

  const open = (index) => {
    document.body.style.overflow = "hidden";
    setGalleryStart(index);
    setModal(true);
  };

  const close = () => {
    document.body.style.overflow = "auto";
    setModal(false);
  };

  const loadRecomendation = async (recomenda) => {
    let currency = getCurrency();

    const headers = _defaultHeaders(currency, props.location.state.correlationId);

    const baseUrl = providerURL();

    const response = await Axios.get(
      `${baseUrl}/hotel/${props.hotelId}/${props.token}/price/recommendation/${recomenda}`,
      {
        headers: headers,
      }
    );

    return response;
  };

  const processRecomendation = async (roomRecomandations, roomData) => {
    const recomendationData = {
      refundable: {
        breakfast: [],
        without: [],
      },
      nonRefundable: {
        breakfast: [],
        without: [],
      },
    };

    for (let i = 0; i < roomRecomandations.length; i++) {
      let response = await loadRecomendation(roomRecomandations[i].id);

      if (response.data.hotel && response.data.hotel.rates?.length > 0) {
        response.data.hotel.rates[0].totalRate = parseFloat(
          response.data.hotel.rates[0].totalRate
        ).toFixed(2);
        const isRefundable =
          response.data.hotel.rates[0].refundability === "Refundable";

        const key = isRefundable ? "refundable" : "nonRefundable";
        const hasBreakfast =
          response.data.hotel.rates[0].boardBasis.type === "BedAndBreakfast"
            ? "breakfast"
            : "without";

        recomendationData[key][hasBreakfast].push({
          id: roomRecomandations[i].id,
          price: response.data.hotel.rates[0].totalRate,
          data: response.data, //TODO :: need to remove
          rates: response.data.hotel.rates,
          rooms: response.data.hotel.rooms,
        });
      }
    }

    recomendationData.refundable.breakfast =
      recomendationData.refundable.breakfast.hasMin("price");
    recomendationData.refundable.without =
      recomendationData.refundable.without.hasMin("price");
    recomendationData.nonRefundable.breakfast =
      recomendationData.nonRefundable.breakfast.hasMin("price");
    recomendationData.nonRefundable.without =
      recomendationData.nonRefundable.without.hasMin("price");

    const finalData = [];
    Object.values(recomendationData).forEach((data) => {
      if (data.breakfast) {
        finalData.push(data.breakfast);
      }
      if (data.without) {
        finalData.push(data.without);
      }
    });

    return finalData.sort((a, b) => a.price - b.price);
  };

  const loadAllRecomend = async () => {
    const roomData = props.roomList.roomDetails;

    // TODO :: NEED TO LOAD ALL RECOMENDED ID TO GROUP
    const roomRecomandations = props.roomList.recomendation;

    const responseRecomendation = await processRecomendation(
      roomRecomandations,
      roomData
    );

    setLoading(false);
    setRecomendationData(responseRecomendation);
  };

  const roomData = props.roomList.roomDetails;
  const roomRecomandation = props.roomList.recomendation;
  const rates = props.roomList.rates;
  const room = props.roomList.room;
  const imagesArray =
    roomData.images?.map((image) => {
      return {
        original:
          image.links[1] && image.links[1].url
            ? image.links[1].url
            : image.links[0].url,
        thumbnail:
          image.links[1] && image.links[1].url
            ? image.links[1].url
            : image.links[0].url,
        description: image.caption,
      };
    }) ?? [];

  if (loading) {
    return <></>
  }

  if (roomsDisplayed <= 0) return <div style={{ display: "none" }} />;
  return (
    <React.Fragment>
      <Modal isOpen={modal} className="smallImagesModal">
        <div className="smallImagesClose">
          <Icon
            type="close-circle"
            style={{ fontSize: "3rem", marginBottom: "1rem" }}
            onClick={close}
          />
        </div>
        <ImageGallery
          items={imagesArray}
          showFullscreenButton={false}
          showPlayButton={false}
          startIndex={galleryStart}
        />
      </Modal>

      <div
        className="combinationCostscontainer shadow"
        style={{
          border: "1px solid #e9edef",
          borderRadius: "10px",
        }}
      >
        <div className="combinationImageName">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="combinationCostscontainerroomName">
              {roomData.name}
            </div>

            {imagesArray.length > 0 && (
              <div className="RoomsImageContainer">
                {imagesArray.map((element, index) => (
                  <div
                    key={index}
                    className="RoomsImage"
                    style={{
                      backgroundImage: `url( ${element.thumbnail} )`,
                      cursor: "pointer",
                    }}
                    onClick={() => open(index)}
                  />
                ))}
              </div>
            )}

            <MediaQuery maxWidth={775}>
              {roomData.hasOwnProperty("facilities") &&
                roomData.facilities.length > 0 && (
                  <Facilities number={4} facilities={roomData.facilities} />
                )}
            </MediaQuery>
            <MediaQuery minWidth={776}>
              {roomData.hasOwnProperty("facilities") &&
                roomData.facilities.length > 0 && (
                  <Facilities number={12} facilities={roomData.facilities} />
                )}
            </MediaQuery>

            <div className="roomsPrices">
              {roomRecomandation.length > 0 &&
                recomendationData.map((element, index) => (
                  <RoomPrice
                    hideElem={props.hide}
                    intialData={{
                      rates: rates[index],
                      room: room[index],
                    }}
                    location={props.location}
                    currency={props.roomList.currency}
                    hotelId={props.hotelId}
                    token={props.token}
                    config={props.config}
                    recommendationId={element?.id}
                    handleSelection={props.handleSelection}
                    selectedtoken={props.selectedtoken}
                    setPaymentData={props.setPaymentData}
                    roomFailedToLoad={roomFailedToLoad}
                    recommendationData={element}
                    key={element?.id}
                    openPolicyModal={props.openPolicyModal}
                    setModal={props.setModal}
                    isAllowedToBookIndosatB2B={isAllowedToBookIndosatB2B}
                    hotelInfo={props.hotelInfo}
                    carryForward={props.carryForward}
                    childrenInfoString={props.childrenInfoString}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RoomPriceContainer;
