import { message } from 'antd';

import { useRecoilState } from "recoil";
import { loginModalVisibleState } from "recoil/atoms"

import AxiosRequestHelper from 'helpers/AxiosRequestHelper'

const useForgotPasswordFormLogic = (props) => {
    const {
        form,
    } = props

    const { getFieldDecorator } = form;
    const [isOpenModal, setIsOpenModal] = useRecoilState(loginModalVisibleState);

    const closeModal = () => {
        setIsOpenModal(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    // Make the POST request
                    let response = await AxiosRequestHelper("post", "/auth/password/send-link", values);
                    // let response = await Axios.post("/auth/password/send-link", values);

                    // Handle success or error based on response
                    if (response.data.success) {
                        message.success(`Successfully sent an email to ${values.email}. Please check your inbox.`);
                        closeModal();
                    } else {
                        // Display the error message from response
                        message.error(response.data.msg || 'An error occurred');
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.message) {
                        message.error(error.response.data.message);
                    } else {
                        message.error(error?.message);
                    }
                }
            } else {
                console.log(err);
            }
        });
    };

    return {
        handleSubmit,
        getFieldDecorator,
    }
}

export default useForgotPasswordFormLogic