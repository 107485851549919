import React, { useEffect, useMemo, useState } from 'react';
import Axios from 'axios'
import { useLocation } from "react-router-dom";
import Header from "components/Header/Header";
import { getStorage } from "helpers/LocalStorageHelpers";
import { _gtag, formatDate, getIsTestAcc } from "helpers/GlobalHelper"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './BookingInfo.css';
import { Form, Input, Button, Select } from "antd";
import PhoneInput from "react-phone-input-2";
import { useMediaQuery } from 'react-responsive';
import { SingleBooking } from '../SingleBooking/SingleBooking';

import Loader from 'components/Global/Loader/Loader';

const BookingInfo = (data) => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [loading, setLoading] = useState(true);
  const [bookingLoading, setBookingLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const [error, setError] = useState(false);
  const [contactFieldText, setContactFieldText] = useState("Contact No.");

  const isTestAcc = getIsTestAcc();

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);


  const getBookingStatus = async () => {
    let config = {
      method: "post",
      url: "/payment/get-booking-info",
      data: {
        id: query.get('refId'),
        customerIp: getStorage('_customer_ip'),
        isTest: isTestAcc ?? false
      },
    };

    await Axios(config)
      .then(async (res) => {
        if (res.data.success == true) {
          const transaction = res.data.object.transaction;
          const response = res.data.object.hotelBooking
          // const provider = res.data.object.providerSubscribe

          const hotelInfo = response.hotel;
          const rates = response.rates[0];

          const roomId = [];
          const roomDetails = [];
          let guestCount = 0
          rates.occupancies.forEach(rate => {
            guestCount += parseInt(rate.numOfAdults)
            roomId.push(rate.roomId)
          })

          response.rooms.forEach((room) => {
            if (roomId.includes(room.id) !== false) {
              roomDetails.push(room);
            }
          })

          const info = {
            name: hotelInfo.name,
            location: `${hotelInfo.contact.address.line1}, ${hotelInfo.contact.address.city.name},${hotelInfo.contact.address.country.name}`,
            stars: parseInt(hotelInfo.starRating),
            imageurl: hotelInfo.heroImage,
            details: hotelInfo.descriptions[0]?.text ?? '-',
            checkindate: formatDate(response.tripStartDate),
            checkoutdate: formatDate(response.tripEndDate),
            bookingid: response.providerConfirmationNumber ?? response.bookingId,
            payment_status: transaction.status,
            referenceid: `${transaction.id}`,
            vd_booking_id: `${response.bookingId}`,
            contactdetails: response.email,
            guestname: response.guestNames,
            guest_details_per_room: transaction.guest_details_per_room,
            roomdetails: roomDetails,
            paymentdetails: `${transaction.currency} ${transaction.total_rate}`,
            RoomCount: transaction.room_count,
            Guestcount: guestCount,
            status: response.bookingStatus,
            showDownload: true,
            // showProviderForm: provider ? false : true
          };

          _gtag('booking_success');

          setPageData(info)
          if (transaction.booking_id) setBookingLoading(false)
        } else {
          setError(true)
          // const transaction = res.data.object.transaction;
          // const hotelInfo = res.data.object.hotelInfo

          // const bookingData = JSON.parse(transaction.booking_data);
          // const guestDetail = JSON.parse(transaction.guest_details);

          // const roomCount = bookingData.roomsAllocations.length
          // const guest = bookingData.roomsAllocations[0].guests[0]
          // const guestCount = guestDetail.noofAdults

          // const info = {
          //   name: hotelInfo.name,
          //   location: `${hotelInfo.contact.address.line1}, ${hotelInfo.contact.address.city.name},${hotelInfo.contact.address.country.name}`,
          //   stars: parseInt(hotelInfo.starRating),
          //   imageurl: hotelInfo.heroImage,
          //   details: hotelInfo.descriptions[0]?.text ?? '-',
          //   checkindate: formatDate(transaction.checkin_date),
          //   checkoutdate: formatDate(transaction.checkout_date),
          //   bookingid: "-",
          //   payment_status: transaction.status === "INPROGRESS" ? "INPROGRESS" : "UNKNOWN",
          //   referenceid: `${transaction.id}`,
          //   vd_booking_id: 'FAILED',
          //   contactdetails: guest.email,
          //   guestname: guest.firstName + " " + guest.lastName,
          //   roomdetails: [],
          //   paymentdetails: `${transaction.currency} ${transaction.total_rate}`,
          //   RoomCount: roomCount,
          //   Guestcount: guestCount,
          //   status: "FAILED",
          //   showDownload: false,
          //   showProviderForm: false,
          // };

          _gtag('booking_failed')

          // setPageData(info)
          setBookingLoading(false)

        }



      }).finally(() => setLoading(false))

  }


  const storeSubscriber = (values) => {
    return Axios({
      method: "post",
      url: "/provider/subscribe",
      data: {
        t_id: pageData.referenceid,
        provider_type: values.provider,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        contact_no: values.contact_number,
      },
    });
  }

  const onProviderChange = (val) => {
    const text = {
      'yoodo': "Yoodo Mobile Number to receive free roaming passes"
    }

    if (text[val]) {
      setContactFieldText(text[val])
    }

  }
  const ProviderForm = (props) => {
    const { getFieldDecorator, validateFields } = props.form;
    const submit = async (props) => {
      await validateFields(async (err, values) => {
        if (!err) {
          const resp = await storeSubscriber(values)
          if (resp) {
            alert('Information store successfully. Thank you booking with us.')
            closeModal()
          }
        }
      });
    }

    return (
      <div>
        <Popup className={'ProviderForm'} lockScroll open={open} closeOnDocumentClick={false} onClose={closeModal}
          contentStyle={{
            borderRadius: '10px',
            width: 'auto',
            minWidth: "400px",
            padding: '20px'
          }}>
          <div className="">
            <a className="close" onClick={closeModal}>
              &times;
            </a>
            <h2 style={{ textAlign: 'center' }}>Roaming Confirmation Form</h2>
            <p>To enjoy the free roaming passes from Yoodo for this booking,<br />
              please fill in the form with correct details :</p>
            <Form
              style={{
                marginRight: 'auto', marginLeft: 'auto'
              }}
              onSubmit={submit}
              className="provider-form"
              hideRequiredMark={true}
              colon={false}
            >
              <Form.Item label={<span>Provider</span>}>
                {getFieldDecorator('provider', {
                  rules: [
                    {
                      required: true,
                      message: "Please select your provider!",
                    },
                  ]
                })(
                  <Select
                    placeholder="Select your provider"
                    allowClear={false}
                    onSelect={onProviderChange}
                  >
                    <Select.Option value="yoodo">Yoodo</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label={<span>First Name</span>}>
                {getFieldDecorator("first_name", {
                  initialValue: pageData.guestname,
                  rules: [
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label={<span>Last Name</span>}>
                {getFieldDecorator("last_name", {
                  initialValue: 'asdsads',
                  rules: [
                    {
                      required: true,
                      message: "Please input your last name!",
                    }
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label={<span>Email</span>}>
                {getFieldDecorator("email", {
                  initialValue: pageData.contactdetails,
                  rules: [
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "string",
                      message: "Please input a valid email",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label={<span>{contactFieldText} <i>(Include contry code)</i></span>}>
                {getFieldDecorator("contact_number", {
                  initialValue: '',
                  rules: [
                    { required: true, message: "Please input your phone number!" },
                  ],
                })(<PhoneInput placeholder="" inputClass="signUpPhoneInput" />)}
              </Form.Item>
              <Button type="primary" style={{ margin: "1rem 0", width: '100%' }} onClick={submit}>Submit</Button>
            </Form>
          </div>
        </Popup>
      </div>);
  }
  const PopupFormContex = Form.create({ name: 'ProviderForm' })(ProviderForm);

  useEffect(() => {
    getBookingStatus();
  }, [])

  const isMobile = useMediaQuery({
    maxWidth: 775
  })

  return (
    <div>
      <Header isMobile={isMobile} isDarkFont={true} />
      {loading && <Loader text={"Getting Booking Information..."} />}
      {(!loading && pageData) &&
        <div style={{ marginTop: 20 }}>
          <div className="booking">
            {pageData.showProviderForm && <PopupFormContex />}
            <SingleBooking bookingLoading={bookingLoading} data={pageData} />
          </div>
        </div>
      }

      {(!loading && error) &&
        <div style={{ marginTop: 20 }}>
          <div className="booking">
            {/* <h2>Booking failed</h2> */}
          </div>
        </div>
      }
    </div>
  )
}

export default BookingInfo;
