// recoil/atoms.js
// import { atom } from 'recoil';

import { atom } from "recoil";

export const confirmModalVisibleState = atom({
    key: 'confirmModalVisibleState',
    default: false,
});

export const temporaryUserState = atom({
    key: 'temporaryUserState',
    default: {
        fullName: null,
    },
});

export const loginModalVisibleState = atom({
    key: 'loginModalVisibleState',
    default: false,
});

export const forgotPasswordModalVisibleState = atom({
    key: 'forgotPasswordModalVisibleState',
    default: false,
});

export const signUpModalVisibleState = atom({
    key: 'signUpModalVisibleState',
    default: false,
});

// B2B INDOSAT
export const b2bIndosatInviteTeamMemberModalVisibleState = atom({
    key: 'b2bIndosatInviteTeamMemberModalVisibleState',
    default: false,
});
export const b2bIndosatRequiredCompanyProfileModalVisibleState = atom({
    key: 'b2bIndosatRequiredCompanyProfileModalVisibleState',
    default: false,
});
