import React from "react";
import "./HotelDescription.css";

export const HotelDescription = (props) => {
  const { descriptions } = props;
  const descriptionWithoutAttractions = descriptions?.reduce(
    (result, description) => {
      if (description.type !== "attractions") {
        result.push(
          <div
            key={description.type}
            id={description.type}
            style={{ marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{ __html: description.text }}
          />
        );
      }
      return result;
    },
    []
  );

  return <div>{descriptionWithoutAttractions}</div>;
};
