import React, { useEffect } from "react";
import { Dropdown, Menu } from "antd";
import { Link, Redirect } from "react-router-dom";
import "./Header.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loginModalVisibleState, signUpModalVisibleState, temporaryUserState } from "recoil/atoms"
import SignUpModalV2 from "components/Auth/SignUpModalV2/SignUpModalV2";
import LoginModalV2 from "components/Auth/LoginModalV2/LoginModalV2";

const StatelessHeader = (props) => {
  const {
    user,
    setuser,
    providerConfig = {},
    logoColor = null
  } = props;

  // Popups
  const setIsOpenLoginModal = useSetRecoilState(loginModalVisibleState);
  const setIsOpenSignUpModal = useSetRecoilState(signUpModalVisibleState);

  const signIn = (
    <div className="buttonContainer">
      <div
        className="button"
        onClick={() => setIsOpenSignUpModal(true)}
        style={{ color: props.signupColor ? props.signupColor : "black" }}
      >
        Sign Up
      </div>
      <div className="button" style={{ color: "#d93b4f" }} onClick={() => setIsOpenLoginModal(true)}>
        Log In
      </div>
    </div>
  );

  const logout = (event) => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userdata");
    localStorage.removeItem("useremail");
    localStorage.removeItem("is-test-acc");

    setuser(false);
    return <Redirect to="/" key={"random"} />;
  };

  const temporaryUser = useRecoilValue(temporaryUserState)
  const userData = JSON.parse(localStorage.getItem("userdata"));

  useEffect(() => {
    if (temporaryUser.fullName) {
      setuser(temporaryUser.fullName);
    } else if (localStorage.usertoken && localStorage.userdata) {

      const data = JSON.parse(localStorage.getItem("userdata"));

      setuser(data.first_name + " " + data.last_name);
    }
  }, [temporaryUser]);

  const menu = (
    <Menu>
      {userData?.isPIC && (
        <Menu.Item>
          <Link to="/business-dashboard">Business Dashboard</Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to="/Myaccount">My account</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/" onClick={logout}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const welcome = (
    <div>
      <Dropdown overlay={menu} placement="bottomLeft" arrow>
        <div style={{ cursor: "pointer" }}>
          Welcome, <b>{user}</b>
        </div>
      </Dropdown>
    </div>
  );

  return (
    <div className="Header" style={props.containerStyle}>
      <SignUpModalV2 />
      <LoginModalV2 />

      <div className="innercontainer">
        <div style={{ marginRight: "auto" }}>
          <Link to={providerConfig.status ? `/${providerConfig.name}` : '/'}>
            {providerConfig.status ? <div className={`logo-${providerConfig.name} ${logoColor ? logoColor : ''}`} /> : <div className="logo" />}
          </Link>
        </div>
        {user ? welcome : signIn}
      </div>
    </div>
  );
};

export default StatelessHeader;
