import React from "react";
import { Icon } from "antd";
import { Form, Input, Button } from "antd";
import "./Profile.css";
import PhoneInput from "react-phone-input-2";
import useProfileLogic from './Profile.logic'

const Profile = () => {
  const {
    first_name,
    changeFirstName,
    last_name,
    changeLastName,
    contactNumber,
    changeNumber,
    email,
    changeEmail,
    updateUser,
    changepasswordvisible,
    setChangepasswordvisible,
    currentpass,
    currentpassword,
    newpassword,
    newpass,
    handleOk,
  } = useProfileLogic()

  return (
    <div className="profileouterContainer">
      <div style={{ fontSize: "2rem", marginBottom: "3rem" }}>Profile</div>
      <div className="profileContainer">
        <div>
          <Form className="columnContainer">
            <div className="inputContainer">
              <div className="inputDescription">First Name</div>
              <Form.Item style={{ margin: 0 }}>
                <Input
                  style={{ border: "none", width: "100%" }}
                  value={first_name}
                  onChange={changeFirstName}
                />
              </Form.Item>
            </div>

            <div className="inputContainer">
              <div className="inputDescription">Last Name</div>
              <Form.Item style={{ margin: 0 }}>
                <Input
                  style={{ border: "none", width: "100%" }}
                  value={last_name}
                  onChange={changeLastName}
                />
              </Form.Item>
            </div>

            <div className="inputContainer">
              <div className="inputDescription">Phone</div>
              <Form.Item style={{ margin: 0 }}>
                <PhoneInput
                  onChange={changeNumber}
                  inputClass="profiletelephoneinput"
                  containerClass="profiletelephoneinput"
                  placeholder=""
                  value={contactNumber}
                />
              </Form.Item>
            </div>

            <div className="inputContainer">
              <div className="inputDescription">Email</div>
              <Form.Item style={{ margin: 0 }}>
                <Input
                  style={{ border: "none", width: "100%" }}
                  value={email}
                  onChange={changeEmail}
                />
              </Form.Item>
            </div>

            <Form.Item style={{ margin: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={updateUser}
                style={{ marginRight: "auto" }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div>
          <Form className="columnContainer">
            <Button
              type="link"
              onClick={() => setChangepasswordvisible(!changepasswordvisible)}
              style={{ padding: "0", marginBottom: "2rem", color: "#f48148" }}
            >
              Change Password{" "}
              {changepasswordvisible ? <Icon type="up" /> : <Icon type="down" />}
            </Button>
            {changepasswordvisible && (
              <div>
                <div className="inputContainer">
                  <div className="inputDescription">Current Password</div>
                  <Form.Item style={{ margin: 0, border: 'none' }}>
                    <Input.Password
                      className="borderlessInput"
                      value={currentpassword}
                      onChange={currentpass}
                      type="password"
                    />
                  </Form.Item>
                </div>

                <div className="inputContainer">
                  <div className="inputDescription">New Password</div>
                  <Form.Item style={{ margin: 0 }}>
                    <Input.Password
                      className="borderlessInput"
                      value={newpassword}
                      onChange={newpass}
                      type="password"
                    />
                  </Form.Item>
                </div>

                <Button type="primary" onClick={handleOk}>
                  Change password
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Profile;