import React from 'react'
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaInstagram, FaLinkedinIn, } from "react-icons/fa";
import FooterLogo from "images/footer_logo.svg"
import FooterPayment from "images/Home/logo-full-final.svg"
import { useTranslation } from 'react-i18next';

export const FooterDesktop = ({ showEsim = true, ...props }) => {
  const date = new Date();

  const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }

  const { t } = useTranslation()

  return (
    <div className="homefooter">
      <div className="LPFooter">
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            columnGap: '80px',
          }}>
            <div className="FooterColumn">
              <span>
                <Link className='footer-links' to="/aboutus">About Us</Link>
              </span>
              <span>
                <Link className='footer-links' to="/ContactUs">Contact Us</Link>
              </span>
              <span>
                <Link className='footer-links' to="/faq">Room FAQ</Link>
              </span>
            </div>

            <div className="FooterColumn">
              <span>
                <Link className='footer-links' to="/termsandconditions">Terms & Conditions</Link>
              </span>
              <span>
                <Link className='footer-links' to="/privacypolicy">Privacy Policy</Link>
              </span>
            </div>

            <div className="FooterColumn" style={{ marginLeft: '-50px' }}>

              <div className="footersocial">
                <span style={{ fontSize: "1.1rem" }}>{t('follow_us')}</span>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <a target="_blank" href="https://www.facebook.com/visitorsdeals">
                    <FaFacebookSquare />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/visitors_deals">
                    <FaInstagram />
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/visitorsdeals">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/visitorsdeals-travel/">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>

            </div>
          </div>

          <div style={{
            display: ['indosat', 'business', 'business-dashboard'].includes(pConfig?.name) ? 'block' : 'none'
          }}>
            <h2>{t('payment_partners')}</h2>

            <img src={FooterPayment} style={{
              width: '100%',
              maxWidth: '30vw',
              height: 'auto',
            }} />
          </div>

        </div>

        <img src={FooterLogo} alt="" style={{
          width: '100%'
        }} />
      </div>

      <div className="LowerFooter">
        <span style={{ margin: '1rem 1rem' }}>
          Copyright © 2020 - {date.getFullYear()}. Visitors Deals. All Rights Reserved ({process.env.REACT_APP_VERSION}) - Owned by Hotstores Solutions Sdn Bhd
        </span>
      </div>
    </div>
  )
}
