import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

export const useFAQGeneralLogic = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 775 });

    const handleBack = () => history.goBack();

    const TranslatedFAQ = [
        { code: "one", qna: ["one", "two"] },
        { code: "two", qna: ["one", "two", "three", "four", "five"] },
        { code: "three", qna: ["one", "two", "three", "four", "five", "six", "seven", "eight"] },
        { code: "four", qna: ["one"] },
    ];

    return { handleBack, isMobile, t, TranslatedFAQ };
};