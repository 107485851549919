import { Modal, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

const SaveRoomModal = ({ isOpen, setIsOpen, roomType, setRoomType, submitAction, removeBooking, currentData }) => {
    let guestDetails;
    if (currentData && currentData?.guest_details) guestDetails = JSON.parse(currentData?.guest_details)
    return (
        <Modal
            visible={isOpen}
            forceRender={true}
            ariaHideApp={false}
            footer={null} // Hides the default footer with OK and Cancel buttons
            onCancel={() => setIsOpen(false)} // This handles the close button
        >
            <label>
                Please write your room preference (Room availability may change but PIC will try to book the room according to the given preference)
            </label>
            <TextArea
                value={roomType}
                onChange={e => setRoomType(e.target.value)}
                rows={4}
            />

            <div style={{ marginTop: '1rem', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', columnGap: '10px' }}>
                {currentData && (
                    <Button type="link" onClick={() => removeBooking({
                        companyId: currentData?.companyId,
                        userId: currentData?.User?.id,
                        hotel_id: currentData?.hotel_id,
                        place_id: currentData?.place_id,
                    })}>
                        Remove
                    </Button>
                )}

                <Button type='primary' onClick={submitAction} disabled={!roomType}>
                    {currentData ? 'Edit' : 'Save'}
                </Button>
            </div>

            {
                currentData && (
                    <div>
                        <hr />
                        <h2>Current saved booking related to this hotel</h2>
                        {/* <h3>User Info</h3>
                        <hr />
                        <p><strong>User name:</strong> {`${currentData?.User?.firstName} ${currentData?.User?.lastName}`}</p>
                        <p><strong>User email:</strong> {currentData?.User?.email}</p>

                        <h3>Booking Detail</h3> */}
                        <hr />
                        <p><strong>Destination:</strong> {currentData?.destination}</p>
                        <p><strong>Hotel Name:</strong> {currentData?.hotel_name}</p>
                        <p><strong>Check-in Date:</strong> {new Date(currentData?.checkin_date).toLocaleDateString()}</p>
                        <p><strong>Check-out Date:</strong> {new Date(currentData?.checkout_date).toLocaleDateString()}</p>
                        <p><strong>Room Count:</strong> {currentData?.room_count}</p>
                        <p><strong>Room Type:</strong> {currentData?.notes}</p>
                        <p><strong>Booking saved at:</strong> {new Date(currentData?.savedAt).toLocaleDateString()}</p>
                        <p><strong>Status:</strong> {currentData?.status}</p>

                        {/* <h3>Guest Details</h3>
                        <hr />
                        <p><strong>Number of Adults:</strong> {guestDetails.noofAdults}</p>
                        <p><strong>Number of Children:</strong> {guestDetails.noofChild}</p> */}
                    </div>
                )
            }


        </Modal>
    );
};

export default SaveRoomModal;
