import React from "react";
import { Button, } from "antd";
import Header from "components/Header/Header";

import useResetNewPasswordStyles from './ResetNewPassword.styles'
import useResetNewPasswordLogic from "./ResetNewPassword.logic";

import Loader from "components/Global/Loader/Loader";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import LoginModalV2 from "components/Auth/LoginModalV2/LoginModalV2";
import ForgotPasswordModalV2 from "components/Auth/ForgotPasswordModalV2/ForgotPasswordModalV2";
import ResetNewPasswordForm from './ResetNewPasswordForm'

const ResetNewPassword = () => {
    const { styles } = useResetNewPasswordStyles()

    const {
        isLoading,
        isPasswordHasBeenChanged,
        isKeyInvalid,
        onClickLogin,
        onClickResetPassword,
        email
    } = useResetNewPasswordLogic()

    const successfullyVerified = () => (
        <div style={styles.form}>
            <FaCheckCircle size={75} style={styles.successIcon} />
            <h1>Password has successfully been updated!</h1>

            <p>If you think you have not updated the password yet, please check your email inbox and make sure to click the most recent link received by your email.</p>

            <Button
                type='primary'
                onClick={onClickLogin}
            >
                Login to your account
            </Button>
        </div>
    )

    const resetPasswordForm = () => isPasswordHasBeenChanged ? successfullyVerified() : (
        <div style={styles.form}>
            <h1>Reset Account Password</h1>
            <p>Enter a new password for {email}</p>
            {/* <Input
                style={styles.input}
                placeholder='New Password'
            />
            <Input
                style={styles.input}
                placeholder='New Password Confirmation'
            />

            <Button
                type='primary'
                loading={isLoadingSubmit}
                onClick={onClickSubmit}
            >
                {getButtonActionTitle()}
            </Button> */}

            <ResetNewPasswordForm />
        </div>
    )

    const invalidKey = () => (
        <div style={styles.form}>
            <FaTimesCircle size={75} style={styles.errorIcon} />
            <h1>Failed to verify the link</h1>

            <p>Please check your email inbox and make sure to click the most recent link received by your email or click on the button below to resend a new reset password link.</p>

            <Button
                type='primary'
                onClick={onClickResetPassword}
            >
                Resend reset password link
            </Button>
        </div>
    )

    const verificationResult = () => isKeyInvalid ? invalidKey() : resetPasswordForm()
    // const verificationResult = () => isPasswordHasBeenChanged ? successfullyVerified() : resetPasswordForm()

    const loadingVerification = () => (
        <Loader text={'Verifying'} />
    )

    return (
        <div>
            <Header isDarkFont={true} />

            {isLoading ? loadingVerification() : verificationResult()}

            <LoginModalV2 />
            <ForgotPasswordModalV2 />

        </div>
    );
};

export default ResetNewPassword;