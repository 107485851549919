import { Button, message, Pagination, Radio } from "antd";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import "./Booking.css";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { formatDate } from "helpers/GlobalHelper"
import SavedBookingDetailModal from "../../components/Provider/Business/SavedBookingDetailModal";
import CheckIndosatTeamMemberRolesHelper from "../../helpers/CheckIndosatTeamMemberRolesHelper";
import { SingleBooking } from "./SingleBooking/SingleBooking";

const BookingCard = ({ data, index, handleCustomerCare, handleClick }) => {
  let {
    name,
    checkindata,
    checkoutdata,
    imageurl, // not present in db
    paymentStatus, // not present in db
  } = data;

  const paymentStatusColor = {
    "PAID": 'green',
    "UNPAID": "red",
    "PENDING": "yellow",
  }

  return (
    <div
      className="bookingCard"
      onClick={() => {
        window.scrollTo(0, 0);
        handleClick(index);
      }}
    >
      <div className="bookingCardInfo">
        <div className="subheading">{name}</div>

        <div className="checkindata">
          <div className="flexrow">
            <div className="faded">Check in: </div>
            <div>{formatDate(checkindata)}</div>
          </div>
          <div className="flexrow">
            <div className="faded">Check out: </div>
            <div>{formatDate(checkoutdata)}</div>
          </div>
          <div className="flexrow" style={{ marginTop: "20px" }}>
            <div className="faded">Status: </div>
            <div style={{ color: paymentStatusColor[paymentStatus] }}>{paymentStatus}</div>
          </div>
        </div>

        {/*<div className="faded">{description}</div>*/}
        {/*<div>*/}
        {/*  <Link*/}
        {/*    to="#"*/}
        {/*    style={{ color: "var(--vdOrange)" }}*/}
        {/*    onClick={(event) => handleCustomerCare(event)}*/}
        {/*  >*/}
        {/*    Chat with customer support*/}
        {/*  </Link>*/}
        {/*</div>*/}
      </div>

      <div>
        <div
          className="bookingCardImage"
          style={{ backgroundImage: `url( ${imageurl} )` }}
        />
      </div>
    </div>
  );
};

export const AllBookings = ({ navigation }) => {
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const isAllowedToBookIndosatB2B = CheckIndosatTeamMemberRolesHelper(1002)
  // const [isAllowedToBookIndosatB2B, setIsAllowedToBookIndosatB2B] = useState(CheckIndosatTeamMemberRolesHelper(1002));
  const [data, setdata] = useState([]);
  const [page, setpage] = useState(1);
  const [perpagecontent, setperpagecontent] = useState(8);
  const [hotelselected, sethotelselected] = useState(-1); // -1 == not selected
  const [selectedFilter, setSelectedFilter] = useState(companyData && !isAllowedToBookIndosatB2B ? 'BUSINESS_SAVED_BOOKINGS' : 'PERSONAL_BOOKINGS');
  const [isOpenSavedBookingModal, setIsOpenSavedBookingModal] = useState(false);
  const [currentSavedBookingDetailData, setCurrentSavedBookingDetailData] = useState(null);
  const history = useHistory();

  const getData = async () => {
    const email = localStorage.getItem("useremail");

    let config = {
      method: "post",
      url: "/users/transactions",
      data: {
        email: email,
      },
      params: {
        // status: "PAID" 
        type: selectedFilter,
      }
    };

    const transactionList = [];
    const res = await Axios(config)
    if (res.data.success) {
      res.data.object.map((trx) => {
        transactionList.push({
          ...trx,
          name: trx.hotel_name,
          checkindata: trx.checkin_date,
          checkoutdata: trx.checkout_date,
          imageurl: trx.hotel_image,
          paymentStatus: trx.status,
          bookingId: trx.id,
        })
      })

      setdata(transactionList);
    }
  }

  useEffect(() => {
    getData()
  }, [selectedFilter]);

  const handleCustomerCare = (event) => {
    event.stopPropagation();
    console.info("Hello Customer service here!");
    // TODO
  };

  const handleCardClick = async (cardIndex) => {
    console.info(document);
    sethotelselected(cardIndex);
    document.scrollingElement.scrollTop = 0;
  };

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    setSelectedFilter(filter);
  };

  useEffect(() => {
    if (currentSavedBookingDetailData !== null) {
      setIsOpenSavedBookingModal(true)
    }
  }, [currentSavedBookingDetailData])

  const indexOfLastCard = page * perpagecontent;
  const indexOfFirstCard = indexOfLastCard - perpagecontent;
  let displayedData = data.slice(indexOfFirstCard, indexOfLastCard);

  const removeBooking = async ({
    companyId,
    userId,
    hotel_id,
    place_id,
  }) => {
    const config = {
      method: 'post',
      url: 'saved-booking-detail/remove',
      data: {
        companyId,
        userId,
        hotel_id,
        place_id,
      },
    };

    await Axios(config)
      .then((response) => {
        setIsOpenSavedBookingModal(false)
        message.success('Booking has been successfully removed.');
        getData()
      })
      .catch((err) => {
        console.error('Error removing booking:', err);
        message.error('Failed to remove booking.');
      })
      .finally(() => {
        // Perform any additional cleanup actions here if needed
      });
  };


  const allbookings = (
    <div>
      <div className="heading">Booking</div>
      <div className="bookingCardContainer">
        {displayedData.map((booking, index) => (
          <BookingCard
            data={booking}
            key={index}
            index={index}
            handleCustomerCare={handleCustomerCare}
            handleClick={() => {
              if (selectedFilter == 'PERSONAL_BOOKINGS') {
                history.push(`/booking?refId=${booking.bookingId}`)
              } else if (selectedFilter == 'BUSINESS_SAVED_BOOKINGS') {
                setCurrentSavedBookingDetailData(booking)
                setIsOpenSavedBookingModal(true)
              }
            }}
          />
        ))}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
      >
        <Pagination
          onChange={(newpage, size) => {
            setpage(newpage);
            window.scrollTo(0, 0);
          }}
          current={page}
          defaultPageSize={perpagecontent}
          total={data.length}
        />
      </div>
    </div>
  );

  return (
    <div className="booking">

      <SavedBookingDetailModal
        isOpen={isOpenSavedBookingModal}
        setIsOpen={setIsOpenSavedBookingModal}
        data={currentSavedBookingDetailData}
        appendRedirectButton={true}
        removeBooking={removeBooking}
      />

      {companyData && (
        <Radio.Group defaultValue={!isAllowedToBookIndosatB2B ? "BUSINESS_SAVED_BOOKINGS" : "PERSONAL_BOOKINGS"} buttonStyle="solid" style={{
          marginTop: '16px',
          marginBottom: '16px',
        }}
          onChange={handleFilterChange}
        >
          {isAllowedToBookIndosatB2B && (
            <Radio.Button value="PERSONAL_BOOKINGS">Personal Bookings</Radio.Button>
          )}
          <Radio.Button value="BUSINESS_SAVED_BOOKINGS">Business bookings</Radio.Button>
        </Radio.Group>
      )}

      {hotelselected === -1 ? (
        allbookings
      ) : (
        <>
          <Button
            type="link"
            style={{
              padding: "0",
              color: "var(--vdOrange)",
              marginBottom: "2rem",
            }}
            onClick={() => {
              sethotelselected(-1);
              document.body.scrollTop = 0;
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "max-content",
              }}
            >
              <IoIosArrowBack />
              All Bookings
            </div>
          </Button>

          <SingleBooking data={data[hotelselected]} />
        </>
      )}
    </div>
  );
};
