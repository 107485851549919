import React, { useState, useEffect } from "react";
import { HomeDesktop } from "./HomeDesktop";
import MediaQuery from "react-responsive";
import { HomeMobile } from "./HomeMobile";
import { _gtag } from "helpers/GlobalHelper"

export const Home = (props) => {

  localStorage.setItem(process.env.REACT_APP_ENV + '_VD_CORRE', null)

  _gtag(`landing_screen`);

  const [generalProps, setGeneralProps] = useState({
    history: props.history,
    hasProvider: false,
    provider: null
  });

  const path = props.history.location.pathname.toString().toLowerCase();
  useEffect(() => {
    switch (path) {
      case '/yoodo':
        setGeneralProps({
          ...generalProps,
          hasProvider: true,
          provider: 'yoodo'
        })
        localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
          hasProvider: true,
          provider: 'yoodo'
        }))
        break;
      case '/indosat':
        setGeneralProps({
          ...generalProps,
          hasProvider: true,
          provider: 'indosat'
        })
        localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
          hasProvider: true,
          provider: 'indosat'
        }))
        break;
      case '/business':
        setGeneralProps({
          ...generalProps,
          hasProvider: true,
          provider: 'business'
        })
        localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
          hasProvider: true,
          provider: 'business'
        }))
        break;
      case '/business-dashboard':
        setGeneralProps({
          ...generalProps,
          hasProvider: true,
          provider: 'business-dashboard'
        })
        localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
          hasProvider: true,
          provider: 'business-dashboard'
        }))
        break;
      default:
        setGeneralProps({
          ...generalProps,
          hasProvider: false,
          provider: null
        })
        localStorage.setItem(process.env.REACT_APP_ENV + '_VD_PROVIDER', JSON.stringify({
          hasProvider: false,
          provider: null
        }))
        break;
    }
  }, []);

  return (
    <div>
      {
        path == '/business-dashboard' || path == '/business' ? (
          <HomeDesktop {...generalProps} />
        ) : (<>
          <MediaQuery maxWidth={775}>
            <HomeMobile {...generalProps} />
          </MediaQuery>
          <MediaQuery minWidth={776}>
            <HomeDesktop {...generalProps} />
          </MediaQuery>
        </>)
      }
    </div>
  );
};
