import { useState } from "react";

const useFAQLogic = (defaultOpen = []) => {
    const [opened, setOpened] = useState(defaultOpen);

    const isOpen = (index) => opened.includes(index);

    const onToggle = (index) => {
        setOpened((prevOpened) =>
            prevOpened.includes(index)
                ? prevOpened.filter(item => item !== index)
                : [...prevOpened, index]
        );
    };

    return { isOpen, onToggle };
};

export default useFAQLogic;
