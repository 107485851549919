import { Button, Input, message, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Elements, useStripe, useElements, CardElement, CardNumberElement, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";

const DashboardContentPayments = ({ isMobile, ...props }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [addPaymentModalVisible, setAddPaymentModalVisible] = useState(false);
    const [cardValidation, setCardValidation] = useState(false);
    const [cardName, setCardName] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const companyData = JSON.parse(localStorage?.getItem("companyData"))

    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;

    const getData = async () => {
        setisLoading(true)

        let config = {
            method: "get",
            url: `/company-payment/payment-methods/${companyData.stripeCustomerId}`,
        };

        await Axios(config).then((res) => {
            setData(res?.data);
        }).catch((error) => {
            // setError('Please complete company info prior to adding company card')
        }).finally(() => {
            setisLoading(false)
        })
        // if (res.status === 200) {
        // } 
        // console.log(res.status)
        // else {
        //     setError('Please complete company info prior to adding company card')
        // }

    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const company = JSON.parse(localStorage?.getItem("companyData"))
        if (!company.stripeCustomerId) {
            message.error("Please save your company details prior to adding card info!");
            return
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            message.error(error.message);
        } else {
            const data = { paymentMethodId: paymentMethod.id, stripeCustomerId: company.stripeCustomerId, cardName }

            Axios.post(`/company-payment/attach-payment-method`, data)
                .then((res) => {
                    if (res.status == 200) {
                        message.success("Card info is saved");
                        getData()
                        setAddPaymentModalVisible(false)
                        setCardName("")
                    } else {
                        message.error("Fail to save card info");
                    }
                })
                .catch((e) => {
                    console.log(e);
                    message.error("An error occurred while saving the card info");
                });
        }
    };

    const handleRemovePaymentMethod = (paymentMethodId) => {
        Axios.delete(`/company-payment/payment-method/${paymentMethodId}`,)
            .then((res) => {
                if (res.status == 200) {
                    message.success("Card info is removed");
                    getData()
                } else {
                    message.error("Fail to remove card info");
                }
            })
            .catch((e) => {
                message.error("An error occurred while saving the card info");
            });
    }

    useEffect(() => {
        getData()
    }, [])

    // if (!isLoading) {
    return (
        <div style={{
            marginTop: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{
                backgroundColor: 'white',
                width: 'fit-content',
                minWidth: isDesktopScreen ? '750px' : 'auto',
                padding: '40px',
                maxHeight: isDesktopScreen ? '80vh' : '60vh',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}>
                    <h2 style={{ fontWeight: 'bold' }}>Payments details</h2>
                    <p>Add or remove payment methods to make it easier when you book</p>
                    {/* <Button type="primary" onClick={() => setAddPaymentModalVisible(true)} disabled={!companyData.stripeCustomerId}>
                        Add new card
                    </Button> */}
                </div>
                <hr />
                {!companyData.stripeCustomerId && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        rowGap: '10px'
                    }}>
                        <div style={{ textAlign: 'center' }}>{error}</div>
                        <Button type="primary" href="/business-dashboard?page=company-details">Click here to complete company info</Button>
                    </div>)
                }
                {
                    !isLoading && data.length == 0 && (
                        <div className="cardsList">
                            <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 36 36">
                                <path fill="#ffcc4d" d="M2.653 35C.811 35-.001 33.662.847 32.027L16.456 1.972c.849-1.635 2.238-1.635 3.087 0l15.609 30.056c.85 1.634.037 2.972-1.805 2.972z" />
                                <path fill="#231f20" d="M15.583 28.953a2.421 2.421 0 0 1 2.419-2.418a2.421 2.421 0 0 1 2.418 2.418a2.422 2.422 0 0 1-2.418 2.419a2.422 2.422 0 0 1-2.419-2.419m.186-18.293c0-1.302.961-2.108 2.232-2.108c1.241 0 2.233.837 2.233 2.108v11.938c0 1.271-.992 2.108-2.233 2.108c-1.271 0-2.232-.807-2.232-2.108z" />
                            </svg>
                            <h2>No cards added</h2>
                        </div>
                    )
                }

                {
                    isLoading ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                            <Spin />
                        </div>
                    ) :
                        (
                            <>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    flexDirection: isDesktopScreen ? 'row' : 'column',
                                    columnGap: isDesktopScreen ? '5rem' : '0rem',
                                    rowGap: isDesktopScreen ? '0rem' : '5rem',
                                }}>

                                    {!companyData.stripeCustomerId && (
                                        <h4 style={{
                                            whiteSpace: 'nowrap'
                                        }}>Payment cards</h4>
                                    )}

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        rowGap: '2rem',
                                        overflowY: 'scroll',
                                        width: '100%',
                                    }}>
                                        {
                                            data.map(d => (
                                                <div className="cardBox">
                                                    <div className="cardBoxContentWrapper">
                                                        <div className="cardBoxContent">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1em" viewBox="0 0 2304 1536">
                                                                <path fill="currentColor" d="M0 1376V768h2304v608q0 66-47 113t-113 47H160q-66 0-113-47T0 1376m640-224v128h384v-128zm-384 0v128h256v-128zM2144 0q66 0 113 47t47 113v224H0V160Q0 94 47 47T160 0z" />
                                                            </svg>
                                                            <h5 className="cardBoxNumber">&#8226;&#8226;&#8226;&#8226;<span style={{ marginLeft: '0.5rem' }}>{d.card.last4}</span></h5>
                                                            <h5 className="cardBoxExpiry">{d.card.exp_month}-{d.card.exp_year}</h5>
                                                        </div>

                                                        <h5 className="cardBoxTitle">{d.billing_details?.name}</h5>
                                                    </div>

                                                    <button className="cardBoxActionButton"
                                                        onClick={() => handleRemovePaymentMethod(d.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                <hr />

                                <button className="addNewCardButton" onClick={() => setAddPaymentModalVisible(true)} disabled={!companyData.stripeCustomerId}>
                                    &#x2B; Add a new card
                                </button>
                            </>

                        )
                }


                <Modal
                    title={null}
                    footer={null}
                    visible={addPaymentModalVisible}
                    onCancel={() => setAddPaymentModalVisible(false)}
                    className="inviteTeamModal"
                    width={"auto"}
                >
                    <div className="modalWrapper">
                        <h2>Payment Details</h2>
                        <p>Add payment method to make it easier when you book</p>
                        <hr />
                        <div className="inputContainer">
                            <div className="inputDescription">Cardholder's name</div>
                            <Input
                                style={{ border: "none", width: "100%" }}
                                onChange={e => setCardName(e.target.value)}
                            />
                        </div>

                        <CardElement onChange={(e) => {
                            console.log(e)
                            e.complete && !e.error ? setCardValidation(true) : setCardValidation(false)
                        }} />


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                            <Button loading={isLoading} onClick={handleSubmit} type="primary" htmlType="submit" disabled={!cardValidation || cardName == ''} style={{ float: 'right' }}>
                                Save card
                            </Button>
                        </div>

                    </div>

                </Modal>

            </div>
        </div>
    );
    // }
};

const isTestAcc = process.env.REACT_APP_ENV == 'staging' ? true : false;
// const isTestAcc = getIsTestAcc();
const stripePromise = isTestAcc ? loadStripe(`${process.env.REACT_APP_STRIPE_KEY_TEST}`) : loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
const StripeProvider = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <DashboardContentPayments {...props} />
        </Elements>
    );
};



export default StripeProvider;
