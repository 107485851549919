import { Button, Form, Input } from 'antd';
import React from 'react'
import useResetNewPasswordFormLogic from './ResetNewPasswordForm.logic';
import useResetNewPasswordStyles from './ResetNewPassword.styles'

const ResetNewPasswordFormComponent = (props) => {
    const { form } = props
    const { styles } = useResetNewPasswordStyles()

    const {
        isLoadingSubmit,
        onClickSubmit,
        getButtonActionTitle,
        isButtonDisabled
    } = useResetNewPasswordFormLogic({ form })

    return (
        <Form
            layout="vertical"
            onSubmit={onClickSubmit}
            style={styles.form}
        >

            <Form.Item>
                {form.getFieldDecorator("newPassword", {
                    rules: [
                        { required: true, message: 'Please enter your new password.' },
                        { min: 8, message: 'Password must be at least 8 characters.' }
                    ]
                }
                )(<Input.Password placeholder="New Password" style={styles.input} />)}
            </Form.Item>

            <Form.Item>
                {form.getFieldDecorator("confirmPassword", {
                    rules: [
                        { required: true, message: 'Please enter the confirm password.' },
                        {
                            validator: (rule, value, callback) => {
                                const newPassword = form.getFieldValue('newPassword');
                                // console.log(newPassword,value)
                                if (!value) {
                                    callback('Please confirm your new password.');
                                } else if (value !== newPassword) {
                                    callback('New Password and Password confirmation do not match.');
                                } else {
                                    callback();
                                }
                            }
                        }
                    ]
                }
                )(<Input.Password placeholder="Confirm New Password" style={styles.input} />)}
            </Form.Item>

            {/* <Form.Item
                name="confirmPassword"
                dependencies={['newPassword']}
                rules={[
                    { required: true, message: 'Please confirm your new password.' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords do not match.'));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Confirm Password" style={styles.input} />
            </Form.Item> */}

            {/* <Form.Item
                name="newPassword"
                rules={[
                    { required: true, message: 'Please enter your new password.' },
                    { min: 6, message: 'Password must be at least 6 characters.' }
                ]}
            >
                <Input.Password placeholder="New Password" style={styles.input} />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                dependencies={['newPassword']}
                rules={[
                    { required: true, message: 'Please confirm your new password.' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords do not match.'));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Confirm Password" style={styles.input} />
            </Form.Item> */}

            <Form.Item>
                <Button type="primary" onClick={onClickSubmit} loading={isLoadingSubmit}
                    disabled={isButtonDisabled()}
                >
                    {getButtonActionTitle()}
                </Button>
            </Form.Item>
        </Form>
    )
}

const ResetNewPasswordForm = Form.create({ name: "sign_up_form_v2" })(ResetNewPasswordFormComponent);

export default ResetNewPasswordForm;
