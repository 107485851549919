import React, { useState } from "react";
import des1 from "images/provider/yoodo/des1.jpg"
import des2 from "images/provider/yoodo/des2.jpg"
import des3 from "images/provider/yoodo/des3.jpg"
import des4 from "images/provider/yoodo/des4.jpg"
import des5 from "images/provider/yoodo/des5.jpg"

import tnc_img_icon from "images/provider/indosat/tnc-icon.png";

import voucherBg from "images/provider/yoodo/voucher-bg.png";

import { YoodoPopularDestinations } from "./YoodoPopularDestinations";
import { Carousel, Modal } from "antd";

const HomeContentYoodo = ({ isMobile, ...props }) => {
  const [showTncModal, setShowTncModal] = useState(false);
  const [showVoucherModal, setShowVoucherModal] = useState(false);

  return (
    <div className="IndosatPopularDestionationsContainer">
      {!isMobile && (
        <>
          <div className="first-row">
            <YoodoPopularDestinations
              place="Paris, France"
              currency={props.currency}
              placeId={437227}
              cost="9999"
              url={des1}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="largeImage"
            />
            <YoodoPopularDestinations
              place="Singapore"
              currency={props.currency}
              placeId={246673}
              cost="9999"
              url={des2}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="largeImage"
            />
          </div>
          <div className="second-row">
            <YoodoPopularDestinations
              place="Sydney, Australia"
              currency={props.currency}
              placeId={383923}
              cost="9999"
              url={des3}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="smallImage"
            />
            <YoodoPopularDestinations
              place="Tokyo, Japan"
              currency={props.currency}
              placeId={246750}
              cost="9999"
              url={des4}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="smallImage"
            />
            <YoodoPopularDestinations
              place="Bali, Indonesia"
              currency={props.currency}
              placeId={328057}
              cost="9999"
              url={des5}
              showLogin={() => props.setOpenLogin(true)}
              history={props.history}
              imageClass="smallImage"
            />
          </div>
        </>
      )
      }
      {isMobile &&
        (<Carousel className="indosat-img-carousel" autoplay={true} dotsClass="carouselContainerDots" dots={true}>
          <YoodoPopularDestinations
            place="Paris, France"
            currency={props.currency}
            placeId={437227}
            cost="9999"
            url={des1}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
          <YoodoPopularDestinations
            place="Singapore"
            currency={props.currency}
            placeId={246673}
            cost="9999"
            url={des2}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
          <YoodoPopularDestinations
            place="Sydney, Australia"
            currency={props.currency}
            placeId={383923}
            cost="9999"
            url={des3}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
          <YoodoPopularDestinations
            place="Tokyo, Japan"
            currency={props.currency}
            placeId={246750}
            cost="9999"
            url={des4}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
          <YoodoPopularDestinations
            place="Bali, Indonesia"
            currency={props.currency}
            placeId={328057}
            cost="9999"
            url={des5}
            showLogin={() => props.setOpenLogin(true)}
            history={props.history}
            imageClass="largeImage"
          />
        </Carousel>
        )
      }
      <div className="tnc-row">
        <div className="tnc-img-container" style={{ backgroundImage: `url( ${tnc_img_icon} )` }} />
        <div className="tnc-title-container">
          {isMobile ?
            (
              <div className="tnc-title">Terms & Conditions</div>
            ) :
            (
              <div className="tnc-title">
                Free roaming terms & conditions
              </div>
            )
          }
        </div>
        <div className="tnc-open-modal-btn">
          <button onClick={() => setShowTncModal(true)}>See Details</button>
        </div>
      </div>
      <div className="voodo-voucher-container">
        <img className="voodo-voucher-img" alt="Voodo Voucher offer" src={voucherBg} />
        <div className="voucher-overlay-text" onClick={() => setShowVoucherModal(true)}>See details</div>
        <Modal
          title="Terms & Conditions"
          visible={showTncModal}
          onOk={() => setShowTncModal(false)}
          onCancel={() => setShowTncModal(false)}
          footer={null}
          style={{ top: 10 }}
          className="tnc-modal"
        >
          <ul className="yoodo-tnc-modal-content">
            <li>Free Roam Like Home pass to Yoodo users who make accommodation bookings for worldwide destination hotels with VisitorsDeals.</li>
            <li>Only accommodation bookings made at least 14 days before the travel date are eligible for this deal.</li>
            <li>Free roaming passes based on the length of accommodation booking, i.e., the customer will get 5 days free roaming passes for accommodation booking of 5 days on the VisitorsDeals platform, in the denominations of 1-day, 3-day or 7-day pass</li>
            <li>
              To avail of the deal, visit <a href="https://www.visitorsdeals.com/yoodo" target="_blank" rel="noopener noreferrer">https://www.visitorsdeals.com/yoodo</a> and proceed with the following steps:
              <ul className="tnc-sub-list">
                <li>Sign up or log in to a registered VisitorsDeals account</li>
                <li>Search for the accommodation and travel dates</li>
                <li>Choose the preferred accommodation and room type</li>
                <li>Insert Yoodo mobile number and confirm the booking</li>
                <li>Receive Roam Like Home passes on the Yoodo app within 14 days prior to the travel date</li>
              </ul>
            </li>
            <li>A valid Yoodo mobile number is required to qualify for the deal. If you’re not a Yoodo user, sign up here at <a href="https://www.yoodo.com.my" target="_blank" rel="noopener noreferrer">www.yoodo.com.my</a></li>
            <li>An active Yoodo plan is required to receive the free Roam Like Home passes, and the customer should have a base plan quota (i.e., data, voice or SMS) to use the passes overseas</li>
            <li>The rewarded Roam Like Home passes must be activated within 60 days of receiving them on the Yoodo app.</li>
            <li>The Roam Like Home passes will be activated automatically when customers use data, voice or SMS overseas</li>
            <li>Only one pass will be automatically activated at one time.</li>
            <li>Further information on Roam Like Home can be found on the product FAQ page <a href="https://www.yoodo.com.my/roaming" target="_blank" rel="noopener noreferrer">https://www.yoodo.com.my/roaming</a>/</li>
            <li>The offer is subject to the terms and conditions of VisitorsDeals. In case of dispute, the decision of VisitorsDeals and Yoodo shall be final</li>
            <li>All conditions of the promotion must be met at the time the booking is made; no retroactive claims will be allowed</li>
            <li>VisitorsDeals reserves the right, in its sole discretion, to disqualify any reward from an eligible user who is found in violation of the terms and conditions or has undertaken fraudulent activities harmful to this campaign.</li>
            <li>For inquiries and concerns about the offer, you may contact our customer service or write to us at <a href="mailto:contactus@visitorsdeals.com">contactus@visitorsdeals.com</a></li>
            <li>The roaming confirmation form must be filled with the correct Yoodo’s mobile details after confirming the room booking</li>
          </ul>
        </Modal>
        <Modal
          title="E-Voucher Terms and conditions"
          visible={showVoucherModal}
          onOk={() => setShowVoucherModal(false)}
          onCancel={() => setShowVoucherModal(false)}
          footer={null}
          style={{ top: 10 }}
          className="tnc-modal"
        >
          <div style={{ fontSize: '24px' }}>Terms and Conditions</div>
          <ul className="yoodo-tnc-modal-content">
            <li>Book RM 200 and above to win Secret Recipe CASH VOUCHERS worth RM 30.</li>
            <li>The value of booking must be more than RM 200 in a single booking / receipt</li>
            <li>Combined bookings are not allowed for redemption, it needs to be in a single booking</li>
            <li>CASH VOUCHERS will be sent for redemption upon the room booking is used / after the check-out</li>
            <li>RM 10 e vouchers (3) in a form of a link will be sent to the subscribers for redemption</li>
            <li>1 Lucky winner will receive a RM 150 Secret Recipe CASH VOUCHER</li>
          </ul>

          <div style={{ fontSize: '24px' }}>RM 30 – e voucher T&C</div>
          <ul className="yoodo-tnc-modal-content">
            <li>Valid at all Secret Recipe outlets in Malaysia except AEON Mall Taiping</li>
            <li>Please check outlet validity for e-voucher redemption here: <a href="https://www.secretrecipe.com.my/e-voucher-redemption-outlet-list" target="_blank" rel="noopener noreferrer">https://www.secretrecipe.com.my/e-voucher-redemption-outlet-list</a></li>
            <li>E-voucher must be redeemed within the stated period of validity. No extension of validity is allowed</li>
            <li>This e-voucher can only be used in a single transaction</li>
            <li>Printed e-voucher and screenshot will not be accepted</li>
            <li>Should the value of the receipt be lower than the e-voucher value, no change shall be returned</li>
            <li>This e-voucher is strictly not for sale, non-refundable and not exchangeable for cash</li>
            <li>This e-voucher is not exchangeable in whole or part for cash, credit or other goods and services</li>
            <li>Secret Recipe is not responsible and not liable for any loss, theft or destruction of the e-voucher and has no obligation to replace or refund value for lost, stolen or damaged</li>
            <li>Secret Recipe reserves the right to change the terms and conditions without prior notice</li>
          </ul>
        </Modal>
      </div>
    </div>
  )
}

export default HomeContentYoodo;
