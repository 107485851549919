import { useState, useEffect } from 'react';
import Axios from 'axios';
import { useIsIndosatBusinessPage } from "hooks/index";

const useSignUpModalLogic = (props) => {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [error, setError] = useState(null);
  const [registered, setRegistered] = useState(false);
  const isIndosatBusinessPage = useIsIndosatBusinessPage()

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err, values) => {
      if (window.location.pathname === '/business' || window.location.pathname === '/business-dashboard') {
        values = { ...values, isRegisterCompanyPIC: true };
      }
      if (window.location.pathname === '/accept-invitation') {
        const params = new URLSearchParams(window.location.search);
        const acceptInvitationKey = params.get('key');
        values = { ...values, acceptInvitationKey };
      }
      if (!err) {
        let endpointUrl = '';
        switch (window.location.pathname) {
          case '/accept-invitation':
            endpointUrl = 'company/accept-invitation';
            break;
          default:
            endpointUrl = 'users/register';
            break;
        }
        try {
          let response = await Axios.post(endpointUrl, values);
          if (response.data === "Success: Registration successful") {
            setRegistered(true);
            if (window.location.pathname === '/accept-invitation') {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
            setError(null)
          } else {
            setError(response.data);
          }
        } catch (error) {
          setRegistered(false)
          if (error.response && error.response.data && error.response.data.message) {
            setError(error.response.data.message);
          } else {
            setError(error?.message);
          }
        }
      }
    });
  };

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const responseGoogle = async (response) => {
    console.log(response.profileObj);
    let values = response.profileObj;
    if (values) {
      values.type = 'google';
      try {
        let response = await Axios.post("users/social", values);
        if (response.data) {
          props.setuser(response.data.userObject.name);
          localStorage.setItem("usertoken", response.data.token);
          localStorage.setItem("userdata", JSON.stringify({
            first_name: response.data.userObject.first_name,
            last_name: response.data.userObject.last_name,
            contactNumber: response.data.userObject.contactNumber,
          }));
          localStorage.setItem("useremail", response.data.userObject.email);
          props.closeModal();
        }
      } catch (error) {
        setError("Error in Google sign up process");
        console.log(error);
      }
    } else {
      setError("Error in Google sign up process");
    }
  };

  const responseFacebook = async (response) => {
    console.log(response);
    let values = {
      email: response.email,
      givenName: response.name,
      familyName: '',
      type: 'facebook',
      socialId: response.userID,
    };
    try {
      let response = await Axios.post("users/social", values);
      if (response.data) {
        props.setuser(response.data.userObject.name);
        localStorage.setItem("usertoken", response.data.token);
        localStorage.setItem("userdata", JSON.stringify({
          first_name: response.data.userObject.first_name,
          last_name: response.data.userObject.last_name,
          contactNumber: response.data.userObject.contactNumber,
        }));
        localStorage.setItem("useremail", response.data.userObject.email);
        props.closeModal();
      }
    } catch (error) {
      setError("Error in Facebook sign up process");
      console.log(error);
    }
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords you entered do not match.");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  useEffect(() => {
    if (props.isAcceptInvitationForm === true && props.invitedEmail) {
      props.form.setFieldsValue({ email: props.invitedEmail });
    }
  }, [props.isAcceptInvitationForm, props.invitedEmail]);

  return {
    confirmDirty,
    error,
    registered,
    handleSubmit,
    handleConfirmBlur,
    responseGoogle,
    responseFacebook,
    compareToFirstPassword,
    validateToNextPassword,
    isIndosatBusinessPage,
  };
};

export default useSignUpModalLogic;