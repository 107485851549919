import { message } from 'antd'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { b2bIndosatInviteTeamMemberModalVisibleState, confirmModalVisibleState } from 'recoil/atoms'
import AxiosRequestHelper from 'helpers/AxiosRequestHelper'

const useDashboardContentTeamPageLogic = (props) => {
    const {
        form
    } = props

    const {
        getFieldDecorator,
        resetFields,
        setFieldsValue,
        validateFields,
    } = form

    const company = JSON.parse(localStorage.getItem("companyData"));
    // const [isVisibleB2BIndosatInviteTeamModal, setIsVisibleB2BIndosatInviteTeamModal] = useState(false)
    const [isVisibleB2BIndosatInviteTeamModal, setIsVisibleB2BIndosatInviteTeamModal] = useRecoilState(b2bIndosatInviteTeamMemberModalVisibleState)
    const [isVisibleConfirmPromoteModal, setIsVisibleConfirmPromoteModal] = useRecoilState(confirmModalVisibleState)
    const [roles, setRoles] = useState([]);
    const [currentTeamMemberId, setCurrentTeamMemberId] = useState(null);
    const [currentTeamMemberData, setCurrentTeamMemberData] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const getTeamMember = async (id) => {
        setCurrentTeamMemberData(null)
        setCurrentTeamMemberId(id)

        let config = {
            method: "get",
            url: `/company/${company.id}/team-members/${id}`,
        };

        const res = await Axios(config)
        if (res.status === 200) {
            const { invitedEmail, role, } = res.data
            // console.log(res.data,invitedEmail)
            setFieldsValue({ teamMemberEmailAddress: res.data?.User ? res.data?.User.email : invitedEmail })
            setCurrentTeamMemberData(res.data)
            if (role !== null) {
                setRoles(role)
            }
        }
    }

    const handleEditClick = (id) => {
        getTeamMember(id)
        setIsButtonDisabled(false)
        setIsVisibleB2BIndosatInviteTeamModal(true);
    };

    const handleActionClick = (id) => {
        const userConfirmed = window.confirm("Are you sure you want to archive this team member?");
        if (userConfirmed && id) {
            const url = `/company/${company.id}/team-members/${id}`

            const payload = {
                status: 'archived',
            };

            AxiosRequestHelper('post', url, payload)
                .then((res) => {
                    message.success("Team member invitation is cleared");
                    handleSuccess()
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.message || "An error occurred";
                    message.error(errorMessage);
                });
        }


        // console.log(id)
        // setIsVisibleConfirmPromoteModal(true)
        // getTeamMember(id)
        // setIsButtonDisabled(false)
        // setIsVisibleB2BIndosatInviteTeamModal(true);
    };


    const getAllTeamMembersData = async () => {
        setisLoading(true)
        // const company = JSON.parse(localStorage?.getItem("companyData"))

        let config = {
            method: "get",
            url: `/company/${company.id}/team-members`,
            params: {
                status: selectedFilter
            }
        };

        const res = await Axios(config)
        if (res.status === 200) {
            setData(res.data);
            setisLoading(false)
        }

        setRoles([])
    }

    const handleSubmit = () => {
        validateFields((err, values) => {
            if (!err) {
                const url = currentTeamMemberId
                    ? `/company/${company.id}/team-members/${currentTeamMemberId}`
                    : `/company/${company.id}/invite`;

                const payload = {
                    invitedEmail: values.teamMemberEmailAddress,
                    role: roles, // include roles in the request
                };

                AxiosRequestHelper('post', url, payload)
                    .then((res) => {
                        message.success("Team members' data has been saved");
                        handleSuccess();
                    })
                    .catch((error) => {
                        message.error(error.message || "An error occurred");
                    });
            }
        });
    };

    const handleRemoveTeamMember = () => {
        validateFields((err, values) => {
            if (!err) {
                const url = currentTeamMemberId
                    ? `/company/${company.id}/team-members/${currentTeamMemberId}`
                    : `/company/${company.id}/invite`;

                const payload = {
                    status: 'cancelled',
                };

                AxiosRequestHelper('post', url, payload)
                    .then((res) => {
                        message.success("Team member invitation is cancelled");
                        handleSuccess()
                    })
                    .catch((error) => {
                        const errorMessage = error.response?.data?.message || "An error occurred";
                        message.error(errorMessage);
                    });

            }
        });
    }


    const handleResendEmailInvitation = () => {
        validateFields((err, values) => {
            if (!err && !currentTeamMemberData.approvedAt) {
                AxiosRequestHelper(
                    'post',
                    `/company/${company.id}/resend-invitation/${currentTeamMemberId}`
                )
                    .then((res) => {
                        message.success(res.data.message);
                        handleSuccess()
                    })
                    .catch((e) => {
                        console.log(e);
                        message.error("An error occurred!");
                    });
            }
        });
    }

    const handleRoleChange = (roleId, checked) => {
        let baseRoles, updatedRoles;

        switch (roleId) {
            case 1004:
                updatedRoles = roles.includes(1004) ? roles.filter(role => role !== 1004) : [...roles, 1004];
                break;
            case 1003:
                baseRoles = [1001, 1002, 1003];
                break;
            case 1002:
                baseRoles = [1001, 1002];
                break;
            case 1001:
                baseRoles = [1001];
                break;
        }

        if (roleId !== 1004) {
            if (checked) {
                updatedRoles = roles.includes(1004) ? [...baseRoles, 1004] : baseRoles;
            } else {
                // If roleId is not 1001, we can allow unchecking it.
                if (roleId !== 1001) {
                    baseRoles = baseRoles.filter(role => role !== roleId);
                    updatedRoles = roles.includes(1004) ? [...baseRoles, 1004] : baseRoles;
                } else {
                    updatedRoles = roles;
                }
            }
        }

        setRoles(updatedRoles);
    };

    const handleFilterChange = (e) => {
        const filter = e.target.value;
        setSelectedFilter(filter);
    };

    // Function to handle form changes and validation
    const handleFormChange = () => {
        // Check if any fields have errors
        validateFields((errors) => {
            if (errors) {
                setIsButtonDisabled(true);
            } else {
                setIsButtonDisabled(false);
            }
        });
    };

    const handleSuccess = () => {
        setIsVisibleB2BIndosatInviteTeamModal(false);
        resetFields();
        setCurrentTeamMemberId(null);
        setRoles([]);
        getAllTeamMembersData();
        setCurrentTeamMemberData(null)
    };

    const onClickInviteTeamButton = () => {
        setIsVisibleB2BIndosatInviteTeamModal(true)
        setCurrentTeamMemberData(null)
        setCurrentTeamMemberId(null)
        setRoles([])
        resetFields()
    }

    useEffect(() => {
        getAllTeamMembersData()
    }, [selectedFilter]);

    return {
        form,
        getTeamMember,
        getAllTeamMembersData,
        handleEditClick,
        handleActionClick,
        handleSubmit,
        handleRemoveTeamMember,
        handleResendEmailInvitation,
        handleRoleChange,
        handleFormChange,
        handleSuccess,
        handleFilterChange,
        onClickInviteTeamButton,
        isVisibleB2BIndosatInviteTeamModal,
        currentTeamMemberId,
        currentTeamMemberData,
        roles,
        isButtonDisabled,
        data,
    }
}

export default useDashboardContentTeamPageLogic