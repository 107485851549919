import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import setupAxios from "helpers/AxiosHelper";
import { getStorage, setStorage } from "helpers/LocalStorageHelpers";
import Axios from "axios";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { RecoilRoot } from 'recoil';
import HttpApi from 'i18next-http-backend';
// import { RoomProvider } from './Context'

setupAxios();

const getIpAddress = () => {
    const ip = getStorage('_customer_ip')

    if (typeof ip != 'undefined' && ip != 'null' && ip != null && ip != 'undefined' && ip != '') return;

    Axios.get('https://api.ipify.org')
        .then((res) => setStorage('_customer_ip', res.data, 86400))
}

getIpAddress();

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'id'],
        lng: "en", // if you're using a language detector, do not define the lng option
        // fallbackLng: "en",
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
        react: { useSuspense: false },
    });

ReactDOM.render(
    <RecoilRoot>
        <Router>
            <App />

        </Router>
    </RecoilRoot>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
