import { Icon, Spin } from "antd";
import React from "react";

const Loader = ({ text = null }) => (
  <div className="modalOverlay">
    <div className="overlayContainer">
      <Spin
        indicator={
          <Icon
            type="loading-3-quarters"
            style={{ fontSize: "6rem", color: "#EB234A" }}
            spin
          />
        }
      />

      {text && <div style={{ margin: "2rem 0", opacity: 1, zIndex: 20 }}>
        {text}
      </div>
      }
    </div>
  </div>

);

export default Loader;
