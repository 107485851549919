import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const userData = JSON.parse(localStorage.getItem("userdata"));

    return (
        <Route
            {...rest}
            render={props =>
                userData && userData?.isPIC ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

export default ProtectedRoute;
