import { useState } from "react";
import Axios from "axios";
import { useIsIndosatBusinessPage } from "hooks/index";
import * as CompanyEmailValidator from "company-email-validator"
import { useSetRecoilState } from "recoil";
import { loginModalVisibleState, forgotPasswordModalVisibleState, signUpModalVisibleState, temporaryUserState } from "recoil/atoms"
import { getProviderConfigHelper } from "helpers/getProviderConfigHelper"
import { message } from "antd";

const useLoginFormLogic = (props) => {
    const {
        form,
    } = props

    console.log(process.env)

    const setIsOpenLoginModal = useSetRecoilState(loginModalVisibleState);
    const setIsOpenForgotPasswordModal = useSetRecoilState(forgotPasswordModalVisibleState);
    const setIsOpenSignUpModal = useSetRecoilState(signUpModalVisibleState);

    const setTemporaryUser = useSetRecoilState(temporaryUserState)

    const [errors, setErrors] = useState(null);
    const { getFieldDecorator } = form;
    const isIndosatBusinessPage = useIsIndosatBusinessPage()
    const { hasProvider, config: pConfig } = getProviderConfigHelper()

    const closeModal = () => {
        setIsOpenLoginModal(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors(null);

        form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    let response = await Axios.post("users/login", values);
                    if (response.data === "Error: Wrong Email/Password") {
                        setErrors(response.data);
                    } else if (response.data) {
                        const name = response.data.userObject.first_name + " " + response.data.userObject.last_name;
                        // setUser(name);
                        localStorage.setItem("usertoken", response.data.token);
                        localStorage.setItem("companyData", response.data.userObject.companyData);
                        localStorage.setItem("isPIC", response.data.userObject.isPIC);
                        localStorage.setItem("userdata", JSON.stringify({
                            ...response.data.userObject,
                            first_name: response.data.userObject.first_name,
                            last_name: response.data.userObject.last_name,
                            contactNumber: response.data.userObject.contactNumber,
                        }));
                        localStorage.setItem("useremail", response.data.userObject.email);

                        if (response.data.userObject.email === 'test_account@visitorsdeals.com') {
                            localStorage.setItem("is-test-acc", true);
                        } else {
                            localStorage.setItem("is-test-acc", false);
                        }

                        setTemporaryUser({
                            fullName: `${response.data.userObject.first_name} ${response.data.userObject.last_name}`,
                        })
                        closeModal();

                        message.success('Successfully logged in!')

                        if (pConfig.name === 'business') {
                            setTimeout(() => {
                                window.location.href = "/business-dashboard"
                            }, 1000);
                        }
                    }
                } catch (error) {
                    setErrors("An error occurred. Please try again later.");
                }
            }
        });
    };

    const responseGoogle = async (response) => {
        let values = response.profileObj;
        if (values) {
            values.type = 'google';
            try {
                let response = await Axios.post("users/social", values);
                if (response.data) {
                    // setUser(response.data.userObject.name);
                    localStorage.setItem("usertoken", response.data.token);
                    localStorage.setItem("userdata", JSON.stringify({
                        first_name: response.data.userObject.first_name,
                        last_name: response.data.userObject.last_name,
                        contactNumber: response.data.userObject.contactNumber,
                    }));
                    localStorage.setItem("useremail", response.data.userObject.email);
                    closeModal();
                }
            } catch (error) {
                // setErrors("Error in Google login process");
            }
        } else {
            // setErrors("Error in Google login process");
        }
    };

    const responseFacebook = async (response) => {
        let values = {
            email: response.email,
            givenName: response.name,
            familyName: '',
            type: 'facebook',
            socialId: response.userID
        };

        try {
            let response = await Axios.post("users/social", values);
            if (response.data) {
                // setUser(response.data.userObject.name);
                localStorage.setItem("usertoken", response.data.token);
                localStorage.setItem("userdata", JSON.stringify({
                    first_name: response.data.userObject.first_name,
                    last_name: response.data.userObject.last_name,
                    contactNumber: response.data.userObject.contactNumber,
                }));
                localStorage.setItem("useremail", response.data.userObject.email);
                closeModal();
            }
        } catch (error) {
            // setErrors("Error in Facebook login process");
        }
    };

    // Custom email validation function
    const validateCompanyEmail = (rule, value, callback) => {
        if (value && !CompanyEmailValidator.isCompanyEmail(value)) {
            callback("Please enter a company email address!");
        } else {
            callback();
        }
    };

    const onClickOpenForgotPasswordModal = () => {
        setIsOpenLoginModal(false)
        setIsOpenForgotPasswordModal(true)
    }

    const onClickOpenSignUpModal = () => {
        setIsOpenLoginModal(false)
        setIsOpenSignUpModal(true)
    }

    return {
        handleSubmit,
        errors,
        getFieldDecorator,
        responseGoogle,
        responseFacebook,
        validateCompanyEmail,
        isIndosatBusinessPage,
        onClickOpenForgotPasswordModal,
        onClickOpenSignUpModal,
    };
};

export default useLoginFormLogic;
