let badgeColor

export const tableColumns = [
    {
        data: "User", title: "Name", render: function (data, type, row) {
            return `${data?.firstName ?? ''} ${data?.lastName ?? ''}`
        }
    },
    { data: "invitedEmail", title: "Invited Email" },
    { data: "User.email", title: "Current Email" },
    { data: "User.contactNumber", title: "Phone Number" },
    { data: "User.mobileOperator", title: "Mobile Operator" },
    {
        data: "status", title: "Invitation status", render: function (data, type, row) {
            switch (data) {
                case 'approved':
                    badgeColor = 'green'
                    break
                case 'waiting':
                    badgeColor = 'orange'
                    break
                case 'cancelled':
                    badgeColor = 'red'
                    break
                case 'disabled':
                    badgeColor = 'grey'
                    break
            }
            return `<div style="background-color: ${badgeColor}; display: inline-block; color: white; padding: 0 4px; border-radius: 8px;">${data}</div>`
        }
    },
    {
        data: "status", // data is set to null because this column doesn't refer to a data property
        title: "Action",
        render: function (data, type, row) {
            switch (data) {
                case 'disabled':
                    return `
                    <div>
                        <button class="edit-button" data-id="${data}" style="display: none;">
                        </button>
                        <button class="action-button" data-id="${data}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="#000" d="M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16M96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0m48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0"/></svg>
                        </button>
                    </div>
                    `
                case 'cancelled':
                    return `
                        <div>
                            <button class="edit-button" data-id="${data}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36">
                                <path fill="black" d="m4.22 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0L13 32l15.84-15.78L20 7.4Z" class="clr-i-solid clr-i-solid-path-1"/>
                                <path fill="black" d="m33.82 8.32l-5.9-5.9a2.07 2.07 0 0 0-2.92 0L21.72 5.7l8.83 8.83l3.28-3.28a2.07 2.07 0 0 0-.01-2.93" class="clr-i-solid clr-i-solid-path-2"/>
                                <path fill="none" d="M0 0h36v36H0z"/>
                                </svg>
                            </button>
                            <button class="action-button" data-id="${data}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="#000" d="M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16M96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0m48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0"/></svg>
                            </button>
                        </div>
                        `;
                default:
                    return `
                        <div>
                            <button class="edit-button" data-id="${data}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36">
                                <path fill="black" d="m4.22 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0L13 32l15.84-15.78L20 7.4Z" class="clr-i-solid clr-i-solid-path-1"/>
                                <path fill="black" d="m33.82 8.32l-5.9-5.9a2.07 2.07 0 0 0-2.92 0L21.72 5.7l8.83 8.83l3.28-3.28a2.07 2.07 0 0 0-.01-2.93" class="clr-i-solid clr-i-solid-path-2"/>
                                <path fill="none" d="M0 0h36v36H0z"/>
                                </svg>
                            </button>
                            <button class="action-button" data-id="${data}" style="display: none;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="black" d="M7.616 22q-.667 0-1.141-.475T6 20.386V3.615q0-.666.475-1.14T7.615 2h8.77q.666 0 1.14.475T18 3.614v16.77q0 .666-.475 1.14t-1.14.475zM12 14q1.35 0 2.613.343t2.387.972V5.5H7v9.816q1.125-.631 2.388-.974T12 14m.002-1.5q-1.04 0-1.771-.728t-.731-1.77t.729-1.771t1.769-.731t1.771.729t.731 1.769t-.728 1.771t-1.77.731"/></svg>
                            </button>
                        </div>
                        `;
            }
        },
    },
];