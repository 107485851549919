import { useEffect, useState } from 'react'
import { useIsMobile } from "hooks"
import { useHistory } from 'react-router-dom';

import Axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { loginModalVisibleState } from 'recoil/atoms';

const useAcceptTeamInvitationLogic = () => {
    const history = useHistory();
    const isMobile = useIsMobile()

    const params = new URLSearchParams(history.location.search);
    const invitedEmail = params.get('email');
    const acceptInvitationKey = params.get('key');
    const userData = JSON.parse(localStorage.getItem("userdata"));

    const setIsVisibleLoginModalV2 = useSetRecoilState(loginModalVisibleState)

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        teamData: null,
        company: null,
        user: null,
        showFail: { status: false, msg: '' }
    });
    let content;

    const handleAcceptInvitation = async (userId) => {
        try {
            const response = await Axios.post("company/team-member/accept-invitation", {
                acceptInvitationKey,
                userId: userId // or replace with actual user id if available
            });

            if (response.data.success) {
                // Reload the page after successful invitation acceptance
                window.location.reload();
            } else {
                // Handle failure case if needed
            }
        } catch (error) {
            // Handle error
            console.error("Error accepting invitation:", error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await Axios.get('company/team-member/verify-invitation', {
                params: { acceptInvitationKey, invitedEmail }
            })

            const teamMember = response.data.teamMember;

            setData({
                teamData: teamMember,
                activeTeamMember: response?.data?.activeTeamMember,
                company: teamMember.Company,
                user: teamMember.User,
                showFail: { status: false, msg: '' }
            });


            setLoading(false);
            setData(prevState => ({
                ...prevState,
                content: content
            }));

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message)
            } else {
                setError(error?.message)
            }
            setLoading(false);
        }
    };

    const onClickLogin = () => {
        setIsVisibleLoginModalV2(true)
    }
    useEffect(() => {
        fetchData();
    }, []);

    return {
        userData,
        error,
        isMobile,
        loading,
        invitedEmail,
        data,
        handleAcceptInvitation,
        onClickLogin,
    }
}

export default useAcceptTeamInvitationLogic