import DataTables from "datatables.net-dt";
import React, { useEffect, useRef } from "react";

export function ReactDataTables({ data, columns, onEdit, onAction, onDelete }) {
  const tableRef = useRef(null);

  useEffect(() => {
    const dt = new DataTables(tableRef.current, {
      data,
      columns,
      createdRow: function (row, data, dataIndex) {
        // Add event listeners to the buttons
        const editButton = row?.querySelector('.edit-button');
        if (editButton && onEdit) {
          editButton.addEventListener('click', () => {
            onEdit(data.id);
          });
        }

        const actionButton = row?.querySelector('.action-button');
        if (editButton && onAction) {
          actionButton.addEventListener('click', () => {
            onAction(data.id);
          });
        }

        const deleteButton = row.querySelector('.delete-button');
        if (editButton && onDelete) {
          deleteButton.addEventListener('click', () => {
            onDelete(data.id);
          });
        }
      },
    });

    return () => {
      dt.destroy();
    };
  }, [data, columns, onEdit]);

  return <table className="dataTable" ref={tableRef}></table>;
}

export default ReactDataTables;