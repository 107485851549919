import React, { useCallback, useRef, useState } from "react";


const DashboardContentSupport = ({ isMobile, ...props }) => {
    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;

    return (
        <div style={{
            // width: '100vw',
            marginTop: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

            <div style={{
                backgroundColor: 'white',
                width: 'fit-content',
                minWidth: isDesktopScreen ? '750px' : 'auto',
                padding: '40px',
            }}>
                <h2>Support</h2>
                <hr />

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    rowGap: '40px',
                    marginTop: '40px'
                }}>
                    <img className="contactLogo" src="/icons/icon-customer-service.svg" />

                    <div className="contactContainer">
                        <div className="contactPhoneItem">
                            <img className="contactIcon" src="/icons/icon-customer-service.svg" />
                            <a href="tel:60122091006" className="contactLabel">+60 12-209 1006<br />(9 AM-5 PM)</a>
                        </div>

                        <div className="contactMailItem">
                            <img className="contactIcon" src="/icons/icon-whatsapp.svg" />
                            <a href="https://wa.me/60128966110" className="contactLabel">+60 12-896 6110</a>
                        </div>

                        <div className="contactMailItem">
                            <img className="contactIcon" src="/icons/icon-mail.svg" />
                            <a href="mailto:contactus@visitorsdeals.com" className="contactLabel">contactus@visitorsdeals.com</a>
                        </div>

                    </div>
                </div>
            </div>

            <h2 style={{
                color: 'white',
                marginTop: '40px',
                textAlign: 'center',
            }}>Please note: Free roaming is available only for specific phone operators.</h2>
        </div>
    )
}

export default DashboardContentSupport;
