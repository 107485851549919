import React, { useState } from 'react'
import Axios from 'axios'
import { saveAs } from 'file-saver';
import { Button, message } from 'antd';
import { PrinterOutlined } from '@ant-design/icons'

export const TransactionListRow = (props) => {
    const [loading, setLoading] = useState(false)
    const [cancelled, setCancelled] = useState(false)
    const { transaction } = props;

    const GenerateInvoice = async () => {
        let res = await Axios.get('/users/invoice/' + transaction.refNo + '/' + localStorage.getItem("useremail"), { responseType: 'blob' });
        console.log(res.data);
        if (res.data === "failed email") {
            message.error("No transaction found for this email");
        }
        else if (res.data === "failed refNo") {
            message.error("No transaction found with this refno")
        }
        else {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

            console.log(pdfBlob);
            const filename = "invoice" + "-" + transaction.refNo + "-user" + transaction.UserId + ".pdf";
            saveAs(pdfBlob, filename);
        }
    }

    if (loading) {
        return (<tr aria-colspan={6} style={{ textAlign: "center" }}><td>Cancelling...</td></tr>)
    }

    return (
        <>
            <tr>
                <td>{transaction.refNo}</td>
                <td>{transaction.rateId}</td>
                <td>{transaction.UserId}</td>
                <td>{transaction.hotelname}</td>
                <td>{transaction.totalRate}</td>
                <td>{transaction.baseRate}</td>
                <td>{transaction.currency}</td>
                <td>{transaction.providerId}</td>
                {cancelled ? <td>Cancelled</td> : <td>{transaction.status}</td>}
                <td>
                    {transaction.Commissions.map((Commission) => (
                        <tr>
                            <td>{Commission.amount}</td>
                            <td>{Commission.description}</td>
                            <td>{Commission.type}</td>
                        </tr>
                    ))}
                </td>
                <td style={{ border: "none !important", padding: "0px !important" }}>
                    {transaction.Taxes.map((Tax) => (
                        <tr>
                            <td>{Tax.amount}</td>
                            <td>{Tax.description}</td>
                            <td>{Tax.type}</td>
                        </tr>
                    ))}
                </td>
                <td>{transaction.refundable ? "Yes" : "No"}</td>
                <td>{transaction.depositRequired ? "Yes" : "No"}</td>
                <td>{transaction.guaranteeRequired ? "Yes" : "No"}</td>
                <td>{transaction.onlineCancellable ? "Yes" : "No"}</td>
                <td>{transaction.payAtHotel ? "Yes" : "No"}</td>
                <td><Button onClick={GenerateInvoice}><PrinterOutlined /></Button></td>
            </tr>

        </>
    )
}
