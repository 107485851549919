// import React, { useState } from "react";
// import classNames from "classnames";
// import "./FAQ.css";
// import useFAQLogic from "./FAQ.logic";

// const FAQ = (props) => {
//   const {
//     children,
//     defaultOpen = [0, 1],
//     open: openFromProps,
//     onToggle: onToggleFromProps = () => { }
//   } = props

//   const isControlled = () => (openFromProps ? true : false);
//   const [open, setIsOpen] = useState(defaultOpen);
//   const getOpen = () => (isControlled() ? openFromProps : open);
//   const isOpen = index => {
//     return getOpen().includes(index) ? true : false;
//   };
//   const onToggle = index => {
//     if (isControlled()) {
//       onToggleFromProps(index);
//     } else {
//       if (getOpen().includes(index)) {
//         setIsOpen(getOpen().filter(item => item !== index));
//       } else {
//         setIsOpen([...getOpen(), index]);
//       }

//       onToggleFromProps(index);
//     }
//   };
//   return (
//     <dl>
//       {React.Children.map(children, (child, index) => {
//         return React.cloneElement(child, {
//           isOpen: isOpen(index),
//           onToggle: () => onToggle(index)
//         });
//       })}
//     </dl>
//   );
// }
// export default FAQ



import React from "react";
import classNames from "classnames";
import "./FAQ.css";
import useFAQLogic from "./FAQ.logic";

const FAQ = ({ children, defaultOpen = [] }) => {
  const { isOpen, onToggle } = useFAQLogic(defaultOpen);

  return (
    <dl>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          isOpen: isOpen(index),
          onToggle: () => onToggle(index)
        })
      )}
    </dl>
  );
};

const Question = ({ children, isOpen, answerId, onToggle }) => (
  <dt>
    <button
      className="FAQ__question"
      aria-expanded={isOpen}
      aria-controls={answerId}
      onClick={onToggle}
    >
      {children(isOpen, onToggle)}
    </button>
  </dt>
);

const Answer = ({ children, id, isOpen }) => {
  const mergedClassname = classNames("FAQ__answer", {
    "FAQ__answer--hidden": !isOpen
  });
  return (
    <dd>
      <p className={mergedClassname} id={id}>
        {children}
      </p>
    </dd>
  );
};

const QAItem = ({ children, isOpen, onToggle }) =>
  React.Children.map(children, (child) =>
    React.cloneElement(child, {
      isOpen,
      onToggle
    })
  );

FAQ.QAItem = QAItem;
FAQ.Question = Question;
FAQ.Answer = Answer;

export default FAQ;
