// components/ModalV2.js
import { Modal } from 'antd';
import React from 'react';
import { useRecoilState } from 'recoil';
import { modalVisibleState } from 'recoil/atoms';

const ModalV2 = (props) => {
    const {
        recoilState,
        children,
        title = null,
        footer = null,
        bodyStyle = {},
        style = {},
        className = null,
    } = props;

    const [visible, setVisible] = useRecoilState(recoilState);

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Modal
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            title={title}
            footer={footer}
            bodyStyle={bodyStyle}
            style={style}
            className={className}
        >
            {/* <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p> */}
            {children}
        </Modal>
    );
};

export default ModalV2;
