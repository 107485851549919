import React from 'react'
import useIsDesktop from 'hooks/useIsDesktop'

const useDashboardContentTeamPageStyles = () => {

  const isDesktop = useIsDesktop()

  const styles = {
    mainContainer: {
      marginTop: '-70px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      backgroundColor: 'white',
      width: isDesktop ? '950px' : 'fit-content',
      minWidth: isDesktop ? '750px' : 'auto',
      maxHeight: isDesktop ? '80vh' : '60vh',
      overflowY: 'scroll',
      padding: '40px',
    },
    radioGroup: {
      marginTop: '16px',
      marginBottom: '16px',
    }
  }

  return {
    styles
  }
}

export default useDashboardContentTeamPageStyles