import { Button, Rate, Skeleton } from "antd";
import { getBaseURL } from "constants/Endpoint";
import React from "react";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router-dom";
import alldata from "../sampleBookingData";
import "./SingleBooking.css";
const hoteldata = alldata.hotel;

const daysCalculation = (data) => Math.round(
  (Date.parse(data.checkoutdate) - Date.parse(data.checkindate)) /
  (1000 * 60 * 60 * 24)
);

const MobileBookingDetails = (data) => {
  const days = daysCalculation(data);

  return (
    <div>
      <div style={{ margin: "1rem 0" }} className="faded">
        Details for{" "}
        <b style={{ color: "black" }}>
          {data.RoomCount} {data.RoomCount === 1 ? " room" : " rooms"}, {days}{" "}
          nights
        </b>{" "}
        and{" "}
        <b style={{ color: "black" }}>
          {data.Guestcount}
          {data.Guestcount === 1 ? " Person" : " People"}{" "}
        </b>
      </div>

      <div style={{ padding: "1rem", border: "1px solid #E9EDEF" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="faded">Check in:</div>
          <div>{data.checkindate}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="faded">Check out:</div>
          <div>{data.checkoutdate}</div>
        </div>
      </div>
    </div>
  );
};

const DesktopBookingDetails = (data) => {
  const days = daysCalculation(data);

  return (
    <div style={{ marginTop: "1rem" }}>
      <div
        style={{
          padding: "1rem",
          border: "1px solid #E9EDEF",
          marginBottom: "2rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ color: "#858586" }}>Check in</div>
          <div>
            {data.checkindate}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #E9EDEF",
            paddingLeft: "1rem",
          }}
        >
          <div style={{ color: "#858586" }}>Check out</div>
          <div>
            {data.checkoutdate}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #E9EDEF",
            paddingLeft: "1rem",
          }}
        >
          <div style={{ color: "#858586" }}>Nights</div>
          <div>{days}</div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #E9EDEF",
            paddingLeft: "1rem",
          }}
        >
          <div style={{ color: "#858586" }}>Guests</div>
          <div>{data.Guestcount}</div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #E9EDEF",
            paddingLeft: "1rem",
          }}
        >
          <div style={{ color: "#858586" }}>Rooms</div>
          <div>{data.RoomCount}</div>
        </div>
      </div>
    </div>
  );
};

export const SingleBooking = ({ data, bookingLoading = false }) => {

  const showBookingField = (data) => {
    if (bookingLoading)
      return (
        <Skeleton
          title={false}
          paragraph={{ rows: 1, width: ["15vw"] }}
          active
        />
      )

    return data
  }

  const history = useHistory();

  const goBack = () => {
    history.goBack(); // Go back to the previous page
  };

  // const data = hoteldata; // = props.data
  return (
    <div className="SingleBooking">
      <div className="SingleBookingMain">
        <div style={{ marginBottom: '4rem' }}>
          <a href="javascript:void(0)" style={{ color: '#f48148', }} onClick={goBack}>&lt; Back to Home</a>
        </div>
        <div className="headingFormatter">
          <div>
            <div className="heading">{data.name}</div>
            <Rate
              disabled={true}
              value={Number(data.stars)}
              className="Searchstars"
            />
            <div className="faded">{data.location}</div>
          </div>
          <div
            className="hotelImage"
            style={{ backgroundImage: `url( ${data.imageurl} )` }}
          />
          {data.showDownload &&
            <Button target={'_blank'} href={`${getBaseURL()}/payment/booking-detail/pdf/${data.name}?refId=${data.referenceid}`} type="primary" style={{ margin: "1rem 0" }}>
            {/* <Button target={'_blank'} href={`${process.env.REACT_APP_BACKEND_URL}/payment/booking-detail/pdf/${data.name}?refId=${data.referenceid}`} type="primary" style={{ margin: "1rem 0" }}> */}
              Download Confirmation
            </Button>
          }
        </div>
        <div className="subheading">Booking details</div>

        <MediaQuery maxWidth={775}>{MobileBookingDetails(data)}</MediaQuery>
        <MediaQuery minWidth={776}>{DesktopBookingDetails(data)}</MediaQuery>

        <div className="infoContainer">
          <div className="infodesc ">Booking ID</div>
          <div className="bookinginfo bookingid">{showBookingField(data.bookingid)}</div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Booking Status</div>
          <div
            className="bookinginfo"
            style={{ color: data.status === "Confirmed" ? "#5ED50B" : "red" }}
          >
            {showBookingField(data.status)}
          </div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Reference ID</div>
          <div className="bookinginfo">{data.referenceid}-{data.vd_booking_id}</div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Contact details</div>
          <div className="bookinginfo">{showBookingField(data.contactdetails)}</div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Guest name</div>
          <div className="bookinginfo">{showBookingField(data.guestname)}</div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Additional guest names</div>
          <div className="bookinginfo">{data.guest_details_per_room}</div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Room details</div>
          <div className="bookinginfo">{
            data.roomdetails?.map((room) => (<div>{room.name}</div>))
          }</div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Payment details</div>
          <div className="bookinginfo">{data.paymentdetails}</div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Payment Status</div>
          <div
            className="bookinginfo"
            style={{ color: data.payment_status === "PAID" ? "#5ED50B" : "red" }}
          >
            {data.payment_status}
          </div>
        </div>
        <div className="infoContainer">
          <div className="infodesc">Additional details</div>
          <div className="bookinginfo">{data.details}</div>
        </div>
      </div>

      <div
        className="SingleBookingSide"
      >
        <img src={data.imageurl} />
      </div>
    </div>
  );
};
