import React from 'react'
import ModalV2 from 'components/Global/ModalV2/ModalV2'
import { styles } from './SignUpModalV2.styles'
import { signUpModalVisibleState } from 'recoil/atoms';
import SignUpForm from '../SignUpForm/SignUpForm';

const SignUpModalV2 = () => {
  return (
    <ModalV2
      recoilState={signUpModalVisibleState}
      children={
        <SignUpForm />
      }
      bodyStyle={styles.bodyStyle}
      style={styles.style}
      className="loginModal"
    />
  )
}

export default SignUpModalV2