import { message } from 'antd';
import Axios from 'axios';
import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil';
import { loginModalVisibleState, forgotPasswordModalVisibleState } from 'recoil/atoms';

const useResetNewPasswordLogic = (props) => {
    const [isLoading, setIsLoading] = useState(false)

    const [isPasswordHasBeenChanged, setIsPasswordHasBeenChanged] = useState(false)
    const [isKeyInvalid, setIsKeyInvalid] = useState(false)

    const setIsVisibleLoginModalV2 = useSetRecoilState(loginModalVisibleState)
    const setIsVisibleResetPasswordModalV2 = useSetRecoilState(forgotPasswordModalVisibleState)

    // Extract parameters from URL
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get('key');
    const email = queryParams.get('email');

    const onClickLogin = () => {
        setIsVisibleLoginModalV2(true)
    }

    const onClickResetPassword = () => {
        setIsVisibleResetPasswordModalV2(true)
    }

    const verifyKey = async () => {
        setIsLoading(true);

        try {
            // Send POST request to verify the key
            const response = await Axios.post('/auth/password/verify', { email, forgetPasswordKey: key });

            if (response.data.success) {
                // Key is valid
                setIsLoading(false);
            } else {
                // Key is invalid
                message.error(response.data.msg || 'Invalid key or email');
            }
        } catch (err) {
            setIsKeyInvalid(true)
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        verifyKey();
    }, []);

    return {
        isLoading,
        isPasswordHasBeenChanged,
        isKeyInvalid,
        onClickLogin,
        onClickResetPassword,
        email
    }
}

export default useResetNewPasswordLogic