import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Error = () => {
    const history = useHistory();

    const handleBack = () => {
        history.goBack()
    }

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '16px'
        }}>
            <h1 style={{
                fontSize: '72px'
            }}>404</h1>
            <h4>It seems like the page you are looking for cannot be found</h4>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                columnGap: '16px'
            }}>
                <Button
                    onClick={handleBack}
                    type='ghost'>Return to Previous page</Button>
                <Button href='/' type='primary'>Return to Home page</Button>
            </div>
        </div>
    );
}

export default Error;
