import React from "react";
import "antd/dist/antd.css";
import "../Auth.styles.css";
import { Form, Input, Button, } from "antd";
import useForgotPasswordFormLogic from "./ForgotPasswordForm.logic";

const ForgotPasswordFormComponent = (props) => {
    const {
        handleSubmit,
        getFieldDecorator,
    } = useForgotPasswordFormLogic(props);

    return (
        <div style={{
            color: 'white',
            backgroundColor: '#ffffff30',
            backdropFilter: "blur(5px)",
            padding: 10,
        }}>
            <Form
                onSubmit={handleSubmit}
                className="login-form"
                hideRequiredMark={true}
                colon={false}
            >
                <div className="text" style={{ color: 'white' }}>
                    Please enter your email address so we can send you a link to reset your password.
                </div>
                <br />
                <Form.Item label={<span style={{ color: 'white' }}>Email</span>}>
                    {getFieldDecorator("email", {
                        rules: [
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                            {
                                type: "email",
                                message: "Please input a valid email",
                            },
                        ],
                    })(<Input />)}
                </Form.Item>

                <br />

                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmit}
                    style={{ marginRight: "1rem", width: "100%" }}
                >
                    Confirm
                </Button>
            </Form>
        </div>

    );
};

const ForgotPasswordForm = Form.create({ name: "forgot_password_form_v2" })(ForgotPasswordFormComponent);

export default ForgotPasswordForm;
