const useInviteTeamMemberFormStyles = () => {
    const styles = {
        formItem: {
            margin: 0,
        }
    }

    return {
        styles
    }
}

export default useInviteTeamMemberFormStyles