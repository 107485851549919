import React, { useEffect, useState, } from "react";
import Header from "components/Header/Header";
import MediaQuery from "react-responsive";
import { SearchResultsDesktop } from "./SearchResultsDesktop";
import { SearchResultsMobile } from "./SearchResultsMobile";
import { AccommodationType } from "./AccommodationType";
import { StarRating } from "./StarRating";
import { InputNumber, Pagination } from "antd";
import { SortBy } from "./SortBy";
import Axios from "axios";
import { setStorage } from "helpers/LocalStorageHelpers";
import { _defaultHeaders, channelId, delay, } from "helpers/AxiosHelper";
import { HOTEL_DETAIL } from "constants/RouteNames";
import moment from "moment";
import searchHotelBg from "images/provider/home/search_hotels.jpg"
import { _gtag } from "helpers/GlobalHelper"
import SearchBar from "components/SearchBar/SearchBar";

import RoomComponent from "components/Hotel/RoomComponent/RoomComponent";
import FooterPage from "components/Global/FooterPage/FooterPage";

import { useCurrencyContext } from "hooks/useCurrencyContext";

const SearchResults = (props) => {
  if (
    // !props.location.state &&
    props.match.params.location &&
    props.match.params.placeId) {
    const params = props.match.params;
    let start = props.location.state.startDate ?? new moment(new Date().setDate(new Date().getDate() + 2)).format("YYYY-MM-DD");
    let end = props.location.state.endDate ?? new moment(new Date().setDate(new Date().getDate() + 3)).format("YYYY-MM-DD");

    params.location = params.location.replaceAll('-', ', ').replaceAll('_', ' ');

    props.location.state = {
      place: params.location,
      placeId: params.placeId,
      startDate: start,
      endDate: end,
      occupancies: [{ numOfAdults: props.location.state.noofAdults ?? 1 }],
      noofAdults: props.location.state.noofAdults ?? 1,
      noofChild: props.location.state.noofChild ?? 0,
      noofroom: props.location.state.noofroom ?? 1,
      panes: props.location.state.panes ?? [
        [
          {
            key: "1",
            adults: 1,
            children: 0,
            childrenInfo: [],
            closable: false,
          },
        ]
      ]
    }

  }

  const DISTANCE_RAIDUS = 15;
  const vervoUrl = process.env.REACT_APP_PROVIDER_URL;
  const autoSuggestURl = process.env.REACT_APP_PROVIDER_AUTOSUGEST_URL;
  const [selectedCurrency, setCurrency] = useCurrencyContext();

  const changeCurrency = (val) => {
    localStorage.setItem("currency", val);
    setCurrency(val);
  };

  const hasProvider = props.location.state.hasProvider;

  let callCount = 0;
  const [perPageContent, setperPageContent] = useState(16);
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setloading] = useState(true);
  const [noResults, setNoResults] = useState(false);


  if (!localStorage.getItem("currency")) {
    changeCurrency("MYR");
  }

  const [hotel, setHotel] = useState([]);
  const [filter, setFilter] = useState(
    // localStorage.getItem("filter") ? localStorage.getItem("filter") : "pl"
    "sh"
  );
  const [inProgress, setProgress] = useState(true);
  const [minHotelPrice, setMin] = useState(0);
  const [maxHotelPrice, setMax] = useState(100);

  const [minFilterPrice, setFilterMin] = useState(null);
  const [maxFilterPrice, setFilterMax] = useState(null);

  const [CheckboxStars, setCheckboxStars] = useState([]);
  const [CheckboxPopular, setCheckboxPopular] = useState([]);

  const [searchterm, setsearchterm] = useState("");

  const hotelHolder = {};
  const defaultParams = {
    channelId: channelId(),
    culture: "en-US",
  };

  const header = _defaultHeaders(selectedCurrency);

  const getLocation = async () => {
    let placeId = props.location.state.placeId;

    if (!placeId) {
      // const res = await Axios.get(`${vervoUrl}/locations/locationcontent/autosuggest?term="${props.location.state.place}"`)
      const res = await Axios.get(`${autoSuggestURl}/locations/locationcontent/autosuggest?term="${props.location.state.place}"`)
      if (res.data.status == 'success') {
        res.data.locationSuggestions.forEach(val => {
          if (val.name === props.location.state.place && val.type != 'Hotel') {
            placeId = val.id
            // props.history.push({
            //   pathname : ROOMS,
            //   state :{
            //     ...props.location.state,
            //     placeId : val.id
            //   }
            // })
          }
          else {
            placeId = res.data.locationSuggestions[0]?.id
          }
        });
      }
    }

    // if( !placeId ) {
    //   alert('Location not found');
    //   window.history.back()
    // }

    const res = await Axios.get(`${autoSuggestURl}/locations/locationcontent/location/${placeId}`);
    if (res.status === 200) return res.data;

    return Promise.reject("location failed");
  };

  const getStaticData = async (opts) => {
    const k = btoa(opts);

    // const cacheData = getStorage(`_search_result_static_${k}`);
    const cacheData = null;

    let data = [];
    let firstLoad = [];

    if (!cacheData) {
      const res = await Axios.post(
        `${vervoUrl}/content/hotelcontent/getHotelContent`,
        {
          ...defaultParams,
          ...opts,
          contentFields: ["basic"],
        },
        {
          headers: header,
        }
      );

      // if(res.status === 204) {
      //   // alert('No results found! (UI will be available soon!)')
      //   return 
      // }

      if (res.status == 200) {

        // const filHotel = res.data.hotels.filter((item) => parseInt(item.distance) <= DISTANCE_RAIDUS);
        const filHotel = res.data.hotels.sort((itemA, itemB) => {
          if (parseFloat(itemA.distance) < parseFloat(itemB.distance)) return -1;
          if (parseFloat(itemA.distance) > parseFloat(itemB.distance)) return 1;

          return 0;
        });

        res.data.hotels = filHotel;

        const staticData = res.data;

        if (!cacheData)
          setStorage(
            `_search_result_static_${k}`,
            JSON.stringify({ opts, staticData }),
            300
          );

        data = res.data;
        firstLoad = res.data.hotels;
      }
    } else {
      const cache = JSON.parse(cacheData)
      data = cache.staticData;
      firstLoad = cache.staticData.hotels;
    }

    setloading(false);
    setHotel(firstLoad);

    if (data?.hotels == undefined) {
      return false
    }

    if (data.hotels.length <= 0) {
      return false;
    } else {
      data.hotels.map((item) => {
        hotelHolder[item.id] = item;
      });
    }

  };

  const initCall = async (opts) => {
    const res = await Axios.post(
      `${vervoUrl}/hotel/availability/init`,
      {
        ...defaultParams,
        ...opts,
      },
      {
        headers: header,
      }
    );

    if (res.status >= 200 && res.status < 300) {
      props.history.correlationId = header.correlationId;
      return res.data
    };


    return Promise.reject("Init Call failed");
  };

  const recursiveCall = async (
    count,
    responseToken,
    nextResultsKey = undefined
  ) => {
    let url = `${vervoUrl}/hotel/availability/async/${responseToken}/results`;
    // console.log(url, header)
    if (
      typeof nextResultsKey != "undefined" &&
      nextResultsKey != "null" &&
      nextResultsKey != "undefined"
    ) {
      url += `?nextResultsKey=${nextResultsKey}`;
    }

    const res = await Axios.get(url, {
      headers: header,
    });

    return res.data;
  };

  const processHotel = async (data) => {
    data.hotels &&
      data.hotels.map((item) => {
        if (typeof hotelHolder[item.id] !== "undefined") {
          hotelHolder[item.id] = { ...hotelHolder[item.id], ...item };
        }
      });
  };

  const getHotel = (
    responseToken,
    nextResultsKey = undefined,
    isFromStorage = false
  ) => {
    recursiveCall(callCount, responseToken, nextResultsKey).then(
      async (data) => {
        processHotel(data);
        setStorage("_search_result_token", responseToken, 3600); // 10 minutes

        // console.log(responseToken)

        if (data.status == "InProgress") {
          if (typeof data.nextResultsKey != "undefined") {
            nextResultsKey = data.nextResultsKey;
          }

          await delay(500);

          getHotel(responseToken, nextResultsKey);
        } else {
          if (data.status == "Completed") {
            const newstaticHotel = {};
            Object.values(hotelHolder).map((item, index) => {
              if (item.rate != null) {
                item.rate.totalRate = parseFloat(item.rate.totalRate).toFixed(2)
                return (newstaticHotel[item.id] = item);
              }
            });

            // Hotels Loaded
            setHotel(Object.values(newstaticHotel));

            let max = Math.max.apply(
              Math,
              Object.values(newstaticHotel).map((o) => {
                return o.rate.totalRate;
              })
            );
            let min = Math.min.apply(
              Math,
              Object.values(newstaticHotel).map((o) => {
                return o.rate.totalRate;
              })
            );
            setMax(Math.ceil(max));
            setMin(Math.floor(min));

            // setFilterMax(Math.ceil(max));
            // setFilterMin(Math.floor(min));

            setProgress(false);

            if (!isFromStorage) {
              setStorage("_search_result_key", nextResultsKey, 3600); // 10 minutes
            }
          }

          return data;
        }
      }
    );
  };

  const commas = (x) => {
    return x
      .toString()
      .replace(/\D/, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getHotelData = async () => {
    const checkIn = props.location.state.startDate;
    const checkOut = props.location.state.endDate;
    const occupancies = props.location.state.occupancies;

    const tokenStorage = null;
    // const tokenStorage = getStorage('_search_result_token');
    // const keyStorage = getStorage('_search_result_key');

    const locationInfo = await getLocation();

    let locationCoordinate = {
      multiPolygonalRegion: {
        polygons: [
          { coordinates: [locationInfo.coordinates] }
        ]
      },
    }
    if (locationInfo.boundaries) {
      const coo = [];
      locationInfo.boundaries.forEach(coor => {
        coo.push({
          coordinates: coor
        })
      });

      locationCoordinate.multiPolygonalRegion.polygons = coo;
      locationCoordinate.distanceFrom = locationInfo.coordinates;
    }

    if (locationInfo.type === 'Hotel') {
      const state = {
        correlationId: header.correlationId,
        place: props.location.state.place,
        startDate: checkIn,
        endDate: checkOut,
        noofAdults: props.location.state.noofAdults,
        noofChild: props.location.state.noofChild,
        noofroom: props.location.state.noofroom,
        panes: props.location.state.panes
      };


      const timeStamp = new Date().getTime();
      const key = `VDHOL-${locationInfo.referenceId}-${timeStamp}`;
      localStorage.setItem(key, JSON.stringify(state));

      props.history.replace(HOTEL_DETAIL.replace(':slug', locationInfo.referenceId) + `?d=${timeStamp}`);

    } else {
      if (!locationInfo.boundaries || locationInfo.boundaries.length === 0) {
        locationCoordinate = {
          circularRegion: {
            centerlat: locationInfo.coordinates.lat,
            centerlong: locationInfo.coordinates.long,
            radiusInKM: 10
          },
        }
      }

      getStaticData(locationCoordinate).then((res) => {

        if (res === false) {
          setProgress(false);
          return;
        }

        if (tokenStorage) {
          getHotel(tokenStorage)
        } else {
          initCall({
            currency: selectedCurrency,
            checkIn,
            checkOut,
            occupancies,
            countryOfResidence: "MY", // TODO : Need to get from user. If put MY get less result
            nationality: "MY", // TODO : Need to get from user. If put MY get less result
            ...locationCoordinate
          }).then(async ({ token }) => {
            await delay(1000);
            getHotel(token)
          });
        }
      });

    }
  };

  const sort_by = (field, reverse, primer) => {
    const key = primer
      ? function (x) {
        return primer(x[field]);
      }
      : function (x) {
        return x[field];
      };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

  const sort_by_price = (reverse) => {
    const key = function (x) {
      return parseFloat(x.rate.totalRate);
    };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

  const changeSlider = (event, val) => {
    setFilterMin(val[0]);
    setFilterMax(val[1]);
  };

  useEffect(() => {
    setProgress(true);
    setloading(true);
    setHotel([]);
    getHotelData();
  }, [
    props.location.state.place,
    selectedCurrency,
    props.location.state.startDate,
    props.location.state.endDate,
    props.location.state.noofAdults,
    props.location.state.noofChild,
    props.location.state.noofroom,
  ]);

  // brings scroll to top on every search
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleKeyUp = (event) => {
    if (event.ctrlKey && event.keyCode === 75) {
      window.showProvider = true;
      const els = document.getElementsByClassName("provider-name")
      for (const el of els) {
        el.style.display = "";
      }
    } else {
      if (window.showProvider) {
        window.showProvider = false
        const els = document.getElementsByClassName("provider-name")
        for (const el of els) {
          el.style.display = "none";
        }

      }
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  let filteredHotel = [];
  let filtersObject = {};
  let hotelType = {};
  let options = {};
  if (!inProgress) {
    //make an object with all filter values

    //finds the filter values ie 5star (15), 4star (100) ...
    filtersObject = {
      star1: 0,
      star2: 0,
      star3: 0,
      star4: 0,
      star5: 0,
      freeBreakfast: 0,
      payAtHotel: 0,
      refundable: 0,
    };

    options = {};
    hotelType = {};

    for (let singleHotel in hotel) {
      let starRating = hotel[singleHotel].starRating;

      if (starRating < 2.0) {
        filtersObject["star1"]++;
      } else if (starRating < 3.0) {
        filtersObject["star2"]++;
      } else if (starRating < 4.0) {
        filtersObject["star3"]++;
      } else if (starRating < 5.0) {
        filtersObject["star4"]++;
      } else if (starRating < 6.0) {
        filtersObject["star5"]++;
      }

      if (hotel[singleHotel].options) {
        if (hotel[singleHotel].options.freeBreakfast) {
          filtersObject["freeBreakfast"]++;
        }

        if (hotel[singleHotel].options.payAtHotel) {
          filtersObject["payAtHotel"]++;
        }

        if (hotel[singleHotel].options.refundable) {
          filtersObject["refundable"]++;
        }
      }

      // to count Hotel, Apartment...
      if (hotel[singleHotel].type) {
        if (hotelType.hasOwnProperty(hotel[singleHotel].type)) {
          hotelType[hotel[singleHotel].type]++;
        } else {
          hotelType[hotel[singleHotel].type] = 1;
        }
      }

      if (hotel[singleHotel].options) {
        for (let key of Object.keys(hotel[singleHotel].options)) {
          if (hotel[singleHotel].options[key] === true) {
            if (options.hasOwnProperty(key)) {
              options[key]++;
            } else {
              options[key] = 1;
            }
          }
        }
      }
    }
  }

  if (!inProgress) {
    //Stars filter, they will "or" with other star filters and "and" with the other filters
    CheckboxStars.forEach((star) => {
      switch (star) {
        case "star1":
          filteredHotel = [
            ...filteredHotel,
            ...hotel.filter(
              (val) => val.starRating < 2.0 && val.starRating >= 1.0
            ),
          ];
          break;
        case "star2":
          filteredHotel = [
            ...filteredHotel,
            ...hotel.filter(
              (val) => val.starRating < 3.0 && val.starRating >= 2.0
            ),
          ];
          break;
        case "star3":
          filteredHotel = [
            ...filteredHotel,
            ...hotel.filter(
              (val) => val.starRating < 4.0 && val.starRating >= 3.0
            ),
          ];
          break;
        case "star4":
          filteredHotel = [
            ...filteredHotel,
            ...hotel.filter(
              (val) => val.starRating < 5.0 && val.starRating >= 4.0
            ),
          ];
          break;
        case "star5":
          filteredHotel = [
            ...filteredHotel,
            ...hotel.filter(
              (val) => val.starRating < 6.0 && val.starRating >= 5.0
            ),
          ];
          break;
      }
    });

    if (CheckboxStars.length === 0) {
      //incase no star filters are selected
      filteredHotel = hotel;
    }

    CheckboxPopular.forEach((filterName) => {
      switch (filterName) {
        case "payAtHotel":
        case "freeBreakfast":
        case "freeCancellation":
        case "refundable":
          filteredHotel = filteredHotel.filter(
            (hotelInfo) =>
              hotelInfo.options && hotelInfo.options[filterName] === true
          );
          break;

        default:
          filteredHotel = filteredHotel.filter(
            (val) => val.type === filterName
          );
      }
    });

    filteredHotel = filteredHotel.filter((hotel) => {
      return (
        (!minFilterPrice || hotel.rate?.totalRate >= minFilterPrice) &&
        (!maxFilterPrice || hotel.rate?.totalRate <= maxFilterPrice)
      );
    });

    if (searchterm !== "") {
      filteredHotel = filteredHotel.filter((val) =>
        val?.name.toLowerCase().includes(searchterm.toString().toLowerCase())
      );
    }
  }

  if (inProgress) {
    filteredHotel = hotel;
  } else if (filter === "sh") {
    filteredHotel = filteredHotel.sort(sort_by("starRating", true, parseFloat));
  } else if (filter === "pl") {
    filteredHotel = filteredHotel.sort(sort_by_price(false));
  } else if (filter === "ph") {
    filteredHotel = filteredHotel.sort(sort_by_price(true));
  } else if (filter === "nr") {
    filteredHotel = filteredHotel.sort(sort_by("distance", false, parseFloat));
  }

  let totalFilteredHotels = filteredHotel.length;

  const indexOfLastPost = currentPage * perPageContent;
  const indexOfFirstPost = indexOfLastPost - perPageContent;
  filteredHotel = filteredHotel.slice(indexOfFirstPost, indexOfLastPost);

  let hotelResults;
  if (!inProgress && filteredHotel.length === 0) {
    hotelResults = (
      <div
        style={{
          margin: "20vw auto",
          fontSize: "large",
          width: "auto",
          textAlign: "center",
        }}
      >
        No Results found
      </div>
    );
  } else {

    _gtag('view_search_results');

    hotelResults = filteredHotel.map((item, index) => {
      return (
        <RoomComponent
          index={index}
          history={props.history}
          location={props.location.state}
          place={props.location.state.place}
          // placeId={props.location.state.placeId}
          placeId={props.match.params.placeId}
          startDate={props.location.state.startDate}
          endDate={props.location.state.endDate}
          noofAdults={props.location.state.noofAdults}
          noofChild={props.location.state.noofChild}
          noofroom={props.location.state.noofroom}
          key={item.id}
          {...item}
        />
      );
    });
  }

  const AccommodationTypeFilters = (
    <AccommodationType
      filtersObject={filtersObject}
      checkboxPopular={CheckboxPopular}
      setcheckboxPopular={setCheckboxPopular}
      setcheckboxStars={setCheckboxStars}
      checkboxStars={CheckboxStars}
      hotelType={hotelType}
    />
  );

  const StarRatingFilters = (
    <StarRating
      setcheckboxStars={setCheckboxStars}
      checkboxStars={CheckboxStars}
      filtersObject={filtersObject}
    />
  );

  const sliderFilter = (
    <div>
      <div
        style={{ color: "#F48247", margin: "1rem 0", paddingBottom: "1rem" }}
      >
        {selectedCurrency} {commas(minHotelPrice)} - {commas(maxHotelPrice)}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <InputNumber
          onClick={(event) => event.stopPropagation()}
          onChange={(val) =>
            setFilterMin(val ? val.toString().replace(/\D/, "") : null)
          }
          formatter={commas}
          style={{ width: "auto", marginRight: "1rem" }}
          placeholder="Min"
          value={minFilterPrice}
        />

        <div>to</div>

        <InputNumber
          onClick={(event) => event.stopPropagation()}
          onChange={(val) =>
            setFilterMax(val ? val.toString().replace(/\D/, "") : null)
          }
          formatter={commas}
          style={{ width: "auto", marginLeft: "1rem" }}
          placeholder="Max"
          value={maxFilterPrice}
        />
      </div>

      {/* SLIDER, currently disabled */}
      {/* <div style={{ margin: "1rem" }}>
        <IOSSlider
          aria-labelledby="range-slider"
          min={minHotelPrice}
          max={maxHotelPrice}
          value={[minFilterPrice, maxFilterPrice]}
          onChange={changeSlider}
        />
      </div> */}
    </div>
  );

  const sortBy = (
    <SortBy value={filter} onChange={(e) => setFilter(e.target.value)} />
  );

  const paging = (
    <Pagination
      onChange={(page, size) => {
        window.scrollTo(0, 0);
        setCurrentPage(page);
      }}
      current={currentPage}
      defaultPageSize={16}
      total={totalFilteredHotels}
    />
  );

  return (
    <div className="SearchResults" style={{
      position: 'relative'
    }}>
      <MediaQuery maxWidth={775}>
        <div style={{
          height: "auto",
          position: 'relative'
        }}>
          <div style={{
            backgroundImage: `none`,
            backgroundColor: 'transparent',
            height: "60vh",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}></div>
          <Header logoColor={'white'} isDarkFont={true} isMobile />
          <SearchResultsMobile
            loading={loading}
            totalHotels={totalFilteredHotels}
            location={props.location.state}
            history={props.history}
            hotels={hotelResults}
            hotelsLoading={inProgress}
            AccommodationTypeFilters={AccommodationTypeFilters}
            StarRatingFilters={StarRatingFilters}
            slider={sliderFilter}
            sortBy={sortBy}
            paging={paging}
            HotelNameSearch={searchterm}
            setHotelNameSearch={setsearchterm}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </MediaQuery>
      <MediaQuery minWidth={776}>
        <div style={{
          backgroundImage: `url(${searchHotelBg})`,
          height: "auto",
          backgroundSize: "cover",
          backgroundPositionY: 'center'
        }}>
          <Header logoColor={'white'} isDarkFont={true} />
          <div style={{ width: "75vw", margin: 'auto', padding: '5rem 0' }}>
            <div>
              <div style={{ fontSize: '5rem', fontWeight: '700', color: 'rgb(217, 59, 79)' }}>
                Free roaming with
                <br />
                every booking
              </div>
            </div>
            <div style={{ margin: "2rem 0 0 0" }}>
              <SearchBar
                history={props.history}
                place={props.location.state.place}
                startDate={props.location.state.startDate}
                endDate={props.location.state.endDate}
                panes={props.location.state.panes[0]}
                totalAdults={props.location.state.noofAdults}
                totalChildren={props.location.state.noofChild}
                totalRooms={props.location.state.noofroom}
              />
            </div>
          </div>
        </div>
        <SearchResultsDesktop
          loading={loading}
          location={props.location.state}
          totalHotels={totalFilteredHotels}
          history={props.history}
          hotels={hotelResults}
          hotelsLoading={inProgress}
          AccommodationTypeFilters={AccommodationTypeFilters}
          StarRatingFilters={StarRatingFilters}
          slider={sliderFilter}
          sortBy={sortBy}
          paging={paging}
          HotelNameSearch={searchterm}
          setHotelNameSearch={setsearchterm}
          setCurrentPage={setCurrentPage}
        />
      </MediaQuery>
      <FooterPage hasProvider={hasProvider} />
    </div>
  );
};

export default SearchResults;
