import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { _gtag } from "helpers/GlobalHelper"

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    _gtag('page_view', 'event', {
      send_to: [process.env.REACT_APP_GOOGLE_ANALYTIC, process.env.REACT_APP_GOOGLE_ANALYTIC2],
      page_title: '',
      page_location: location.pathname,
      page_path: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;
