import React from "react";
import './FAQGeneral.css';
import Header from "components/Header/Header";
import FooterPage from "components/Global/FooterPage/FooterPage";
import FAQ from "components/GeneralInformation/FAQ/FAQ";
import { useFAQGeneralLogic } from "./FAQGeneral.logic";

const renderFAQSection = (section, t, isMobile) => (
  <>
    <div className="docsSubHeading" style={{ fontWeight: "bold" }}>{t(`faq_${section.code}`)}</div>
    <FAQ defaultOpen={[]}>

      {section.qna.map((qna, index) => (
        <FAQ.QAItem key={index}>
          <FAQ.Question answerId={`q${index}`}>
            {(isOpen, onToggle) => {
              return (
                <div style={{ borderRadius: 10, border: '1px solid #E9EDEF', padding: 20, color: 'black' }}>
                  <span style={{ fontWeight: '600', fontSize: '16px' }}>
                    <span style={{ color: "#EB234A", fontSize: 25, marginRight: 20 }}>{isOpen ? "-" : "+"}</span>
                    {t(`faq_${section.code}_q_${qna}`)}
                  </span>
                </div>
              );
            }}
          </FAQ.Question>
          <FAQ.Answer id={`q${index}`}>
            <div style={{ marginTop: '5px', backgroundColor: '#dddddd45', marginLeft: '20px', padding: '20px', borderRadius: '5px' }}>
              <div dangerouslySetInnerHTML={{ __html: t(`faq_${section.code}_a_${qna}`) }} />
            </div>
          </FAQ.Answer>
        </FAQ.QAItem>
      ))}
    </FAQ>
  </>
);

const FAQGeneral = () => {
  const { handleBack, isMobile, t, TranslatedFAQ } = useFAQGeneralLogic();

  return (

    <div className="docsPage" style={{ height: "auto" }}>
      <Header isMobile={isMobile} isDarkFont={true} />
      <div className="docsContainer">

        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          columnGap: '10px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#eb234b',
            borderRadius: '50%',
            padding: '8px',
            cursor: 'pointer'
          }}
            onClick={handleBack}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#fff" d="m15.965 1.6l.035.034l-8.832 8.855H24v3.556H7.166L16 22.9l-.035.034h-5.319L0 12.268L10.647 1.601z" /></svg>
          </div>
          <div className="docsHeading">Visitorsdeals FAQ</div>
        </div>

        {TranslatedFAQ.map(section => renderFAQSection(section, t, isMobile))}
      </div>
      <FooterPage />
    </div>
  );
}

export default FAQGeneral;
