import React, { useState } from "react";
import SearchBar from "../../SearchBar/SearchBar";
import { Modal, } from "antd";
import LoginBg from '../../../images/login_bg_1.png';
import SignUpModal from "../../Auth/SignUpModal/SignUpModal";

const HomeSearchBarBusiness = ({ hasProvider, setOpenLogin, history, openSignUp, }) => {
    const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;
    const userData = JSON.parse(localStorage.getItem("userdata"));

    const [modalVisible, setModalVisible] = useState(false)


    return (
        <div className="HeroIndoSatCenter">
            <div className="HeroText" style={{
                color: 'white',
                height: 'auto',
                marginBottom: isDesktopScreen ? '8rem' : '2rem'
            }}>
                <div style={{ marginTop: '100px', fontWeight: 'bolder', fontSize: isDesktopScreen ? '5.8rem' : '3.5rem', color: 'rgb(217, 59, 79)' }}>
                    Book Your Rooms &
                </div>
                <div style={{ fontWeight: 'bolder', fontSize: isDesktopScreen ? '5.8rem' : '3.5rem', color: 'rgb(217, 59, 79)' }}>
                    Stay Connected With
                </div>
                <div style={{ fontWeight: 'bolder', fontSize: isDesktopScreen ? '5.8rem' : '3.5rem', color: 'rgb(217, 59, 79)' }}>
                    FREE Roaming
                </div>
            </div>

            {
                !userData && (
                    <button className="btn-primary" style={{
                        width: '100%',
                        opacity: '100%',
                        backgroundColor: '#d93b4f',
                        outline: 'none',
                        border: 'none',
                        fontSize: '28px',
                        width: 'fit-content',
                        padding: '10px 15px',
                        borderRadius: '50px',
                    }} onClick={userData?.isPIC ? () => window.location.href = '/business-dashboard' : () => setModalVisible(!modalVisible)}>{userData?.isPIC ? 'Visit Business Dasboard' : 'Sign Up'}</button>
                )
            }

            {
                modalVisible && (
                    <Modal
                        title={null}
                        footer={null}
                        visible={modalVisible}
                        onCancel={() => setModalVisible(false)}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        bodyStyle={{
                            backgroundImage: `url(${LoginBg})`,
                            backgroundSize: 'cover',
                            padding: '30px',
                        }}
                        className="loginModal"
                    >
                        <div style={{
                            color: 'white',
                            backgroundColor: '#ffffff30',
                            backdropFilter: "blur(5px)",
                            padding: 10,
                        }}>
                            <SignUpModal
                                closeModal={() => setModalVisible(false)}
                                setmodalType={"Register"}
                            />
                        </div>
                    </Modal>
                )
            }
        </div>
    )
}

export default HomeSearchBarBusiness;
