import React from "react";

const HomeContentBusiness = ({ isMobile, ...props }) => {
  const isDesktopScreen = window.matchMedia('(min-width: 1200px)').matches;

  return (
    <div className="IndosatPopularDestionationsContainer" style={{
      columnGap: isDesktopScreen ? '10px' : 0,
    }}>


    </div>
  )
}

export default HomeContentBusiness;
