import React, { useState } from "react";
import RoomInfo from "./RoomInfo";
import { Button, DatePicker, Dropdown, Select } from "antd";
import "./SearchBar.css";
import "./SearchBarRoomInfo.css";
import moment from "moment";
import {RightCircleOutlined} from "@ant-design/icons";

import Autocomplete from "components/Global/AutoComplete/Autocomplete";

const { RangePicker } = DatePicker;

export const SearchBarDesktop = (props) => {
  const [dropDownVisible, setdropDownVisible] = useState(false);
  // const {location, placeId, rooms} = useParams()

  // const isDisabled = !props.state.activeSuggestion ? 
  //                   (!location ? true : 
  //                   !(props.state.place && props.state.activeSuggestion && location)) : 
  //                   false;
  // const isDisabled = (!location && !props.state.place && !props.state.activeSuggestion) ? true : false
  const isDisabled = ( !props.state.place) ? true : false

  return (
    <div className="SearchBar">
      <div className="SearchBarContainer">
        <Autocomplete
          triggerParentLocation={props.setplace}
          triggerParentLocationClick={props.clickPlace}
          activeSuggestion={props.state.activeSuggestion}
          className="placeInput"
          value={props.state.place}
        />
        <RangePicker
          nextIcon={<RightCircleOutlined />}
          superNextIcon={null}

          allowClear={false}
          style={{ marginRight: "0rem", width: "15vw" }}
          onChange={props.changeDate}
          format="MMM DD"
          separator="-"
          className="calendar"
          placeholder={["Check In", "Check Out"]}
          value={[
            props.state.startDate && moment(props.state.startDate),
            props.state.endDate && moment(props.state.endDate),
          ]}
          disabledDate={(current) =>
            current && current < moment().subtract(1, "day").endOf("day")
          }
          disabled={isDisabled}
        />

        <Dropdown
          overlay={
            <RoomInfo
              {...props.state}
              changeAdultCount={props.changeAdultCount}
              changeChildCount={props.changeChildCount}
              changeChildAge={props.changeChildAge}
              onChange={props.onChange}
              onEdit={props.onEdit}
              claseDropdown={setdropDownVisible}
            />
          }
          trigger={["click"]}
          visible={dropDownVisible}
          onVisibleChange={setdropDownVisible}
        >
          <div
            className="dropdownContainer"
            onClick={(e) => setdropDownVisible(true)}
          >
            <Select
              className="selectInput"
              placeholder="Rooms"
              value={`${props.state.totalRooms} ${
                props.state.totalRooms > 1 ? "Rooms" : "Room"
              }`}
              open={false}
              showArrow={false}
              disabled={isDisabled}
              // disabled={!props.state.activeSuggestion ? (!props.state.place ? true : false) : false}
            />
            <Select
              className="selectInput"
              placeholder="Adults"
              onClick={(e) => e.preventDefault()}
              open={false}
              value={`${props.state.totalAdults} ${
                props.state.totalAdults > 1 ? "Adults" : "Adult"
              }`}
              showArrow={false}
              disabled={isDisabled}
            />
            <Select
              className="selectInput"
              placeholder="Children"
              onClick={(e) => e.preventDefault()}
              open={false}
              value={props.children(props.state.totalChildren)}
              showArrow={false}
              disabled={isDisabled}
            />
          </div>
        </Dropdown>

        <Button
          type="primary"
          style={{
            width: "10vw",
            height: "calc(5rem + 2px)",
            backgroundColor: "#d93b4f",
            border: "1px solid #d93b4f",
            borderLeft: "none",
          }}
          onClick={props.search}
        >
          Go
        </Button>
      </div>
    </div>
  );
};
