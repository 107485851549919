import { useIsMobile } from "hooks"

const useResetNewPasswordStyles = () => {
    const isMobile = useIsMobile()

    const styles = {
        form: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '4px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 32px',
            marginTop: '64px'
        },
        input: {
            width: !isMobile && '400px'
        },
        successTitleGroup: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '16px',
        },
        successIcon: {
            color: 'green',
        },
        errorIcon: {
            color: 'red',
        },
        actionGroup: {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }

    return {
        styles
    }
}

export default useResetNewPasswordStyles