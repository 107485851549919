export const ENV = 'production' // local | development | production

export const getBaseURL = () => {
    switch (ENV) {
        case 'development':
        case 'production':
            return 'https://api.visitorsdeals.com'
        case 'local':
            return 'http://localhost:3001'
        default:
            return 'https://api.visitorsdeals.com'
    }
}