import React, { Component } from "react";
import Axios from "axios";
import MediaQuery from "react-responsive";
import { AccommodationDetailsMobile } from "./AccommodationDetailsMobile";
import { AccommodationDetailsDesktop } from "./AccommodationDetailsDesktop";
import { Spin, Modal, Button, message } from "antd";
import "./AccommodationDetails.css";
import StatelessHeader from "../../components/Header/StatelessHeader";
import { _defaultHeaders, channelId, getCurrency } from "helpers/AxiosHelper";
import { BOOKING_INFO } from "constants/RouteNames";
import moment from "moment";
import { _gtag, formatPolicyText, formatPolicyType, processCancellationPolicy } from "helpers/GlobalHelper"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { loginModalVisibleState, b2bIndosatRequiredCompanyProfileModalVisibleState } from "recoil/atoms"
import LoginModalV2 from "components/Auth/LoginModalV2/LoginModalV2";
import { temporaryUserState } from "recoil/atoms"
import RequiredCompanyProfileModalV2 from "components/B2BIndosat/RequiredCompanyProfileModalV2/RequiredCompanyProfileModalV2"

const AccommodationDetailsWrapper = (props) => {
  const setIsOpenLoginModal = useSetRecoilState(loginModalVisibleState);

  return <AccommodationDetails setIsOpenLoginModal={setIsOpenLoginModal} {...props} />;
};


class AccommodationDetails extends Component {
  constructor(props) {

    super(props);

    const query = new URLSearchParams(this.props.location.search);

    this.query = query;
    this.state = {
      data: [],
      loading: true,
      selectedToken: "",
      selectedPrice: "",
      selectedCurrency: "",
      paymentData: {},
      bookingModalVisible: false,
      policyModalVisible: false,
      showPolicyOnly: true,
      initialLoad: true,
      user: false,
      loginModalVisible: false,
      loginModalType: "Login",
      recommendationData: [],
      carryForward: JSON.parse(localStorage.getItem(`VDHOL-${this.props.match.params.slug}-${query.get('d')}`)) ?? {}
    };

  }


  componentDidMount = () => {
    // Not Sure when navigate overflow hidden at 1st
    document.body.style.overflow = "initial";
    let user = false;

    if (localStorage.usertoken && localStorage.userdata) {
      const data = JSON.parse(localStorage.getItem("userdata"));
      user = data.first_name + " " + data.last_name;
    }

    let data = JSON.stringify({
      rectangularRegion: null,
      channelId: channelId(),
      culture: "en-US",
      circularRegion: null,
      hotelIds: [this.props.match.params.slug],
      filterBy: null,
      contentFields: ["All"],
      providerPrefs: [],
    });

    // const query = new URLSearchParams(this.props.location.search);
    // 1719510003487
    // console.log(this.state.carryForward, this.props.match.params.slug, localStorage.getItem(`VDHOL-${this.props.match.params.slug}-${query.get('d')}`))

    //TODO :: HARCODE URL
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_PROVIDER_URL}/content/hotelcontent/getHotelContent`,
      headers: _defaultHeaders(
        getCurrency(),
        this.state.carryForward.correlationId
      ),
      data: data,
    };
    let retryCounter = 0;
    const getHotelContent = () => Axios(config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.hotels.length <= 0 && retryCounter <= 2) {
            retryCounter++;
            return getHotelContent();
          } else {
            return response;
          }
        } else if (response.status === 204) {
          alert(`Hotel Record Not Found`)
          self.props.history.goBack();
        }
      })


    var self = this;
    getHotelContent().then(function (response) {
      if (response.data.hotels.length > 0) {
        let hotel = response.data.hotels[0];
        self.setState({ data: hotel, loading: false, user });
        document.title = `${hotel.name}`
      } else {
        const hotel = JSON.parse(data);
        alert(`Hotel Record Not Found - ${hotel.hotelIds[0]}`)
        self.props.history.goBack();
      }
    })
      .catch(function (error) {
        console.log(error);
      });

    const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }
    let cfg = {
      status: false,
      name: null,
      loginMsgColor: null
    }
    if (hasProvider) {
      cfg = {
        status: true,
        name: pConfig?.name,
        loginMsgColor: pConfig?.textColor
      }
    }
    this.setState({ providerConfig: cfg })

  };

  completePayment = () => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    const queryString = this.props?.history?.location?.search;
    const queryParams = {};
    queryString.slice(1).split('&').forEach(query => {
      const [key, value] = query.split('=');
      queryParams[key] = value;
    });
    const search_timestamp = queryParams.d;
    const place_id = queryParams.p_id;
    const saved_booking_id = queryParams.saved_booking_id;

    const hotelAddress = this.state.data.contact.address

    let locState = this.state.carryForward;
    let data = this.state.data;
    // console.log(this.state.data, this.state,this.state.recommendationData)
    // return
    this.props.history.push({
      pathname: BOOKING_INFO,
      search: (userData?.isPIC && saved_booking_id) ? `saved_booking_id=${saved_booking_id}` : '',
      state: {
        checkinDate: locState.startDate,
        checkoutDate: locState.endDate,
        checkintime: data.checkinInfo.beginTime,
        checkouttime: data.checkoutInfo.time,
        Roomcount: locState.noofroom,
        guestDetails: {
          noofAdults: locState.noofAdults,
          noofChild: locState.noofChild,
          panes: locState.panes,
          ages: this.childrenString(true),
        },
        hotelid: data.id,
        hotelname: data.name,
        destination: `${hotelAddress.city.name}, ${hotelAddress.country.name}`,
        selectedPrice: parseFloat(this.state.selectedPrice).toFixed(2),
        rateDetails: this.state.rateObject,
        recommendationData: {
          ...this.state.recommendationData,
          correlationId: locState.correlationId,
        },
        selectedToken: this.state.selectedToken,
        token: this.state.token,
        // selectedCurrency: currency,
        hotelImage: data.heroImage,
        hotelAddress: this.address(),
        nights: this.days(),
      },
    });
  };

  handleSaveBooking = async () => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    const companyData = JSON.parse(localStorage.getItem("companyData"));

    let locState = this.state.carryForward;
    let data = this.state.data;
    const config = {
      method: "post",
      url: 'saved-booking-detail/save',
      headers: _defaultHeaders(
        getCurrency(),
        this.state.carryForward.correlationId
      ),
      data: {
        companyId: companyData.id,
        teamMemberId: '1',
        userId: userData.id,
        correlationid: locState.correlationId,
        hotel_name: data.name,
        hotel_image: data.heroImage,
        hotel_id: data.id,
        checkin_date: locState.startDate,
        checkout_date: locState.endDate,
        guest_details: JSON.stringify({
          noofAdults: locState.noofAdults,
          noofChild: locState.noofChild,
          panes: locState.panes,
          ages: this.childrenString(true),
        }),
        room_count: locState.noofroom,
        rate_id: this.state.recommendationData.hotel.rates[0].id,
        rate_token: this.state.token,
        // rate_token: this.state.recommendationData.token,
        rate_selected_token: this.state.selectedToken,
        provider_id: this.state.recommendationData?.hotel?.rates[0].providerId,
      },
    };

    await Axios(config)
      .then((response) => {
        message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
      })
      .catch((err) => {
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        // setIsLoading(false)
      });
  }

  getSavedBookingInfo = async () => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    const companyData = JSON.parse(localStorage.getItem("companyData"));

    let data = this.state.data;
    const config = {
      method: "get",
      url: `/saved-booking-detail/get-detail?companyId=${companyData?.id}&userId=${userData?.id}&hotel_id=${data?.id}&rate_selected_token=${this?.state?.selectedToken}`,
    };

    await Axios(config)
      .then((response) => {
        // message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
      })
      .catch((err) => {
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        // setIsLoading(false)
      });
  }

  // token is recomendationID --> #$#%@ pankaj and team
  handleSelection = (selectedToken, token, rate, currency, recommendationData, onlyPolicy = false,) => {
    this.getSavedBookingInfo()
    // if (this.state.user) {
    if (onlyPolicy) {
      this.setState({
        rateObject: rate,
        recommendationData,
        selectedToken: selectedToken,
        token: token,
        selectedPrice: parseFloat(rate).toFixed(2),
        selectedCurrency: currency,
        policyModalVisible: true,
        showPolicyOnly: true,
        initialLoad: false,
      });
    } else {
      this.setState({
        rateObject: rate,
        recommendationData,
        selectedToken: selectedToken,
        token: token,
        selectedPrice: parseFloat(rate).toFixed(2),
        selectedCurrency: currency,
        bookingModalVisible: true,
        showPolicyOnly: false,
        initialLoad: false,
      });
    }
    // } else {
    //   this.openLogin();
    // }
  };
  setModal = (bookingModalVisible) => this.setState({ bookingModalVisible });

  setuser = (user) => this.setState({ user });

  setloginModalVisible = (loginModalVisible) =>
    this.setState({ loginModalVisible });

  setloginModalType = (loginModalType) => this.setState({ loginModalType });

  openLogin = () => {
    this.props.setIsOpenLoginModal(true);

  };

  openSignUp = () => {
    this.setloginModalType("Register");
    this.setloginModalVisible(true);
  };

  address = () => {
    let address = this.state.data.contact.address;
    let arr = [
      address.line1,
      address.line2,
      address.city?.name,
      address.state?.name,
      address.country?.name,
    ];

    arr = arr.filter((val) => val !== undefined);
    return arr.join(", ");
  };

  days = () => {
    return Math.round(
      (Date.parse(this.state.carryForward.endDate) -
        Date.parse(this.state.carryForward.startDate)) /
      (1000 * 60 * 60 * 24)
    );
  };

  guestString = (noofAdults, noofChild, shortAnd = false) => {
    let adults = `${noofAdults} Adult`;
    if (noofAdults > 1) adults += "s";

    let children = "";
    if (noofChild > 0) {
      if (shortAnd) children += `&`;
      else children += `and`;

      if (noofChild === 1) children += " 1 child";
      else children += ` ${noofChild} children`;
    }

    return `${adults} ${children}`;
  };

  childrenString = (returnOnlyAges = false) => {
    let childrenInfo = [];

    let panes = this.state.carryForward.panes[0];

    if (panes.length === 0) return false;

    panes.forEach((pane) => {
      childrenInfo = childrenInfo.concat(pane.childrenInfo);
    });

    let onlyAges = childrenInfo.join(",");

    if (onlyAges === "") return false;

    if (returnOnlyAges) return onlyAges;

    let childrenInfoString;
    if (childrenInfo.length === 1) childrenInfoString = "Child's age - ";
    else childrenInfoString = "Children's ages - ";

    return childrenInfoString + onlyAges;
  };

  openPolicyModal = () => {

    this.setState({
      bookingModalVisible: true,
      policyModalVisible: true
    })
  }

  bookingModal = () => {
    const userData = JSON.parse(localStorage.getItem('userdata'))
    const companyData = JSON.parse(localStorage.getItem('companyData'))

    let bookNowAction, additionalInfo;
    let bookNowActionText = 'Book now'
    if (this.state.user) {
      if (userData?.isPIC == true && !companyData?.stripeCustomerId) {
        bookNowAction = () => window.location.href = '/business-dashboard?page=company-details'
        additionalInfo = 'As a PIC we would need your company info, please kindly complete company profile before booking'
        bookNowActionText = 'Complete company profile'
      } else {
        bookNowAction = this.completePayment
      }
    } else {
      bookNowAction = this.openLogin
    }

    return (
      <>
        <Modal
          forceRender={true}
          visible={this.state.bookingModalVisible}
          title={this.state.data.name}
          footer={[
            <div style={{ textAlign: 'center', width: '100%' }}>
              <p>{additionalInfo}</p>
              <Button key="submit" type="primary" style={{ width: '200px' }} onClick={bookNowAction}>
                {bookNowActionText}
              </Button>,
            </div>
          ]}
          closable={true}
          onCancel={() => this.setModal(false)}
          className="bookingModal"
        >
          <div className="bookingHotelInfo">
            <div
              style={{
                backgroundImage: `url(${this.state.data.heroImage})`,
              }}
              className="imageDiv"
            />
            <div>
              <div
                style={{
                  color: "black",
                  fontSize: "1.5rem",
                  marginBottom: "1rem",
                }}
              >
                {this.state.carryForward.name}
              </div>
              <div className="infoBox">
                <div className="subHeading">Check In</div>
                <div
                  style={{
                    color: "black",
                    paddingBottom: "1rem",
                    marginBottom: "1rem",
                    borderBottom: "1px solid #E9EDEF",
                  }}
                >
                  {moment(this.state.carryForward.startDate).format("dddd, D MMMM YYYY")}
                  {/* {moment(this.state.carryForward.startDate).format("dddd")},{" "}
                {this.state.carryForward.startDate} */}
                  {/* at{" "}{this.state.data.checkinInfo.beginTime} */}
                </div>
                <div className="subHeading">Check out</div>
                <div>
                  {moment(this.state.carryForward.endDate).format("dddd, D MMMM YYYY")}
                  {/* {moment(this.state.carryForward.endDate).format("dddd")},{" "}
                {this.state.carryForward.endDate} */}
                  {/* at{" "} {this.state.data.checkoutInfo.time} */}
                </div>

                <div className="flexSpWrap">
                  <div className="flexcol bookingModalInfo">
                    <div className="subHeading">Rooms</div>
                    <div>{this.state.carryForward.noofroom}</div>
                  </div>

                  <div className="flexcol bookingModalInfo">
                    <div className="subHeading">Nights</div>
                    <div>{this.days()}</div>
                  </div>

                  <div className="flexcol bookingModalInfo">
                    <div className="subHeading">Guests</div>
                    <div>
                      <MediaQuery maxWidth={775}>
                        {this.guestString(
                          this.state.carryForward.noofAdults,
                          this.state.carryForward.noofChild,
                          true
                        )}
                      </MediaQuery>
                      <MediaQuery minWidth={776}>
                        {this.guestString(
                          this.state.carryForward.noofAdults,
                          this.state.carryForward.noofChild
                        )}
                      </MediaQuery>
                    </div>
                  </div>

                  {this.state.carryForward.noofChild > 0 && (
                    <div className="flexcol bookingModalInfo">
                      <div className="subHeading">
                        {this.state.carryForward.noofChild === 1
                          ? `Child's age`
                          : `Children's age`}
                      </div>
                      <div>{this.childrenString(true)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="subHeading">Total (Including all taxes)</div>
              <div style={{ color: "#F48247", marginBottom: "1rem" }}>
                {getCurrency()}{" "}
                {this.state.selectedPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>

            {(this.state.recommendationData?.hotel?.rates[0]?.policies?.length > 0 || this.state.recommendationData?.hotel?.rates[0]?.cancellationPolicies?.length > 0) && (
              <Button
                type="link"
                style={{ color: "var(--vdOrange", padding: 0 }}
                onClick={this.openPolicyModal}
              >
                View Policy
              </Button>
            )}
          </div>
          {/* )} */}
        </Modal>
      </>

    );
  }

  render() {

    _gtag('view_item');

    const loading = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );

    const header = (
      <>
        <StatelessHeader
          user={this.state.user}
          modalVisible={this.state.loginModalVisible}
          modalType={this.state.loginModalType}
          setuser={this.setuser}
          setmodalVisible={this.setloginModalVisible}
          setmodalType={this.setloginModalType}
          openLogin={this.openLogin}
          openSignUp={this.openSignUp}
          providerConfig={this.state.providerConfig}
        />
        <LoginModalV2 />
      </>

    );

    const policyModal = () => {

      const rates = this.state.recommendationData?.hotel?.rates[0];
      let refundTooltip = '';
      if (rates?.cancellationPolicies?.length) {

        const res = processCancellationPolicy(rates.cancellationPolicies);
        refundTooltip = `<div>${res.shortText}</div>`;
        refundTooltip += res.table;
      }

      return (
        <Modal
          forceRender={true}
          title="Policies"
          visible={this.state.policyModalVisible}
          onCancel={() => {
            this.setState({ policyModalVisible: false })
          }}
          closable={false}
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ policyModalVisible: false })}
            >
              Close
            </Button>,
          ]}
        >
          {refundTooltip.length > 0 && (
            <div style={{ marginBottom: '10px' }}>
              <strong>Refundable Policy:</strong>
              <span dangerouslySetInnerHTML={{ __html: refundTooltip }} />
            </div>
          )}


          {this.state.recommendationData?.hotel?.rates[0]?.policies?.map(
            (policy, index) => (
              <div key={index}>
                <strong>{formatPolicyType(policy.type)}:</strong>
                <div style={{ whiteSpace: 'break-spaces' }}>{formatPolicyText(policy.text)}</div>
              </div>
            )
          )}
        </Modal>
      )
    };

    return (
      <div className="AccommodationDetails">
        <MediaQuery maxWidth={775}>
          {this.state.loading ? (
            loading
          ) : (
            <AccommodationDetailsMobile
              location={{
                state: this.state.carryForward
              }}
              data={this.state.data}
              handleSelection={this.handleSelection}
              modalVisible={this.state.bookingModalVisible}
              selectedPrice={this.state.selectedPrice}
              completePayment={this.completePayment}
              history={this.props.history}
              header={header}
              bookingModal={this.bookingModal()}
              policyModal={policyModal}
              openPolicyModal={this.openPolicyModal}
              setModal={this.setModal}
              childrenInfoString={this.childrenString}
              carryForward={this.state.carryForward}
            />
          )}
        </MediaQuery>
        <MediaQuery minWidth={776}>
          {this.state.loading ? (
            loading
          ) : (
            <AccommodationDetailsDesktop
              location={{
                state: this.state.carryForward
              }}
              data={this.state.data}
              handleSelection={this.handleSelection}
              modalVisible={this.state.bookingModalVisible}
              selectedPrice={this.state.selectedPrice}
              completePayment={this.completePayment}
              history={this.props.history}
              header={header}
              bookingModal={this.bookingModal()}
              policyModal={policyModal}
              openPolicyModal={this.openPolicyModal}
              setModal={this.setModal}
              childrenInfoString={this.childrenString}
              carryForward={this.state.carryForward}
            />
          )}
        </MediaQuery>
      </div>
    );
  }
}
export default AccommodationDetailsWrapper