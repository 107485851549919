import React from "react";
import { Button } from "antd";
import Header from "components/Header/Header";
import useAcceptTeamInvitationLogic from "./AcceptTeamInvitation.logic";
import Loader from "components/Global/Loader/Loader";
import { FaCheckCircle, FaTimesCircle, FaUserFriends } from "react-icons/fa";
import useAcceptTeamInvitationStyles from './AcceptTeamInvitation.styles'
import SignUpForm from 'components/Auth/SignUpForm/SignUpForm';

const AcceptTeamInvitation = () => {
    const {
        userData,
        error,
        isMobile,
        loading,
        invitedEmail,
        data,
        handleAcceptInvitation,
        onClickLogin,
    } = useAcceptTeamInvitationLogic()

    const { styles } = useAcceptTeamInvitationStyles()

    const unverifiedKey = () => {
        return (<div style={styles.formSuccess}>
            <FaTimesCircle size={75} style={styles.errorIcon} />
            <h1>Failed to verify the link</h1>

            <p>Please check your email inbox and make sure to click the most recent link received by your email or click on the button below to accept team's invitation.</p>

            <Button
                type='primary'
                href='/'
            >
                Back to Home
            </Button>
        </div>)
    }

    const getTeamMemberFormInvitation = () => {
        return data?.activeTeamMember ? (
            <div>Lorem ipsum dolor sit amet.</div>
        ) : (
            <div>Lorem ipsum dolor sit amet.</div>
        )
    }

    // 1) approved
    // 2) not approved - no account
    // 3) not approved - account already exists
    // 4) not approved - account already exists - not associated to any company
    // 5) not approved - account already exists - associated to a company - user => switch company
    // 6) not approved - account already exists - associated to a company - pic => needs promotion
    // 7) wrong key
    const getTeamMemberInvitation = () => {
        return data.teamData.status !== 'cancelled' && (
            <div style={styles.form}>
                <FaUserFriends size={75} style={styles.memberIcon} />
                {data?.teamData?.approvedAt ? (
                    <h2>In order to join your company's team <b>{data.company?.businessName}</b>, please complete the following form</h2>
                ) : (
                    <h2>In order to join your company's team <b>{data.company?.businessName}</b>, please click the button below</h2>
                )}
            </div>
        )
    }

    const getInvitationInfo = () => {
        return getTeamMemberInvitation()
    }

    const successfullyJoined = () => {
        return (
            <div style={styles.formSuccess}>
                <FaCheckCircle size={75} style={styles.successIcon} />
                <h2>You've successfully joined the <b>{data?.activeTeamMember?.Company?.businessName}</b>'s team!</h2>

                {userData && data.teamData.invitedEmail == userData.email ? <Button
                    type='primary'
                    href="/Myaccount?tab=your_company"
                >
                    Click here to view your profile
                </Button> : <Button
                    type='primary'
                    onClick={onClickLogin}
                >
                    Login to your account
                </Button>}
            </div>
        )
    }


    const getInvitationActiveTeamMemberInfoContentInfo = () => {
        return (data?.activeTeamMember && data.teamData.status == 'waiting') && (
            <div style={styles.container}>
                <p>Since you are currently a member of the following company, <b>accepting invitation means your status in the former company will be disabled</b></p>
                <h4>Former company's business name: {data?.activeTeamMember?.Company?.businessName}</h4>
            </div>
        )
    }

    const getInvitationAction = () => {
        const teamMember = data?.teamData

        if (teamMember.status === 'waiting') {
            if (teamMember.User) {
                return <div style={{ ...styles.form, marginTop: '16px' }}>
                    <Button type="primary" onClick={() => handleAcceptInvitation(teamMember.User.id)}>Click here to accept invitation</Button>
                </div>
            } else {
                return (
                    <div style={styles.wrapper}>
                        <SignUpForm
                            formStyles={styles.signUpForm}
                            isAcceptInvitationForm={true}
                            invitedEmail={invitedEmail}
                        />
                    </div>
                )
            }
        } else if (teamMember.status === 'cancelled') {
            return <div style={styles.form}>
                <FaTimesCircle size={75} style={styles.errorIcon} />
                <h1>Your company has cancelled the invitation.</h1>

                <p>Please contact your company PIC.</p>

                <Button
                    type='primary'
                    href='/'
                >
                    Back to Home
                </Button>
            </div>
        } else if (teamMember.status === 'approved') {
            return (
                <>
                    <h2>You've joined the team! Please click the link below to view your company</h2>
                    <a href="/Myaccount?tab=your_company">Click here</a>
                </>
            );
        }
    }

    return (
        <div style={styles.container}>
            <Header isDarkFont={true} />

            {
                error ? unverifiedKey() : (
                    loading ? <Loader text={'Verifying'} /> : <div className={styles.form}>
                        {
                            data?.teamData.status == 'approved' ? (
                                successfullyJoined()
                            ) : (
                                <div className={styles.form}>
                                    {getInvitationInfo()}
                                    {getInvitationActiveTeamMemberInfoContentInfo()}
                                    {getInvitationAction()}
                                </div>

                            )
                        }
                    </div>
                )
            }
            {/* {loading ? <Loader text={'Verifying'} /> : <div className={styles.form}>
                {
                    data?.teamData.status == 'approved' ? (
                        successfullyJoined()
                    ) : (
                        <div className={styles.form}>
                            {getInvitationInfo()}
                            {getInvitationActiveTeamMemberInfoContentInfo()}
                            {getInvitationAction()}
                        </div>

                    )
                }
            </div>} */}
        </div >
    );
};

export default AcceptTeamInvitation