import React from 'react'
import MapContainer from 'components/Hotel/HotelMap/HotelMap'
import './HotelLocation.css'

const HotelLocation = (props) => {
    return (
        <div className="HotelLocationContainer">
            <MapContainer geoCode={props.geoCode} name={props.name} />
        </div>
    )
}

export default HotelLocation