import { useState, useEffect } from 'react';
import Axios from 'axios';
import { useRecoilValue } from 'recoil';
import { temporaryUserState } from 'recoil/atoms'

export const INDOSAT_B2B_TEAM_MEMBERS_ROLES = [
    { id: 1001, description: "Able to view bookings only" },
    { id: 1002, description: "Able to book for oneself only" },
    { id: 1003, description: "Able to book for others" },
    // { id: 1003, description: "Able to book for others (can only be checked when #2 is checked)" },
    { id: 1004, description: "Using company card" },
];

const useUserRoleChecker = () => {
    const [userRoles, setUserRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const temporaryUser = useRecoilValue(temporaryUserState)

    useEffect(() => {
        setIsLoading(true);
        const companyData = localStorage.getItem("companyData");
        const email = localStorage.getItem("useremail");

        if (companyData) {
            Axios.get(`/users/userDetails/${email}`)
                .then((res) => {
                    setUserRoles(res.data.teamMembers.role); // Assuming the response includes a 'roles' field
                })
                .catch((error) => {
                    setError("Error fetching user details: " + error);
                    console.error(error);
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false)
        }

    }, [temporaryUser]);
    
    const hasRole = (roleId) => {
        const userData = JSON.parse(localStorage.getItem("userdata"));
        const companyData = localStorage.getItem("companyData");

        if (!userData) {
            if (roleId == 1001 || roleId == 1002 || roleId == 1003) {
                return true
            } else {
                return false
            }
        }

        if (companyData == 'null' || !companyData) {
            if (roleId == 1004) {
                return false
            } else {
                return true
            }
        } else {
            if (userData?.isPIC) return true
            // console.log(userRoles.includes(roleId))
            return userRoles.includes(roleId);
        }
        if (companyData && !userData.isPIC) {
            //     return userRoles.includes(roleId);
            // } else if(companyData) {
            //     return false;
            // } else if(!companyData) {
            //     if(roleId == 1004) return false
            //     return true
            // }
        };
    }

    return { hasRole, isLoading, error };
};

// Usage example
const CheckIndosatTeamMemberRolesHelper = (roleId) => {
    const { hasRole, isLoading, error } = useUserRoleChecker();

    return !isLoading && hasRole(roleId); // Since no UI rendering is needed
};

export default CheckIndosatTeamMemberRolesHelper;
