import React, { useEffect, useState } from "react";
import "pages/LegalDocuments.css";
import Header from "components/Header/Header";
import FooterPage from "components/Global/FooterPage/FooterPage";
import { Form, Input, Button } from "antd";
import des1 from "../../../images/contactus.jpg";
import Axios from "axios";
import { MDBAlert } from "mdbreact";
import { useMediaQuery } from "react-responsive";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not validate email!",
  },
};
const ContactUs = ({ form }) => {

  const { getFieldDecorator } = form;
  const [resMsg, setResMsg] = useState();
  const isMobile = useMediaQuery({
    maxWidth: 775
  });

  const onFinish = (e) => {
    e.preventDefault()

    form.validateFields(async (err, values) => {
      if (!err) {
        const serverCall = await Axios.post(
          '/general/contact-us', values
        );

        if (serverCall.data?.success) {
          setResMsg({
            success: true,
            msg: 'Thanks for reaching out , we will get back to you as soon as possible.'
          })
          form.resetFields();
        } else {
          setResMsg({
            success: false,
            msg: 'Failed to send email.'
          })

        }
      }
    });

  };

  useEffect(() => {
    if (resMsg) {
      setTimeout(() => setResMsg(undefined), 10 * 1000)
    }
  }, [resMsg])

  return (
    <div className="docsPage">
      <Header isMobile={isMobile} isDarkFont={true} />
      <div
        style={{
          backgroundImage: `url(${des1})`,
        }}
        className="docsbackground"
      >
        <div className="docsContainer">
          <span className="docsHeading" style={{ color: "white" }}>
            Corporate Travel
          </span>
          <div
          // className="aboutUsMainContainer"
          >
            <div className="contactUs">
              <div className="contactUsSide" style={{ alignItems: "inherit" }}>
                For special corporate rates or any other business enquiries please contact us here, or you can write to us at
                <a style={{ color: "white" }} href="mailto:contactus@visitorsdeals.com">
                  <u>contactus@visitorsdeals.com</u>
                </a>
                <p>or call us at +6012-8966110</p>
              </div>

              {resMsg && (
                <MDBAlert color={resMsg.success ? 'success' : 'danger'}>{resMsg.msg}</MDBAlert>
              )}
              <Form
                name="contact_form"
                onSubmit={onFinish}
                validateMessages={validateMessages}
                className="formClass"
              >
                <div className="inputContainer">
                  <div className="inputDescription">Name</div>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('user', {
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    })(<Input style={{ border: "none", width: "100%", marginBottom: 0 }} />)}
                  </Form.Item>
                </div>
                <div className="inputContainer">
                  <div className="inputDescription">Email</div>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: "email",
                        },
                        {
                          required: true,
                        },
                      ]
                    })(<Input style={{ border: "none", width: "100%" }} />)}
                  </Form.Item>
                </div>

                <div className="inputContainer">
                  <div className="inputDescription">Subject</div>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('subject', {
                      rules: [
                        {
                          required: true,
                        },
                      ]
                    })(<Input style={{ border: "none", width: "100%" }} />)}
                  </Form.Item>
                </div>

                <div className="inputContainer">
                  <div className="inputDescription">Message</div>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('message', {
                      rules: []
                    })(<Input.TextArea style={{ border: "none", width: "100%" }} />)}
                  </Form.Item>
                </div>

                <div className="submitButton">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: "auto" }}
                    >
                      Send
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/*<MessengerCustomerChat*/}
      {/*    pageId="1740169049605102"*/}
      {/*    appId="208307229975285"*/}
      {/*/>*/}
      <FooterPage />
    </div>
  );
};

const ContactUsForm = Form.create({ name: 'contact_form' })(ContactUs);

export default ContactUsForm;
